import React from 'react';
import { useWatch } from 'react-hook-form';
import { AttachmentsUploadDropZone } from 'modules/attachments/components/AttachmentsUploadDropZone';
import { useAttachmentDetailForm } from 'modules/attachments/contexts/attachmentDetailForm';
import { TAttachmentDetailFormSchema } from 'modules/attachments/types';
import { EFileSizeUnit } from 'types';
import { getFileSizeInBytes } from 'utils/fileSize';

export interface IFileUploadFieldProps {}

export const FileUploadField: React.FC<IFileUploadFieldProps> = () => {
    const {
        formMethods: { setValue, getValues },
    } = useAttachmentDetailForm();
    const { file } = useWatch<TAttachmentDetailFormSchema>();

    const handleDrop = (files: File[]) => {
        setValue('file', files[0]);
        if (getValues('name') === undefined || getValues('name').length === 0) {
            setValue('name', files[0].name);
        }
    };

    return (
        <AttachmentsUploadDropZone
            visual="section"
            filesCount={file ? 1 : 0}
            maxFiles={1}
            multiple={false}
            onDrop={handleDrop}
            maxFileSize={getFileSizeInBytes(5, EFileSizeUnit.MB)}
        />
    );
};
