import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { IconPrint } from 'assets/icons';
import { useLazyGetAttachmentFileQuery } from 'modules/attachments/services';
import { Button, ButtonProps } from 'modules/ui';
import { printURI } from 'utils';

export interface IPrintAttachmentFileButtonProps extends ButtonProps {
    attachmentId: number;
}

export const PrintAttachmentFileButton: React.FC<IPrintAttachmentFileButtonProps> = ({ attachmentId, ...props }) => {
    const { t } = useTranslation();
    const [getAttachmentFile, { isLoading }] = useLazyGetAttachmentFileQuery();

    const handlePrint = async () => {
        try {
            const { data } = await getAttachmentFile({ attachmentId }, true).unwrap();
            printURI(data);
        } catch (error: any) {
            if (error?.data?.message) {
                return toast.error(error.data.message);
            }
            toast.error(t('attachments.print.error'));
        }
    };
    return (
        <Button type="button" variant="secondary" onClick={handlePrint} isLoading={isLoading} {...props}>
            <IconPrint />
            {t('common.subheader.print')}
        </Button>
    );
};
