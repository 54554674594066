import { config } from 'config';
import { AuthRole } from 'core/auth/types';
import { T } from 'core/translation/types';
import { z } from 'zod';

// language type definitions:
// countryCode - 'CZ', lang - 'cs', languageId - 36, languageText - 'čeština'

export const DEFAULT_LANGUAGE_ID = 36;

export enum Currency {
    CZK = 'CZK',
    EUR = 'EUR',
}

export const countryCodeToLanguageId: { [key: string]: number } = {
    CZ: 36,
    DE: 52,
    EN: 41,
    AT: 52,
};

export const languageIdToLanguage: { [key: string]: 'čeština' | 'angličtina' | 'němčina' } = {
    '36': 'čeština',
    '52': 'němčina',
    '41': 'angličtina',
};

export const countryCodeToLanguage: { [key: string]: 'čeština' | 'angličtina' | 'němčina' } = {
    CZ: 'čeština',
    DE: 'němčina',
    EN: 'angličtina',
    AT: 'němčina',
};

export const languageToLanguageId: { [key: string]: number } = {
    cs: 36,
    de: 52,
    en: 41,
};

export const languageIdToLang: { [key: number]: 'cs' | 'de' | 'en' } = {
    36: 'cs',
    52: 'de',
    41: 'en',
};

export type universalLanguage = {
    countryCode: string[];
    lang: 'cs' | 'de' | 'en';
    languageId: number;
    languageText: string;
};

export const defaultRedirects = {
    [AuthRole.USER]: config.routes.commissions.list,
    [AuthRole.DISPATCHER]: config.routes.offers.list,
    [AuthRole.ONBOARDING]: config.routes.dispatcher.onboarding,
    [AuthRole.ATTACHMENT_UPLOAD]: config.routes.auth.login,
};

export const tableNames = {
    commissions: {
        list: 'commissions',
        searched: 'offers-searched',
        selected: 'offers-selected',
    },
    carriers: { list: 'carriers' },
    dispatchers: { places: 'dispatchers-places' },
    customers: { list: 'customers', locations: 'customers-locations' },
    invoicing: { list: 'invoicing', commissions: 'invoicing-uninvoiced-commissions' },
    offers: {
        list: 'offers',
        offersSection: 'offers-section',
        contactedSection: 'offers-contacted',
        modifyModal: 'offers-modify',
    },
};

// in English
// export enum Countries {
//     AL = 'Albania',
//     AD = 'Andorra',
//     AM = 'Armenia',
//     AT = 'Austria',
//     BY = 'Belarus',
//     BE = 'Belgium',
//     BA = 'Bosnia and Herzegovina',
//     BG = 'Bulgaria',
//     CH = 'Switzerland',
//     CY = 'Cyprus',
//     CZ = 'Czech Republic',
//     DE = 'Germany',
//     DK = 'Denmark',
//     EE = 'Estonia',
//     ES = 'Spain',
//     FO = 'Faeroe Islands',
//     FI = 'Finland',
//     FR = 'France',
//     GB = 'United Kingdom',
//     GE = 'Georgia',
//     GI = 'Gibraltar',
//     GR = 'Greece',
//     HU = 'Hungary',
//     HR = 'Croatia',
//     IE = 'Ireland',
//     IS = 'Iceland',
//     IT = 'Italy',
//     LI = 'Liechtenstein',
//     LT = 'Lithuania',
//     LU = 'Luxembourg',
//     LV = 'Latvia',
//     MC = 'Monaco',
//     MK = 'Macedonia',
//     MT = 'Malta',
//     NO = 'Norway',
//     NL = 'Netherlands',
//     PL = 'Poland',
//     PT = 'Portugal',
//     RO = 'Romania',
//     RS = 'Serbia',
//     SE = 'Sweden',
//     SI = 'Slovenia',
//     SK = 'Slovakia',
//     SM = 'San Marino',
//     TR = 'Turkey',
//     UA = 'Ukraine',
//     VA = 'Vatican City State',
// }

// in Czech
export enum Countries {
    AL = 'Albánie',
    AD = 'Andorra',
    AM = 'Arménie',
    AT = 'Rakousko',
    BY = 'Bělorusko',
    BE = 'Belgie',
    BA = 'Bosna a Hercegovina',
    BG = 'Bulharsko',
    CH = 'Švýcarsko',
    CY = 'Kypr',
    CZ = 'Česká republika',
    DE = 'Německo',
    DK = 'Dánsko',
    EE = 'Estonsko',
    ES = 'Španělsko',
    FO = 'Faerské ostrovy',
    FI = 'Finsko',
    FR = 'Francie',
    GB = 'Spojené království',
    GE = 'Gruzie',
    GI = 'Gibraltar',
    GR = 'Řecko',
    HU = 'Maďarsko',
    HR = 'Chorvatsko',
    IE = 'Irsko',
    IS = 'Island',
    IT = 'Itálie',
    LI = 'Lichtenštejnsko',
    LT = 'Litva',
    CN = 'Čína',
    LU = 'Lucembursko',
    LV = 'Lotyšsko',
    MC = 'Monaco',
    MK = 'Makedonie',
    MT = 'Malta',
    NO = 'Norsko',
    NL = 'Nizozemsko',
    PL = 'Polsko',
    PT = 'Portugalsko',
    RO = 'Rumunsko',
    RS = 'Srbsko',
    SE = 'Švédsko',
    SI = 'Slovinsko',
    SK = 'Slovensko',
    SM = 'San Marino',
    TR = 'Turecko',
    UA = 'Ukrajina',
    VA = 'Vatican City State',
}

type EnumType = keyof typeof Countries;
const CountryCodes = (t?: T) => z.custom<EnumType>((value: any) => value, t && t('form.errors.required'));

export enum invoicingLanguages {
    čeština = 'čeština',
    angličtina = 'angličtina',
    němčina = 'němčina',
}

export const selectLanguages = [
    {
        label: 'Čeština',
        value: 'cs',
    },

    {
        label: 'Angličtina',
        value: 'en',
    },
    {
        label: 'Němčina',
        value: 'de',
    },
];

export { CountryCodes };
