import { configureStore } from '@reduxjs/toolkit';
import { appApi, authApi, filesApi } from 'core/api';
import { generalApi } from 'core/api';
import { appSlice } from 'core/application';
import { authSlice } from 'core/auth';
import { carrierApi } from 'modules/carriers/services';
import { commissionsApi } from 'modules/commissions/services';
import { commissionSlice } from 'modules/commissions/store';
import { customersApi } from 'modules/customers/services';
import { enquiryApi, offerSlice } from 'modules/offers/services';
import { dispatchersApi } from 'modules/onboarding/services';

const slices = {
    [authSlice.name]: authSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    [offerSlice.name]: offerSlice.reducer,
    [commissionSlice.name]: commissionSlice.reducer,
};

const apis = {
    [appApi.reducerPath]: appApi.reducer,
    [generalApi.reducerPath]: generalApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [carrierApi.reducerPath]: carrierApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [commissionsApi.reducerPath]: commissionsApi.reducer,
    [dispatchersApi.reducerPath]: dispatchersApi.reducer,
    [enquiryApi.reducerPath]: enquiryApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
};

const middleware = [
    appApi.middleware,
    authApi.middleware,
    carrierApi.middleware,
    customersApi.middleware,
    commissionsApi.middleware,
    generalApi.middleware,
    dispatchersApi.middleware,
    enquiryApi.middleware,
    filesApi.middleware,
];

// Register reducers here from individual modules
export const store = configureStore({
    reducer: {
        ...slices,
        ...apis,
    },
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // according to the redux-toolkit maintainer, we need to turn off serializableCheck
            // to be able to pass File as mutation argument (used in InvoiceBasicForm).
            // source: https://github.com/reduxjs/redux-toolkit/issues/1239#issuecomment-875708062
            serializableCheck: false,
            ignoredPath: [
                appApi.reducerPath,
                authApi.reducerPath,
                carrierApi.reducerPath,
                customersApi.reducerPath,
                commissionsApi.reducerPath,
                enquiryApi.reducerPath,
            ],
        }).concat(...middleware),
});
