export const config = {
    app: {
        url: 'http://localhost:5173',
        base_title: 'Qapline IS',
    },
    api: {
        //     development server
        // url: 'https://dev.qapline.api.koala42.com/api',

        //     local server
        //  url: 'http://localhost:3000/api',

        //     production server
        // url: 'https://api.qapline.k42.app/api',

        //     auto server
        url:
            process.env.VITE_MODE === 'main'
                ? 'https://api.qapline.com/api'
                : 'https://dev.qapline.api.koala42.com/api',
        websocketUrl:
            process.env.VITE_MODE === 'main' ? 'wss://api.qapline.com' : 'wss://dev.qapline.api.koala42.com',
        mapboxToken: 'pk.eyJ1IjoiaHVsZGFjeiIsImEiOiJjbGJ6M2l3d2cwd2hyM3FvNXM5M2twb2RlIn0.nVPfoiec06LkIocNYRPoGA',
        endpoints: {
            // auth
            login: 'v3/login',
            geocodingForward: 'v3/geocoding/forward',
            refresh: 'v3/refresh',
            logout: 'v3/login/logout',
            // carrier
            carrier: 'v3/carrier',
            carrierList: 'v3/carrier/list',
            carrierCSV: 'v3/carrier/csv',
            vehicleType: 'v3/vehicle_type',
            dispatcherVehicleFeature: 'dispatcher_vehicle_feature',
            dispatcherSearch: '/v3/dispatcher/commission/search',
            carrierInquiry: 'v3/carrier/enquiry',
            onboardingEmail: 'v3/carrier/sendOnboardingEmail',
            // invoices
            invoices: 'v3/invoice',
            invoicesCSV: 'v3/invoice/csv',
            invoicesXML: 'v3/invoice/xml',
            invoiceFiles: 'services/uploader.php',
            uninvoicedItems: 'v3/invoice/uninvoiced_items',
            uninvoicedCounts: 'v3/invoice/uninvoiced_counts',
            invoiceNumbers: 'v3/invoice/numbers',
            invoicePdf: 'v3/invoice/pdf',
            invoiceEmail: 'v3/invoice/mail',
            // customers
            customers: 'v3/customer',
            customersCSV: 'v3/customer/csv',
            // commissions
            commissions: 'v3/commission',
            commissionSummary: 'v3/commission/summary',
            commissionsCSV: 'v3/commission/csv',
            commissionListNumbers: 'v3/commission/numbers',
            // order
            orderCarrier: 'v3/order',
            orderCarrierPdf: 'v3/order/pdf',
            orderCarrierConfirmationEmail: 'v3/order/email',

            orderConfirmationEmail: 'v3/order/confirmationEmail',

            orderLoading: 'v3/loading',
            orderLoadingPdf: 'v3/loading/pdf',
            orderLoadingConfirmationEmail: 'v3/loading/email',

            orderDelivery: 'v3/delivery',
            orderDeliveryPdf: 'v3/delivery/pdf',
            orderDeliveryConfirmationEmail: 'v3/delivery/email',

            orderDischargePdf: 'v3/discharge/pdf',
            orderDischargeConfirmationEmail: 'v3/discharge/email',
            // dispatcher
            dispatchers: 'v3/dispatcher',
            checkDispatcherMail: 'v3/dispatcher/checkMail',
            checkOnboardingToken: 'v3/dispatcher/checkToken',
            dispatcherEmail: 'v3/dispatcher/email',
            // enquiry
            enquiry: 'v3/enquiry',
            enquiryContact: 'v3/enquiry/contact',
            closeEnquiry: 'v3/enquiry/close',
            reopenEnquiry: 'v3/enquiry/reopen',
            // offer
            offer: 'v3/offer',
            // localization
            state: 'state/languages',
            // files
            files: 'v3/file',
            filesList: 'v3/file/list',
            // ares
            ares: 'v3/ares',

            sentryTunnel: 'v3/sentry/tunnel',
            attachments: {
                upload: 'v3/attachment/upload',
                create: 'v3/attachment',
                list: 'v3/attachment',
                get: 'v3/attachment/:id',
                getFile: 'v3/attachment/file/:id',
                mailFile: 'v3/attachment/mail/:id',
                delete: 'v3/attachment/:id',
                update: 'v3/attachment/:id',
                mailAttachment: 'v3/attachment/mail/:id',
            },
        },
    },
    routes: {
        auth: {
            login: '/login',
            signup: '/signup',
            requestResetPassword: '/request-password',
            resetPassword: '/reset-password',
            logout: '/logout',
        },
        commissions: {
            list: '/commissions',
            detail: '/commissions/:id',
            new: '/commissions/new',
            export: '/commissions/export/:id',
        },
        carriers: {
            list: '/carriers',
            detail: '/carriers/:id',
            new: '/carriers/new',
        },
        customers: {
            list: '/customers',
            detail: '/customers/:id',
            new: '/customers/new',
        },
        invoicing: {
            list: '/invoicing',
            detail: '/invoicing/:id',
            new: '/invoicing/new',
        },
        offers: {
            list: '/offers',
            detail: '/offers/:id',
            new: '/offers/new',
        },
        profile: '/profile',
        dispatcher: {
            list: '/dispatcher',
            detail: '/dispatcher/:id',
            new: '/dispatcher/new',
            onboarding: 'dispatcher/onboarding',
        },
        attachments: {
            upload: '/attachment/upload',
            detail: '/attachment/:id',
            new: '/attachment/new',
        },
    },
    nav: {
        commissions: 'commissions',
        carriers: 'carriers',
        customers: 'customers',
        invoicing: 'invoicing',
        offers: 'offers',
        profile: 'profile',
    },
};
