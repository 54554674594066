import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useAttachmentDetailForm } from 'modules/attachments/contexts/attachmentDetailForm';
import { useUpdateAttachmentMutation } from 'modules/attachments/services';
import { TAttachment } from 'modules/attachments/types';

import { AttachmentDetailForm, IAttachmentDetailFormProps } from '../AttachmentDetailForm';

export interface IEditAttachmentFormWrapperProps {
    initialData?: TAttachment;
    onIsSubmittingChange?: (isSubmitting: boolean) => void;
}

export const EditAttachmentFormWrapper: React.FC<IEditAttachmentFormWrapperProps> = ({
    onIsSubmittingChange,
    initialData,
}) => {
    const { t } = useTranslation();
    useAttachmentDetailForm(initialData);
    const [updateAttachment, { isLoading }] = useUpdateAttachmentMutation();

    const handleSubmit: IAttachmentDetailFormProps['onSubmit'] = async (data) => {
        if (!data.attachment_id || isLoading) return;
        try {
            await updateAttachment({
                ...data,
                commissionId: data.commission_id || undefined,
                invoiceId: data.invoice_id || undefined,
                attachmentId: Number(data.attachment_id),
            }).unwrap();
            toast.success(t('attachments.update.success'));
        } catch (error: any) {
            if (error?.data?.message) {
                return toast.error(error.data.message);
            }
            toast.error(t('attachments.update.error'));
        }
    };

    useEffect(() => {
        onIsSubmittingChange?.(isLoading);
    }, [isLoading]);

    return <AttachmentDetailForm onSubmit={handleSubmit} />;
};
