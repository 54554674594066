import { useState } from 'react';
import { CommissionExportSchema, commissionLanguages } from 'modules/commissions';

type Template = CommissionExportSchema['template'] | 'enquiry'

export type useEmailMessageSaverType = {
    messages: {
        [key in Template]?: {
            [key in commissionLanguages | 'cs']?: string;
        };
    };
    subjects: {
        [key in Template]?: {
            [key in commissionLanguages | 'cs']?: string;
        };
    }
    setMessage: (
        message: string,
        lang: commissionLanguages | 'cs',
        template: Template,
    ) => void;
    setSubject: (
        subject: string,
        lang: commissionLanguages | 'cs',
        template: Template,
    ) => void;
};

export const useEmailMessageSaver = () => {
    const [messages, setMessages] = useState<useEmailMessageSaverType['messages']>({});
    const [subjects, setSubjects] = useState<useEmailMessageSaverType['subjects']>({});

    const handleSetSubject = (
        subject: string,
        lang: commissionLanguages | 'cs',
        template: Template,
    ) => {
        setSubjects((prev) => {
            const mutated = { ...prev };
            if (!mutated[template]) {
                mutated[template] = {
                    [lang]: subject,
                };
            }
            mutated[template] = {
                ...mutated[template],
                [lang]: subject,
            };
            return mutated;
        });
    };

    const handleSetMessage = (
        message: string,
        lang: commissionLanguages | 'cs',
        template: Template,
    ) => {
        setMessages((prev) => {
            const mutated = { ...prev };
            if (!mutated[template]) {
                mutated[template] = {
                    [lang]: message,
                };
            }
            mutated[template] = {
                ...mutated[template],
                [lang]: message,
            };
            return mutated;
        });
    };

    return {messages, subjects, setMessage: handleSetMessage, setSubject: handleSetSubject};
};
