import type { IconProps } from './types';

export const DispatcherMapIcon: React.FC<IconProps> = ({ ...props }) => {
    return (
        <>
            <svg
                {...props}
                id="symbolLocation"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="32"
                viewBox="0 0 24 32"
            >
                <g id="Exclusion_1" data-name="Exclusion 1" fill="#00a720">
                    <path
                        d="M 12 31.24303436279297 C 11.10647296905518 30.21704864501953 8.740694999694824 27.44063758850098 6.400809764862061 24.18623924255371 C 2.540469884872437 18.81711006164551 0.5 14.60317993164062 0.5 12 C 0.5 8.928239822387695 1.696210026741028 6.040329933166504 3.868269920349121 3.868269920349121 C 6.040329933166504 1.696210026741028 8.928239822387695 0.5 12 0.5 C 15.0717601776123 0.5 17.95966911315918 1.696210026741028 20.13172912597656 3.868269920349121 C 22.30378913879395 6.040329933166504 23.5 8.928239822387695 23.5 12 C 23.5 14.60317993164062 21.45952987670898 18.81711006164551 17.59918975830078 24.18623924255371 C 15.25930500030518 27.44063758850098 12.89352703094482 30.21704864501953 12 31.24303436279297 Z"
                        stroke="none"
                    />
                    <path
                        d="M 12 1 C 9.061790466308594 1 6.299449920654297 2.144199371337891 4.221820831298828 4.221820831298828 C 2.144199371337891 6.299449920654297 1 9.061790466308594 1 12 C 1 14.49658966064453 3.006189346313477 18.60713005065918 6.801670074462891 23.88726043701172 C 8.854430198669434 26.74296951293945 10.92863845825195 29.23019409179688 12 30.47843551635742 C 13.07136154174805 29.23019409179688 15.14556980133057 26.74296951293945 17.19832992553711 23.88726043701172 C 20.99381065368652 18.60713005065918 23 14.49658966064453 23 12 C 23 9.061790466308594 21.85580062866211 6.299449920654297 19.77816963195801 4.221820831298828 C 17.7005500793457 2.144199371337891 14.93819999694824 1 12 1 M 12 0 C 18.62741088867188 0 24 5.372579574584961 24 12 C 24 18.62741088867188 12 32 12 32 C 12 32 0 18.62741088867188 0 12 C 0 5.372579574584961 5.372579574584961 0 12 0 Z"
                        stroke="none"
                        fill="#fff"
                    />
                </g>
                <g id="icoProfile" transform="translate(4 4.001)">
                    <g id="Layer_18" transform="translate(2.927 2.927)">
                        <path
                            id="Path_10813"
                            data-name="Path 10813"
                            d="M9.316,7.094a3.044,3.044,0,1,0-4.487,0A5.091,5.091,0,0,0,2,11.639a.507.507,0,0,0,.507.507h9.132a.507.507,0,0,0,.507-.507A5.091,5.091,0,0,0,9.316,7.094ZM7.073,3.015A2.029,2.029,0,1,1,5.044,5.044,2.031,2.031,0,0,1,7.073,3.015ZM3.047,11.132A4.066,4.066,0,0,1,5.79,7.8a3.009,3.009,0,0,0,2.565,0A4.065,4.065,0,0,1,11.1,11.132Z"
                            transform="translate(-2 -2)"
                            fill="#fff"
                        />
                    </g>
                    <rect id="Rectangle_6903" data-name="Rectangle 6903" width="16" height="16" fill="none" />
                </g>
            </svg>
        </>
    );
};
