import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
    attachmentDetailFormValidationSchema,
    TAttachment,
    TAttachmentDetailFormSchema,
    TInitialCommission,
    TInitialInvoice,
} from '../types';

export interface IAttachmentDetailFormContext {
    isValid: boolean;
    attachment: TAttachment | null;
    formMethods: ReturnType<typeof useForm<TAttachmentDetailFormSchema>>;
    isNew: boolean;
    submitButtonRef: React.MutableRefObject<HTMLButtonElement | null> | null;
    initialCommission: TInitialCommission | null;
    initialInvoice: TInitialInvoice | null;

    triggerSubmit: () => void;
    setAttachment: (attachment: TAttachment) => void;
    setInitialCommission: (commission: TInitialCommission) => void;
    setInitialInvoice: (invoice: TInitialInvoice) => void;
}

const AttachmentDetailFormContext = createContext<IAttachmentDetailFormContext>({
    isValid: false,
    formMethods: undefined!,
    isNew: true,
    attachment: null,
    submitButtonRef: null,
    initialCommission: null,
    initialInvoice: null,

    triggerSubmit: () => undefined,
    setAttachment: () => undefined,
    setInitialCommission: () => undefined,
    setInitialInvoice: () => undefined,
});
export interface IAttachmentDetailFormContextProviderProps {
    children: React.ReactNode;
}
export const AttachmentDetailFormContextProvider: React.FC<IAttachmentDetailFormContextProviderProps> = ({
    children,
}) => {
    const [attachment, setAttachment] = useState<TAttachment | null>(null);
    const [initialCommission, setInitialCommission] = useState<TInitialCommission | null>(null);
    const [initialInvoice, setInitialInvoice] = useState<TInitialInvoice | null>(null);

    const isNew = useMemo(() => {
        return !attachment;
    }, [attachment]);

    const submitButtonRef = useRef<HTMLButtonElement | null>(null);
    const formMethods = useForm<TAttachmentDetailFormSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(attachmentDetailFormValidationSchema()),
    });
    const { isValid } = useFormState({ control: formMethods.control });

    const handleTriggerSubmit = () => {
        submitButtonRef?.current?.click();
    };

    return (
        <FormProvider {...formMethods}>
            <AttachmentDetailFormContext.Provider
                value={{
                    isNew,
                    isValid,
                    attachment,
                    formMethods,
                    setAttachment,
                    initialInvoice,
                    submitButtonRef,
                    initialCommission,
                    setInitialInvoice,
                    setInitialCommission,
                    triggerSubmit: handleTriggerSubmit,
                }}
            >
                {children}
            </AttachmentDetailFormContext.Provider>
        </FormProvider>
    );
};

export const useAttachmentDetailForm = (initialData?: TAttachment) => {
    const context = useContext(AttachmentDetailFormContext);

    useEffect(() => {
        if (initialData) {
            context.setAttachment(initialData);
            context.formMethods.reset(initialData);
        }
    }, [initialData]);

    return context;
};
