import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { T } from 'core/translation/types';
import { CommissionDischargeSchema, commissionLanguages, CommissionLoadingSchema } from 'modules/commissions';
import { useEmailMessageSaver, useEmailMessageSaverType } from 'modules/commissions/hooks/useEmailMessageSaver';
import { useSelectedDispatchers } from 'modules/commissions/hooks/useSelectedDispatchers';
import { selectLanguages } from 'modules/common';
import { FormGrid, MultiSwitchField, TextAreaField } from 'modules/form';
import { getFormattedDateTimeForLoadingDischarge } from 'modules/offers/utils';
import { Button, FormLabel, Typography } from 'modules/ui';
import { Subset } from 'types/helpers';
import { z } from 'zod';

import styles from './SendCommissionInquiryModal.module.scss';

interface SendCommissionInquiryModalProps {
    loadings: Subset<CommissionLoadingSchema & { companyName: string }>[];
    discharges: Subset<CommissionDischargeSchema & { companyName: string }>[];
    totalWeight: string;
    totalLoadingMeters: string;
    newSelectedDispatchersCount?: number;
    onClose: () => void;
    handleEmail: (
        data: useEmailMessageSaverType['messages']['enquiry'],
        loadings: Subset<CommissionLoadingSchema & { companyName: string }>[],
        discharges: Subset<CommissionLoadingSchema & { companyName: string }>[],
    ) => void;
}

const sendEmailForm = (t: T) =>
    z.object({
        lang: z.nativeEnum(commissionLanguages),
        message: z.string().min(10, { message: t('form.errors.minLengthOther', { minLength: 10 }) }),
    });

export type SendEmailForm = z.infer<ReturnType<typeof sendEmailForm>>;

export const SendCommissionInquiryModal: React.FC<SendCommissionInquiryModalProps> = ({
    loadings,
    discharges,
    totalWeight,
    totalLoadingMeters,
    newSelectedDispatchersCount,
    onClose,
    handleEmail,
}) => {
    const { t } = useTranslation();
    const selectedDispatchersThreshold = 50;
    const template = 'enquiry';
    const { messages, setMessage } = useEmailMessageSaver();
    const { selectedDispatchers } = useSelectedDispatchers();

    const [warned, setWarned] = useState(false);

    const formDefaultValues = {
        lang: commissionLanguages['čeština'],
        message: `Dobrý den, Zasíláme poptávku na přepravu: nakládka: Znojmo 28. 11. 2023 vykládka: Jihlava 29. 11. 2023 V případě zájmu nás prosím kontaktujte s cenovou nabídkou. Děkuji S přáním hezkého dne`,
    };

    const methods = useForm<SendEmailForm>({
        defaultValues: formDefaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(sendEmailForm(t)),
    });
    const { control, handleSubmit, setValue } = methods;
    const { lang, message } = useWatch({ control });

    const hasDuplicates = useMemo(() => {
        // const seen: Record<string, boolean> = {};
        // let duplicatedEmail = '';
        // const duplicates = selectedDispatchers.some(
        //     ({ email }) => (seen.hasOwnProperty(email) ? (duplicatedEmail = email) : false) || (seen[email] = false),
        // );
        // return duplicates ? duplicatedEmail : false;
        // todo: temporary blocker for validation of duplicates - remove after dispatcher emails are unique
        return false;
    }, [selectedDispatchers]);

    useEffect(() => {
        const generatedMessages = generateMessages();
        Object.keys(generatedMessages).forEach((key) => {
            setMessage(generatedMessages[key as keyof typeof generateMessages], key as commissionLanguages, template);
        });
        setValue('message', generatedMessages[lang as commissionLanguages]);
    }, [loadings, discharges, totalLoadingMeters, totalWeight]);

    useEffect(() => {
        if (!lang) return;
        if (messages[template]?.[lang]) setValue('message', messages[template][lang] as string);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    useEffect(() => {
        message && lang && setMessage(message, lang, template);
    }, [message]);

    const onSubmit = (/*data: SendEmailForm*/) => {
        handleEmail(messages[template], loadings, discharges);
    };

    const handleButtonSubmit = () => {
        if (!warned && (newSelectedDispatchersCount || selectedDispatchers.length) >= selectedDispatchersThreshold) {
            setWarned(true);
            return;
        }
        handleSubmit(onSubmit, (error) => console.log(error))();
    };

    const generateMessages = () => {
        const EMPTY_PLACEHOLDER = '-';

        const loading = loadings[0];
        const discharge = discharges[discharges.length - 1];

        const traceText = `${loading.location?.countryCode || EMPTY_PLACEHOLDER} ${
            loading.location?.postalCode || EMPTY_PLACEHOLDER
        } ${loading.location?.city || EMPTY_PLACEHOLDER} -> ${discharge.location?.countryCode || EMPTY_PLACEHOLDER} ${
            discharge.location?.postalCode || EMPTY_PLACEHOLDER
        } ${discharge.location?.city || EMPTY_PLACEHOLDER}`;

        const goodsText = `${totalLoadingMeters || EMPTY_PLACEHOLDER}, ${totalWeight || EMPTY_PLACEHOLDER}`;
        const loadingDateTimeText = getFormattedDateTimeForLoadingDischarge(
            loading.date || '',
            loading.dateTo || null,
            loading.time || null,
        );

        const messagesTemplates = {
            cs: `Dobrý den,\n\nmám náklad:\n${traceText}\n\n${goodsText}\n\n Připraveno k nakládce ${loadingDateTimeText}\n\nV případě zájmu se prosím {action} do systému Qapline a reagujte na nabídku.\n\n{link}\n\nS přáním hezkého dne\n\n`,
            en: `Hello,\n\nI have a cargo:\n${traceText}\n\n${goodsText}\n\n Ready for loading ${loadingDateTimeText}\n\nIf you are interested, please {action} in the Qapline system and respond to the offer.\n\n{link}\n\nBest regards\n\n`,
            de: `Hallo,\n\nIch habe eine Fracht:\n${traceText}\n\n${goodsText}\n\n Bereit zum Laden ${loadingDateTimeText}\n\nWenn Sie interessiert sind, {action} Sie sich bitte im Qapline-System und reagieren Sie auf das Angebot.\n\n{link}\n\nMit freundlichen Grüßen\n\n`,
        };
        return messagesTemplates;
    };

    return (
        <div className={styles.modal}>
            <div className={styles.container}>
                <FormProvider {...methods}>
                    <Form
                        id="send-commission-demand-form"
                        onSubmit={handleSubmit(onSubmit, (error) => console.log(error))}
                    >
                        <div className={styles.title}>
                            <Typography variant="h4" className={styles.type}>
                                {t('commissions.sendCommissionInquiryModal.label')}
                            </Typography>
                        </div>
                        <FormGrid columns={1}>
                            <div>
                                <FormLabel>{t('commissions.sendCommissionInquiryModal.language')}</FormLabel>
                                <MultiSwitchField name="lang" label="" options={selectLanguages} />
                            </div>
                            <TextAreaField
                                name="message"
                                rows={14}
                                label={t('commissions.sendCommissionInquiryModal.message')}
                            />
                            {hasDuplicates && (
                                <div className={styles.errorMessage}>
                                    {t('commissions.sendCommissionInquiryModal.duplicates', { email: hasDuplicates })}
                                </div>
                            )}
                            <div className={styles.buttons}>
                                <Button
                                    className={styles.btn}
                                    type="button"
                                    color="green"
                                    variant="secondary"
                                    onClick={onClose}
                                >
                                    {t('commissions.sendCommissionInquiryModal.cancel')}
                                </Button>
                                <Button
                                    onClick={handleButtonSubmit}
                                    className={styles.btn}
                                    color="red"
                                    danger={warned}
                                    disabled={!!hasDuplicates}
                                    variant="primary"
                                >
                                    {warned
                                        ? t('commissions.sendCommissionInquiryModal.confirmation', {
                                              count: selectedDispatchers.length,
                                          })
                                        : t('commissions.sendCommissionInquiryModal.approve')}
                                </Button>
                            </div>
                        </FormGrid>
                    </Form>
                </FormProvider>
            </div>
        </div>
    );
};
