import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { IconDownload } from 'assets/icons';
import { useLazyGetAttachmentFileQuery } from 'modules/attachments/services';
import { Button, ButtonProps } from 'modules/ui';
import { performFileDownload } from 'utils/performFileDownload';

export interface IDownloadAttachmentFileButtonProps extends ButtonProps {
    attachmentId: number;
    hideText?: boolean;
}

export const DownloadAttachmentFileButton: React.FC<IDownloadAttachmentFileButtonProps> = ({
    attachmentId,
    hideText,
    ...props
}) => {
    const { t } = useTranslation();
    const [getAttachmentFile, { isLoading }] = useLazyGetAttachmentFileQuery();

    const handleDownload = async () => {
        try {
            const { data, headers } = await getAttachmentFile({ attachmentId }, true).unwrap();
            const fileName = headers.get('Content-Disposition')?.split('filename=')[1] || '';
            performFileDownload(data, decodeURIComponent(fileName));
        } catch (error: any) {
            if (error?.data?.message) {
                return toast.error(error.data.message);
            }
            toast.error(t('attachments.download.error'));
        }
    };
    return (
        <>
            <Button
                Icon={hideText ? <IconDownload /> : undefined}
                type="button"
                variant="secondary"
                onClick={handleDownload}
                isLoading={isLoading}
                {...props}
            >
                {!hideText && <IconDownload />}
                {!hideText && t('common.subheader.download')}
            </Button>
        </>
    );
};
