import React from 'react';
import { BaseFieldProps } from 'modules/form';

import styles from './CarrierSearchMultiSwitch.module.scss';

export type CarrierSearchMultiSwitchProps = BaseFieldProps & {
    value: string | number | null;
    onChange: (value: string | number | null) => void;
    options: { label: string; value: string | number }[];
    isDisabled?: boolean;
};

export const CarrierSearchMultiSwitch: React.FC<CarrierSearchMultiSwitchProps> = ({
    options,
    value: fieldValue,
    isDisabled,
    onChange,
    ...props
}) => (
    <div className={styles['wrapper']} {...props}>
        {options.map(({ label, value }, index) => {
            const buttonClassName = `${styles['customSearchCarrierButton']} ${isDisabled ? styles['disabledBtn'] : ''}`;

            return (
                <button
                    disabled={isDisabled}
                    type="button"
                    className={buttonClassName}
                    role="radio"
                    value={value}
                    key={index}
                    onClick={() => {
                        if (isDisabled) return;
                        if (fieldValue === value) {
                            onChange(null);
                        } else {
                            onChange(value);
                        }
                    }}
                    data-state={value == fieldValue ? 'on' : 'off'}
                    {...props}
                >
                    {label}
                </button>
            );
        })}
    </div>
);
