import type { IconProps } from './types';

export const HqMapIcon: React.FC<IconProps> = ({ ...props }) => {
    return (
        <>
            <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32">
                <g id="symbolLocation" transform="translate(0 0)">
                    <g id="Exclusion_1" data-name="Exclusion 1" fill="#1770fe">
                        <path
                            d="M 12 31.24304962158203 C 11.10647296905518 30.21706390380859 8.740694999694824 27.44065284729004 6.400809764862061 24.18625450134277 C 2.540469884872437 18.81712532043457 0.5 14.60319519042969 0.5 12.00001525878906 C 0.5 8.928255081176758 1.696210026741028 6.040345191955566 3.868269920349121 3.868285179138184 C 6.040329933166504 1.69622528553009 8.928239822387695 0.5000152587890625 12 0.5000152587890625 C 15.0717601776123 0.5000152587890625 17.95966911315918 1.69622528553009 20.13172912597656 3.868285179138184 C 22.30378913879395 6.040345191955566 23.5 8.928255081176758 23.5 12.00001525878906 C 23.5 14.60319519042969 21.45952987670898 18.81712532043457 17.59918975830078 24.18625450134277 C 15.25930500030518 27.44065284729004 12.89352703094482 30.21706390380859 12 31.24304962158203 Z"
                            stroke="none"
                        />
                        <path
                            d="M 12 1.000015258789062 C 9.061790466308594 1.000015258789062 6.299449920654297 2.144214630126953 4.221820831298828 4.221836090087891 C 2.144199371337891 6.299465179443359 1 9.061805725097656 1 12.00001525878906 C 1 14.49660491943359 3.006189346313477 18.60714530944824 6.801670074462891 23.88727569580078 C 8.854430198669434 26.74298477172852 10.92863845825195 29.23020935058594 12 30.47845077514648 C 13.07136154174805 29.23020935058594 15.14556980133057 26.74298477172852 17.19832992553711 23.88727569580078 C 20.99381065368652 18.60714530944824 23 14.49660491943359 23 12.00001525878906 C 23 9.061805725097656 21.85580062866211 6.299465179443359 19.77816963195801 4.221836090087891 C 17.7005500793457 2.144214630126953 14.93819999694824 1.000015258789062 12 1.000015258789062 M 12 1.52587890625e-05 C 18.62741088867188 1.52587890625e-05 24 5.372594833374023 24 12.00001525878906 C 24 18.62742614746094 12 32.00001525878906 12 32.00001525878906 C 12 32.00001525878906 0 18.62742614746094 0 12.00001525878906 C 0 5.372594833374023 5.372579574584961 1.52587890625e-05 12 1.52587890625e-05 Z"
                            stroke="none"
                            fill="#fff"
                        />
                    </g>
                    <g id="icoHouse" transform="translate(4 4.001)">
                        <rect id="Rectangle_6903" data-name="Rectangle 6903" width="16" height="16" fill="none" />
                        <path
                            id="Path_10825"
                            data-name="Path 10825"
                            d="M4.5-1a.578.578,0,0,1,.385.146L9.806,3.521A.579.579,0,0,1,10,3.953V9.421A.579.579,0,0,1,9.421,10H-.421A.579.579,0,0,1-1,9.421V3.953a.579.579,0,0,1,.194-.433L4.115-.854A.578.578,0,0,1,4.5-1ZM8.842,4.213,4.5.354.158,4.213V8.842H8.842Z"
                            transform="translate(3.5 3.5)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </svg>
        </>
    );
};
