import { TFunction } from 'i18next';
import z from 'zod';

export type TMailModalFormSchemaArgs = {
    maxRecipients?: number;
};

export const mailModalFormSchema = (args?: TMailModalFormSchemaArgs) => {
    const schema = z.object({
        recipients: z
            .array(
                z.object({
                    value: z.string(),
                    label: z.string(),
                }),
            )
            .min(1)
            .max(args?.maxRecipients || 100),
        message: z.string().min(1),
        attachments: z.array(z.object({ value: z.any(), label: z.string().min(1).optional() })),
    });
    return schema;
};

export const mailModalFormValidationSchema = (t: TFunction, args?: TMailModalFormSchemaArgs) => {
    return mailModalFormSchema(args).superRefine(({ recipients }, ctx) => {
        recipients.forEach((recipient, index) => {
            const valueEmailCheck = z.string().email();
            const labelEmailCheck = z.string().email();

            const valueResult = valueEmailCheck.safeParse(recipient.value);
            const labelResult = labelEmailCheck.safeParse(recipient.label);

            if (!valueResult.success || !labelResult.success) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: t('form.errors.itemOnPositionHasToBeEmail', { position: index + 1 }),
                    path: ['recipients'],
                });
            }
        });
    });
};

export type TMailModalFormSchema = z.infer<ReturnType<typeof mailModalFormSchema>>;
export type TMailModalTransformedValues = {
    recipients: string[];
    message: string;
    attachments: string[];
};
