import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';
import { baseQueryWithAuthRefresh } from 'core/api/baseQuery';
import i18n from 'core/translation/i18next/i18next';
import {
    ActualCommissionSchema,
    actualCommissionSchema,
    CommissionSchema,
    type CommissionsPageSchema,
    commissionsPageSchema,
    CommissionsTableSchema,
    CommissionSummarySchema,
    PostEnquiryEmailResponse,
    SendCreateDeliveryConfirmationPdfSchema,
    SendCreateLoadingConfirmationPdfSchema,
    SendCreateNeutralizationPdfSchema,
    SendCreateOrderCarrierPdfSchema,
    SendCreateOrderConfirmationEmailSchema,
    SendInquirySchema,
    SendUniversalOrderEmailSchema,
    TListCommissionNumberResponseBody,
    TListCommissionNumbersRequestBody,
} from 'modules/commissions';
import { QueryParams } from 'types/query-params';

import { TransformedCommissionData } from '../utils/FormattingCommissionData/types';
export interface Message {
    event: 'invalidate';
    data: 'Carriers';
}

export const commissionsApi = createApi({
    reducerPath: 'commissionsApi',
    baseQuery: baseQueryWithAuthRefresh,
    tagTypes: ['Commissions', 'CommissionItem', 'PDF', 'Email', 'Summary', 'CommissionNumbers'],
    endpoints: (builder) => ({
        getCommissions: builder.query<CommissionsPageSchema, QueryParams<CommissionsTableSchema>>({
            providesTags: ['Commissions', 'CommissionItem'],
            query: (params) => ({
                url: config.api.endpoints.commissions,
                params,
            }),
            transformResponse: (res: CommissionsPageSchema) => {
                const validatedSchema = commissionsPageSchema().safeParse(res);
                // used for back compatibility; almost all commissions are incorrect
                if (!validatedSchema.success) {
                    console.warn('Validation failed in getCommissions');
                }
                return res;
            },
        }),
        getCommissionSummary: builder.query<CommissionSummarySchema, QueryParams<CommissionsTableSchema>>({
            providesTags: ['Summary'],
            query: (params) => ({
                url: config.api.endpoints.commissionSummary,
                params,
            }),
        }),
        getOneCommission: builder.query<ActualCommissionSchema, QueryParams<{ id: number }>>({
            providesTags: (_result, _error, arg) => [{ type: 'CommissionItem', id: arg.id }],
            query: ({ id = '' }) => ({
                url: `${config.api.endpoints.commissions}/${id}`,
            }),
            transformResponse: (res: ActualCommissionSchema) => {
                const validatedSchema = actualCommissionSchema(i18n.t).safeParse(res);
                // used for back compatibility; almost all commissions are incorrect
                if (!validatedSchema.success) {
                    console.warn('Validation failed in getCommission');
                }
                return res;
            },
        }),
        postNewCommission: builder.mutation<CommissionSchema, { data: TransformedCommissionData }>({
            query: ({ data }: { data: TransformedCommissionData }) => ({
                url: config.api.endpoints.commissions,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Commissions', 'Summary']),
        }),
        putUpdatedCommission: builder.mutation<CommissionSchema, { data: TransformedCommissionData; id: number }>({
            query: ({ data, id }) => ({
                url: `${config.api.endpoints.commissions}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (_, error, arg) =>
                error ? [] : ['Commissions', { type: 'CommissionItem', id: arg.id }, 'Summary'],
        }),
        deleteCommission: builder.mutation<CommissionSchema, { id: number }>({
            query: ({ id }) => ({
                url: `${config.api.endpoints.commissions}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Commissions', 'Summary']),
        }),
        // createOrderCarrier - Objednávka na dopravce
        postOrderCarrier: builder.query({
            providesTags: ['Commissions'],
            query: ({ body }) => ({
                url: config.api.endpoints.orderCarrier,
                method: 'POST',
                body,
            }),
        }),
        postOrderCarrierPdf: builder.query<
            Blob,
            { body: SendCreateOrderCarrierPdfSchema; aborter?: () => AbortSignal }
        >({
            providesTags: ['PDF'],
            query: ({ body, aborter }: { body: SendCreateOrderCarrierPdfSchema; aborter?: () => AbortSignal }) => {
                const signal = aborter?.();
                return {
                    url: config.api.endpoints.orderCarrierPdf,
                    method: 'POST',
                    body,
                    signal,
                    responseHandler: (response) => response.blob(),
                };
            },
        }),
        postOrderCarrierConfirmationEmail: builder.query({
            providesTags: ['Email'],
            query: ({ body }: { body: SendUniversalOrderEmailSchema }) => ({
                url: config.api.endpoints.orderCarrierConfirmationEmail,
                method: 'POST',
                body,
            }),
        }),
        //  createOrderConfirmation - Potvrzení objednávky
        postOrderConfirmationEmail: builder.query({
            providesTags: ['Email'],
            query: ({ body }: { body: SendCreateOrderConfirmationEmailSchema }) => ({
                url: config.api.endpoints.orderConfirmationEmail,
                method: 'POST',
                body,
            }),
        }),
        // createLoadingConfirmation - Potvrzení nakládky
        postLoading: builder.query({
            providesTags: ['Commissions'],
            query: ({ body }: { body: SendCreateLoadingConfirmationPdfSchema }) => ({
                url: config.api.endpoints.orderLoading,
                method: 'POST',
                body,
            }),
        }),
        postLoadingPdf: builder.query<
            Blob,
            { body: SendCreateLoadingConfirmationPdfSchema; aborter?: () => AbortSignal }
        >({
            providesTags: ['PDF'],
            query: ({ body, aborter }) => {
                const signal = aborter?.();
                return {
                    url: config.api.endpoints.orderLoadingPdf,
                    method: 'POST',
                    signal,
                    body,
                    responseHandler: (response) => response.blob(),
                };
            },
        }),
        postLoadingConfirmationEmail: builder.query({
            providesTags: ['Email'],
            query: ({ body }: { body: SendUniversalOrderEmailSchema }) => ({
                url: config.api.endpoints.orderLoadingConfirmationEmail,
                method: 'POST',
                body,
            }),
        }),
        // createDeliveryConfirmation - Potvrzení doručení zboží
        postDelivery: builder.query({
            providesTags: ['Commissions'],
            query: ({ body }: { body: SendCreateDeliveryConfirmationPdfSchema }) => ({
                url: config.api.endpoints.orderDelivery,
                method: 'POST',
                body,
            }),
        }),
        postDeliveryPdf: builder.query<
            Blob,
            { body: SendCreateDeliveryConfirmationPdfSchema; aborter?: () => AbortSignal }
        >({
            providesTags: ['PDF'],
            query: ({ body, aborter }) => {
                const signal = aborter?.();
                return {
                    url: config.api.endpoints.orderDeliveryPdf,
                    method: 'POST',
                    body,
                    signal,
                    responseHandler: (response) => response.blob(),
                };
            },
        }),
        postDeliveryConfirmationEmail: builder.query({
            providesTags: ['Email'],
            query: ({ body }: { body: SendUniversalOrderEmailSchema }) => ({
                url: config.api.endpoints.orderDeliveryConfirmationEmail,
                method: 'POST',
                body,
            }),
        }),
        postNeutralizationPdf: builder.query<
            Blob,
            { body: SendCreateNeutralizationPdfSchema; aborter?: () => AbortSignal }
        >({
            providesTags: ['Commissions'],
            query: ({ body, aborter }) => {
                aborter?.();
                return {
                    url: config.api.endpoints.orderDischargePdf,
                    method: 'POST',
                    body,
                    responseHandler: (response) => response.blob(),
                };
            },
        }),
        postNeutralizationConfirmationEmail: builder.query({
            providesTags: ['Email'],
            query: ({ body }: { body: SendUniversalOrderEmailSchema & { dischargeNumber: number } }) => ({
                url: config.api.endpoints.orderDischargeConfirmationEmail,
                method: 'POST',
                body,
            }),
        }),
        postInquiryEmail: builder.mutation<PostEnquiryEmailResponse, { body: SendInquirySchema }>({
            invalidatesTags: (_result, _error, { body }) => [{ type: 'CommissionItem', id: body.commissionId }],
            query: ({ body }) => ({
                url: config.api.endpoints.carrierInquiry,
                method: 'POST',
                body,
            }),
        }),
        listCommissionNumbers: builder.query<TListCommissionNumberResponseBody, TListCommissionNumbersRequestBody>({
            query: ({ search, age }) => {
                return {
                    url: config.api.endpoints.commissionListNumbers,
                    method: 'GET',
                    params: {
                        ...(search ? { search } : {}),
                        ...(age ? { age } : {}),
                    },
                };
            },
            providesTags: (_, __, arg) => [{ type: 'CommissionNumbers', id: arg.search }],
        }),
    }),
});

export const {
    useGetCommissionsQuery,
    useGetOneCommissionQuery,
    usePostNewCommissionMutation,
    usePutUpdatedCommissionMutation,
    useDeleteCommissionMutation,
    useLazyPostOrderCarrierQuery,
    useLazyPostOrderCarrierPdfQuery,
    useLazyPostOrderCarrierConfirmationEmailQuery,
    useLazyPostOrderConfirmationEmailQuery,
    useLazyPostLoadingQuery,
    useLazyPostLoadingPdfQuery,
    useGetCommissionSummaryQuery,
    useLazyPostLoadingConfirmationEmailQuery,
    useLazyPostDeliveryPdfQuery,
    useLazyPostDeliveryQuery,
    useLazyPostDeliveryConfirmationEmailQuery,
    useLazyPostNeutralizationPdfQuery,
    useLazyPostNeutralizationConfirmationEmailQuery,
    usePostInquiryEmailMutation,
    useListCommissionNumbersQuery,
    useLazyListCommissionNumbersQuery,
} = commissionsApi;
