import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import classNames from 'classnames';
import { Spinner } from 'modules/ui';

import commissionAttachmentWrappingStyles from './InvoiceDocumentCommissionAttachment.module.scss';
import defaultWrappingStyles from './InvoiceDocumentDefault.module.scss';

export type TInvoiceDocumentProps = {
    blobUrl: string | null;
    loading: boolean;
    pdfWrapperStyles?: 'default' | 'commissionAttachments';
};

export const InvoiceDocument: React.FC<TInvoiceDocumentProps> = ({
    blobUrl,
    loading,
    pdfWrapperStyles = 'default',
}) => {
    const { t } = useTranslation();
    const [pdfPages, setPdfPages] = useState<number>(0);
    const [pageWidth, setPageWidth] = useState(0);
    const [wrapperStyling, setWrapperStyling] = useState<CSSModuleClasses>(defaultWrappingStyles);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const documentPageRef = useRef<HTMLDivElement>(null);

    const handleOnLoad = ({ numPages }: { numPages: number }) => {
        setPdfPages(numPages);
    };

    const handleChangePage = (direction: 'next' | 'prev') => {
        if (direction === 'next') {
            if (currentPage < pdfPages) setCurrentPage(currentPage + 1);
            return;
        }
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    // This component is used multiple times and requires specific wrapper styling based on current use case
    useEffect(() => {
        if (pdfWrapperStyles === 'default') {
            //used in invoicing
            setWrapperStyling(defaultWrappingStyles);
        } else if (pdfWrapperStyles === 'commissionAttachments') {
            //used in commission attachments
            setWrapperStyling(commissionAttachmentWrappingStyles);
        }
    });

    useEffect(() => {
        const getDimensions = () => documentPageRef.current?.offsetWidth || 0;
        const handleResize = () => {
            setPageWidth(getDimensions());
        };
        if (documentPageRef.current) {
            setPageWidth(getDimensions());
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [blobUrl]);

    return (
        <div className={wrapperStyling.documentBox}>
            <div className={wrapperStyling.documentWrapper}>
                {pdfPages > 1 && (
                    <ChevronLeftIcon
                        className={classNames(wrapperStyling.arrowLeft, currentPage === 1 && wrapperStyling.disabled)}
                        onClick={() => handleChangePage('prev')}
                    />
                )}
                <div className={wrapperStyling.documentContainer} ref={documentPageRef}>
                    <Document
                        className={wrapperStyling.document}
                        file={blobUrl}
                        onLoadSuccess={handleOnLoad}
                        noData={() => (
                            <div className={wrapperStyling.noDocument}>
                                {loading ? <Spinner fullScreen /> : <div>{t('commissions.export.noDocument')}</div>}
                            </div>
                        )}
                    >
                        <Page
                            width={pageWidth}
                            pageNumber={currentPage}
                            devicePixelRatio={2}
                            renderAnnotationLayer={false}
                        />
                    </Document>
                </div>
                {pdfPages > 1 && (
                    <ChevronRightIcon
                        className={classNames(
                            wrapperStyling.arrowRight,
                            currentPage === pdfPages && wrapperStyling.disabled,
                        )}
                        onClick={() => handleChangePage('next')}
                    />
                )}
            </div>
        </div>
    );
};
