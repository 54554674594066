import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon, MinusIcon } from 'assets/icons';
import classNames from 'classnames';
import { Typography } from 'modules/ui';

import styles from './AttachmentSentStatusPill.module.scss';

export interface IAttachmentSentStatusPillProps {
    sent: boolean;
    size?: 'big' | 'small';
}

export const AttachmentSentStatusPill: React.FC<IAttachmentSentStatusPillProps> = ({ sent, size = 'big' }) => {
    const { t } = useTranslation();

    return (
        <div
            className={classNames(styles.pillWrapper, {
                [styles.sent]: sent,
                [styles.small]: size === 'small',
            })}
        >
            {sent ? (
                <>
                    <CheckIcon className={styles.checkIcon} />
                    <Typography className={styles.sentText} fontWeight="medium" variant="p">
                        {t('attachments.mail.sent')}
                    </Typography>
                </>
            ) : (
                <>
                    <MinusIcon className={styles.minusIcon} />
                    <Typography fontWeight="medium" color="secondary" variant="p">
                        {t('attachments.mail.notSent')}
                    </Typography>
                </>
            )}
        </div>
    );
};
