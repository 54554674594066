import { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { config } from 'config';
import { useLoginUserMutation, useRefreshTokenMutation } from 'core/api';
import { useDocumentTitle } from 'core/application/hooks';
import { LoginError } from 'core/auth/types';
import { useQuery } from 'hooks';
import { defaultRedirects } from 'modules/common';
import { Form, TextField } from 'modules/form';
import { Button, Typography } from 'modules/ui';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';

import { VisualFormInputsContext } from '../../../../modules/form/components/Form/Form';
import { useAuth } from '../../hooks';
import { type LoginSchema, loginSchema } from '../../schemas';
import { AuthPage } from '../AuthPage';

import styles from './LoginForm.module.scss';

export const LoginForm = () => {
    const { t } = useTranslation();
    useDocumentTitle(t('auth.login.title'));

    const query = useQuery();
    const { isAuthenticated, user } = useAuth();
    const [refreshToken] = useRefreshTokenMutation();
    const [loginUser, { isLoading }] = useLoginUserMutation();
    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);
    const navigate = useNavigate();

    const methods = useForm<LoginSchema>({
        resolver: zodResolver(loginSchema(t)),
        defaultValues: {
            email: query.get('email') || '',
            password: '',
        },
    });
    const {
        handleSubmit,
        formState: { isSubmitting },
        setError,
    } = methods;

    useEffect(() => {
        const fetchRefreshToken = async () => {
            try {
                // don't refresh token if user is authenticated (immediately after login)
                if (isAuthenticated) return;
                // handle case when use is already authenticated
                await refreshToken({}).unwrap();
                // if user is not authenticated

                const enquiryId = query.get('enquiryId');
                // if redirect to the offer exists
                enquiryId
                    ? navigate(config.routes.offers.detail.replace(':id', enquiryId))
                    : user && navigate(defaultRedirects[user.role]);
            } catch (_) {}
        };
        fetchRefreshToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const onSubmit: SubmitHandler<LoginSchema> = async (credentials) => {
        try {
            const result = await loginUser({ ...credentials });
            if ('data' in result) {
                const enquiryId = query.get('enquiryId');
                if (enquiryId) {
                    navigate(config.routes.offers.detail.replace(':id', enquiryId));
                }

                return toast.success(t('auth.Login.success'));
            }
            toast.error(t('auth.Login.error'));
        } catch (e) {
            const error = e as LoginError;
            setError('email', { message: error.message });
        }
    };

    return (
        <AuthPage>
            <Typography variant="h1">{t('auth.login.title')}</Typography>
            {isAuthenticated ? (
                <Navigate to={'/'} replace={true} />
            ) : (
                <FormProvider {...methods}>
                    <VisualFormInputsContext.Provider
                        value={{
                            visualInputsList,
                            setVisualInputsList,
                        }}
                    >
                        <Form
                            onSubmit={handleSubmit(onSubmit, (error) =>
                                handleErrorsWithNoInputs(error, visualInputsList),
                            )}
                        >
                            <div className={styles.fields}>
                                <TextField
                                    name="email"
                                    type="email"
                                    label={t('auth.email.label')}
                                    placeholder={t('auth.email.placeholder')}
                                    autoComplete="username"
                                />
                                <TextField
                                    name="password"
                                    type="password"
                                    label={t('auth.password.label')}
                                    placeholder={t('auth.password.placeholder')}
                                    autoComplete="current-password"
                                />
                            </div>
                            {/* <Link className={styles.link} to={config.routes.auth.requestResetPassword}>
                                {t('auth.login.forgotPassword')}
                            </Link> */}
                            <div className={styles.buttons}>
                                <Button type="submit" disabled={isSubmitting} isLoading={isLoading}>
                                    {t('auth.login.submit')}
                                </Button>
                            </div>
                        </Form>
                    </VisualFormInputsContext.Provider>
                </FormProvider>
            )}
        </AuthPage>
    );
};
