import React from 'react';
import { IconFile, TrashIconRedStroke } from 'assets/icons';
import { motion } from 'framer-motion';
import { Typography } from 'modules/ui';
import { formatFileSize } from 'utils/fileSize';

import styles from './AttachmentItem.module.scss';

export interface IAttachmentItemProps {
    attachment: { file: File; id: string };
    index: number;
    onRemove: (index: number) => void;
}

export const AttachmentItem: React.FC<IAttachmentItemProps> = ({ attachment, onRemove, index }) => {
    const { name, size } = attachment.file;

    const handleRemove = () => {
        onRemove(index);
    };

    return (
        <motion.div layout className={styles.wrapper}>
            <div className={styles.iconWrapper}>
                <IconFile className={styles.fileIcon} />
            </div>
            <div className={styles.infoWrapper}>
                <Typography variant="p" style={{ wordBreak: 'break-word', lineHeight: 1.3 }}>
                    {name}
                </Typography>
                <Typography variant="subheading" fontWeight="normal" style={{ wordBreak: 'break-word' }}>
                    {formatFileSize(size)}
                </Typography>
            </div>
            <div className={styles.deleteIconWrapper} onClick={handleRemove}>
                <TrashIconRedStroke className={styles.deleteIcon} />
            </div>
        </motion.div>
    );
};
