import { Dispatch, SetStateAction } from 'react';
import { Row } from '@tanstack/react-table';
import { Table, TableContainer, TableProps } from 'modules/table';

import { DispatcherSearchTableItem, useDispatcherSearchTable } from '../../hooks/useDispatcherSearchTable';

export type TSelectedDispatchersTableProps = {
    selectedDispatchers: DispatcherSearchTableItem[];
    disabledIds?: number[];
    setSelectedDispatchers: Dispatch<SetStateAction<DispatcherSearchTableItem[]>>;
    tableName: string;
} & Pick<TableProps, 'overflow' | 'storeColumnWidth' | 'fixedHeight'>;
export const SelectedDispatchersTable: React.FC<TSelectedDispatchersTableProps> = ({
    selectedDispatchers,
    disabledIds,
    setSelectedDispatchers,
    tableName,
    fixedHeight = 300,
    overflow,
    storeColumnWidth,
}) => {
    const onRowDeselect = (rows: Row<DispatcherSearchTableItem>[]) => {
        setSelectedDispatchers((prev) =>
            prev.filter((dispatcher) => !rows.find((row) => row.original.dispatcher_id === dispatcher.dispatcher_id)),
        );
    };

    const { paginatedData, filteredData, columns, tableProps, changedData } = useDispatcherSearchTable(
        selectedDispatchers,
        tableName,
        undefined,
        disabledIds,
        undefined,
        onRowDeselect,
    );

    // set row selection to be always full
    tableProps.rowSelection = changedData.reduce((acc: Record<number, boolean>, curr) => {
        acc[curr.dispatcher_id] = true;
        return acc;
    }, {});
    selectedDispatchers.length > 0 && (tableProps.allRowsSelected = true);

    return (
        <>
            <TableContainer>
                <Table
                    data={paginatedData}
                    filtered={filteredData}
                    columns={columns}
                    totalCount={filteredData?.length}
                    bordered={true}
                    fixedHeight={fixedHeight ? 300 : undefined}
                    storeColumnWidth={storeColumnWidth}
                    overflow={overflow}
                    {...tableProps}
                />
            </TableContainer>
        </>
    );
};
