import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'modules/ui/components/FormLabel';

import { TMailModalFormSchema } from '../../MailModal.types';

import styles from './AttachmentsList.module.scss';

export interface IAttachmentsListProps {}

export const AttachmentsList: React.FC<IAttachmentsListProps> = () => {
    const { t } = useTranslation();
    const { fields } = useFieldArray<TMailModalFormSchema>({ name: 'attachments' });
    return (
        <div>
            <FormLabel>{t('common.attachments')}</FormLabel>
            <p className={styles.attachedFiles}>{fields.map(({ value, label }) => label || value).join(', ')}</p>
        </div>
    );
};
