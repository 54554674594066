import { useTranslation } from 'react-i18next';

import { CommissionHeaderSwitchItem } from './components/CommissionHeaderSwitchItem';
import { SelectedTabOptions } from './types';

import styles from './CommissionHeaderSwitch.module.scss';

type CommissionHeaderSwitchProps = {
    onTabSelect: (selectedTab: SelectedTabOptions) => void;
    selectedTab: SelectedTabOptions;
};

export const CommissionHeaderSwitch: React.FC<CommissionHeaderSwitchProps> = ({ onTabSelect, selectedTab }) => {
    const { t } = useTranslation();

    const handleTabClick = (selectedTab: SelectedTabOptions) => {
        onTabSelect(selectedTab);
    };

    const tabOptions = [
        {
            option: SelectedTabOptions.Configuration,
            label: t('commissions.form.commissionTab.configuration'),
        },
        {
            option: SelectedTabOptions.Carrier,
            label: t('commissions.form.commissionTab.carrier'),
        },
        {
            option: SelectedTabOptions.Offer,
            label: t('commissions.form.commissionTab.offer'),
        },
    ];

    return (
        <div className={styles.wrapper}>
            {tabOptions.map(({ option, label }) => (
                <CommissionHeaderSwitchItem
                    key={option}
                    isSelected={selectedTab === option}
                    onClick={() => handleTabClick(option)}
                >
                    {label}
                </CommissionHeaderSwitchItem>
            ))}
        </div>
    );
};
