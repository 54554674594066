import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { config } from 'config';
import { AttachmentsTable } from 'modules/attachments/components/AttachmentsTable';
import { FormActionButton } from 'modules/form/components/FormActionButton';
import { FormSection } from 'modules/form/components/FormSection';

export interface IAttachmentsSectionProps {
    commissionId: number;
    commissionNumber: number;
}

export const AttachmentsSection: React.FC<IAttachmentsSectionProps> = ({ commissionId, commissionNumber }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigateToCreateAttachment = () => {
        navigate({
            pathname: config.routes.attachments.new,
            search: createSearchParams({
                commission: JSON.stringify({
                    commission_id: commissionId,
                    number: commissionNumber,
                }),
            }).toString(),
        });
    };

    return (
        <FormSection
            title={t('attachments.title')}
            headerEndSlot={
                <FormActionButton onClick={handleNavigateToCreateAttachment}>
                    {t('attachments.table.addAttachment')}
                </FormActionButton>
            }
        >
            <AttachmentsTable commissionId={commissionId} columnsVisibility={{ commission: false }} />
        </FormSection>
    );
};
