import React from 'react';
import { motion } from 'framer-motion';

import { AttachmentItem, IAttachmentItemProps } from '../AttachmentItem';

import styles from './AttachmentsList.module.scss';

export interface IAttachmentsListProps extends Pick<IAttachmentItemProps, 'onRemove'> {
    attachments: { file: File; id: string }[];
}

export const AttachmentsList: React.FC<IAttachmentsListProps> = ({ attachments, onRemove }) => {
    return (
        <motion.div layout className={styles.wrapper}>
            {attachments.map((attachment, index) => (
                <AttachmentItem key={attachment.id} {...{ index, attachment, onRemove }} />
            ))}
        </motion.div>
    );
};
