import React from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { type FieldValues, type Path, type UseFieldArrayReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RenderArgs } from 'modules/form';
import { Typography } from 'modules/ui';
import { StrictModeDroppable } from 'utils/reactBeautifulDnd-strictModeFix';

// Defines the arguments passed to the render function for form rendering

export type DragAndDropFormArrayProps<T extends FieldValues, V extends FieldValues> = UseFieldArrayReturn<T, any> & {
    name: string;
    className?: string;
    render: (args: RenderArgs<T, V>) => JSX.Element | null;
    getLoadingDischargeIndexes: {
        loadingsIndexes: number[];
        dischargesIndexes: number[];
    }

};

const DragAndDropFormArrayInner = <T extends FieldValues = FieldValues, V extends FieldValues = FieldValues>(
    { name, render, fields, className, move, getLoadingDischargeIndexes, ...fieldArrayProps }: DragAndDropFormArrayProps<T, V>,
    ref: React.ForwardedRef<HTMLUListElement>,
) => {
    const { t } = useTranslation();

    const onDragEnd = (result: any) => {
        const { source, destination } = result;

        // Dropped outside the list
        if (!destination) {
            return;
        }

        const isLoading = getLoadingDischargeIndexes.loadingsIndexes.includes(source.index);

        if(isLoading && destination.index > getLoadingDischargeIndexes.loadingsIndexes[getLoadingDischargeIndexes.loadingsIndexes.length - 1] )
        {
            destination.index = getLoadingDischargeIndexes.loadingsIndexes[getLoadingDischargeIndexes.loadingsIndexes.length - 1]
        }

        if(!isLoading && destination.index === 0 )
        {
            destination.index = getLoadingDischargeIndexes.dischargesIndexes[0]
        }

        // Reorder the items
        move(source.index, destination.index);
        };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="droppable">
                {(provided) => (
                    <ul
                        ref={(node) => {
                            provided.innerRef(node);
                            if (typeof ref === 'function') ref(node);
                            else if (ref) ref.current = node;
                        }}
                        className={className}
                        {...provided.droppableProps}
                    >
                        {!fields.length && <Typography variant="p">{t('form.noItems')}</Typography>}
                        {fields.map((field, index) => (
                            <Draggable key={field.id} draggableId={field.id} index={index}>
                                {(provided) => (
                                    <li
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {render({
                                            index,
                                            getFieldProps: (field) => ({
                                                name: `${name}.${index}.${field}` as Path<T>,
                                            }),
                                            move,
                                            ...fieldArrayProps,
                                        })}
                                    </li>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </ul>
                )}
            </StrictModeDroppable>
        </DragDropContext>
    );
};

export const DragAndDropFormArray = React.forwardRef(DragAndDropFormArrayInner) as <
    T extends FieldValues,
    V extends FieldValues,
>(
    props: DragAndDropFormArrayProps<T, V> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof DragAndDropFormArrayInner>;
