import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNonTypedTranslation } from 'core/translation';
import { useAppDispatch } from 'hooks';
import { useAbortController } from 'hooks/useAbortController';
import useChange from 'hooks/useChange';
import { debounce, isNull } from 'lodash';
import { VehicleTypes } from 'modules/carriers';
import { useLazySearchCarriersQuery } from 'modules/carriers/services';
import { DispatcherSearchParameters } from 'modules/commissions';
import { setDispatcherSearchParameters } from 'modules/commissions/store';
import { useDispatcherSearchMapContext } from 'modules/dispatcherSearch/components/DispatcherSearchMap/context/useDispatcherSearchMapContext';
import { DispatcherSearchResponse } from 'modules/dispatcherSearch/types';
import { CheckboxField, FormGrid, MultiSelectField, SliderField, VisualFormInputsContext } from 'modules/form';
import { getVehicleFeatureOptions } from 'modules/onboarding/utils/vehicleFeature';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';
import { z } from 'zod';

import { CarrierSearchMultiSwitchField } from '../CarrierSearchMultiSwitchField';
import { transformDispatcherSearchToTableItem } from '../CommissionsBasicForm/utils/CommissionsBasicForm.utils';
import { DispatcherSearchTableItem } from '../DispatcherSearchTables/hooks/useDispatcherSearchTable';
import { NumberField } from '../NumberField';

const searchCarrierRequest = () =>
    z.object({
        searchType: z.enum(['dispatcher', 'commission', 'hq']).nullable(),
        directions: z.boolean(),
        vehicleTypes: z.array(z.number().min(1).max(4)).optional(),
        requiredFeatures: z.array(z.number().min(1).max(14)).optional(),
        requiredFeaturesSome: z.array(z.number().min(1).max(14)).optional(),
        minLength: z.number(),
        minWidth: z.number(),
        minWeight: z.number(),
        minHeight: z.number(),
        loadingRadius: z.number(),
        dischargeRadius: z.number(),
        loadingLocation: z.object({ latitude: z.number(), longitude: z.number() }),
        dischargeLocation: z.object({ latitude: z.number(), longitude: z.number() }),
    });

export type SearchCarrierRequest = z.infer<ReturnType<typeof searchCarrierRequest>>;

interface CommissionCarrierFormProps {
    locations?: {
        loading: {
            lat: number;
            lon: number;
            radius: number;
        };
        discharge: {
            lat: number;
            lon: number;
            radius: number;
        };
    };
    defaultValues?: DispatcherSearchParameters;
    hiddenFields?: string[];
    onFetchedData: (carriers: DispatcherSearchTableItem[]) => void;
    fetchDataForMap?: (carriers: DispatcherSearchResponse) => void;
}

export const CommissionCarrierForm: React.FC<CommissionCarrierFormProps> = ({
    locations,
    defaultValues,
    hiddenFields,
    onFetchedData,
    fetchDataForMap,
}) => {
    const { t } = useTranslation();
    const { tnt } = useNonTypedTranslation();
    const dispatch = useAppDispatch();
    const { abort: abortFetching } = useAbortController();

    const [getCarriers, { isFetching }] = useLazySearchCarriersQuery();

    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);
    const [lastRequest, setLastRequest] = useState<SearchCarrierRequest>(); //stores the last request which will be used, when filtration toggle button is toggled off again

    // FORM's INITIALIZATION & STATE

    const formDefaultValues: SearchCarrierRequest = {
        searchType: null,
        directions: false,
        vehicleTypes: [],
        //vehicleTypes: 0,
        requiredFeatures: [],
        requiredFeaturesSome: [],
        minLength: 0,
        minWidth: 0,
        minWeight: 0,
        minHeight: 0,

        loadingRadius: 10,
        dischargeRadius: 10,
        loadingLocation: {
            latitude: locations?.loading.lat || 0,
            longitude: locations?.loading.lon || 0,
        },
        dischargeLocation: {
            latitude: locations?.discharge.lat || 0,
            longitude: locations?.discharge.lon || 0,
        },

        ...defaultValues,
    };

    const methods = useForm<SearchCarrierRequest>({
        defaultValues: formDefaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(searchCarrierRequest()),
    });
    const { handleSubmit, watch, control, getValues, setValue } = methods;

    const {
        minHeight,
        minWidth,
        minLength,
        minWeight,
        requiredFeaturesSome,
        requiredFeatures,
        vehicleTypes,
        searchType,
    } = useWatch({ control });

    const {
        setOnLoadingRadiusMapChange,
        setOnDischargeRadiusMapChange,
        isDispatcherFiltrationToggled,
        setIsMapLoading,
        setIsTableLoading,
    } = useDispatcherSearchMapContext();

    useEffect(() => {
        dispatch(
            setDispatcherSearchParameters({
                minHeight: minHeight || 0,
                minWidth: minWidth || 0,
                minLength: minLength || 0,
                minWeight: minWeight || 0,
                requiredFeaturesSome: requiredFeaturesSome || [],
                requiredFeatures: requiredFeatures || [],
                vehicleTypes: vehicleTypes || [],
                // vehicleTypes: vehicleTypes || 0,
            }),
        );
    }, [
        dispatch,
        minHeight,
        minWidth,
        minLength,
        minWeight,
        JSON.stringify(locations),
        requiredFeaturesSome,
        requiredFeatures,
        vehicleTypes,
    ]);

    // on first render fetch some data
    useEffect(() => {
        onSubmit(getValues());
    }, []);

    // handle submit on every change -- 'cause there is no submit button
    useEffect(() => {
        const subscription = watch(() =>
            handleSubmit(handleDataChangeDelay, (error) => handleErrorsWithNoInputs(error, visualInputsList))(),
        );
        return () => subscription.unsubscribe();
    }, []);

    // debounce with 300ms delay on every change -- so that the server does not burn down
    const handleDataChangeDelay = useCallback(
        debounce((data) => {
            onSubmit(data);
        }, 300),
        [],
    );

    const transformData = (data: SearchCarrierRequest) => {
        if (!locations) return;

        return {
            ...data,
            minHeight: data.minHeight * 100,
            minWidth: data.minWidth * 100,
            minLength: data.minLength * 100,
            searchType: data.searchType,
        } as SearchCarrierRequest;
    };

    const onSubmit = async (data: SearchCarrierRequest) => {
        const formattedRequest = transformData(data);
        if (!formattedRequest) {
            return;
        }

        const { data: carriers, isSuccess } = await getCarriers({
            body: formattedRequest,
            aborter: abortFetching,
        });

        if (isSuccess) {
            onFetchedData(transformDispatcherSearchToTableItem(carriers));
            fetchDataForMap && fetchDataForMap(carriers);
        }
    };

    //map and tables each have their own loading states
    useEffect(() => {
        if (isFetching && !isNull(searchType)) {
            setIsMapLoading(true);
            setIsTableLoading(true);
        } else if (isFetching && isNull(searchType)) {
            setIsTableLoading(true);
        } else if (!isFetching) {
            setIsMapLoading(false);
            setIsTableLoading(false);
        }
    }, [isFetching]);

    // Handle dispatcher filtration toggle
    useChange(() => {
        if (isDispatcherFiltrationToggled) {
            // Store current form values in lastRequest
            const currentFormValues = getValues();
            setLastRequest(currentFormValues);

            // If filtration is toggled off, send request with default form values (except for radiuses)
            Object.keys(formDefaultValues).forEach((key) => {
                if (key !== 'loadingRadius' && key !== 'dischargeRadius') {
                    setValue(key as keyof SearchCarrierRequest, formDefaultValues[key as keyof SearchCarrierRequest]);
                }
            });

            // Submit the form with default values
            handleSubmit(onSubmit)();
        } else if (!isDispatcherFiltrationToggled && lastRequest) {
            // If filtration is toggled off, set the form values to lastRequest and submit the form
            Object.keys(lastRequest).forEach((key) => {
                setValue(key as keyof SearchCarrierRequest, lastRequest[key as keyof SearchCarrierRequest]);
            });

            setIsMapLoading(true);
            handleSubmit(onSubmit)();
        }
        console.log(lastRequest);
    }, [isDispatcherFiltrationToggled]);

    return (
        <FormProvider {...methods}>
            <VisualFormInputsContext.Provider
                value={{
                    visualInputsList,
                    setVisualInputsList,
                }}
            >
                <div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
                    {!hiddenFields?.includes('searchType') && (
                        <div
                            style={{
                                alignSelf: 'flex-end',
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '6px',
                            }}
                        >
                            <div>
                                <CarrierSearchMultiSwitchField
                                    label={t('commissions.form.carrier.searchCarrier.form.searchType.label')}
                                    name="searchType"
                                    isDisabled={isDispatcherFiltrationToggled}
                                    options={[
                                        {
                                            label: t(
                                                'commissions.form.carrier.searchCarrier.form.searchType.dispatcher',
                                            ),
                                            value: 'dispatcher',
                                        },
                                        {
                                            label: t(
                                                'commissions.form.carrier.searchCarrier.form.searchType.commission',
                                            ),
                                            value: 'commission',
                                        },
                                        {
                                            label: t('commissions.form.carrier.searchCarrier.form.searchType.hq'),
                                            value: 'hq',
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    )}
                    <div style={{ flex: 1 }}>
                        <SliderField
                            name="loadingRadius"
                            onChange={(value: number | undefined) => {
                                setValue('loadingRadius', value ? value : 0);
                                setIsMapLoading(true); //also show spinner while moving the slider
                                setOnLoadingRadiusMapChange(value ? value : 0);
                            }}
                            label={t('commissions.form.cargoLoad.radius', { context: 'loading' })}
                            min={1}
                            max={500}
                            isDisabled={isDispatcherFiltrationToggled}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <SliderField
                            name="dischargeRadius"
                            onChange={(value: number | undefined) => {
                                setValue('dischargeRadius', value ? value : 0);
                                setIsMapLoading(true);
                                setOnDischargeRadiusMapChange(value ? value : 0);
                            }}
                            label={t('commissions.form.cargoLoad.radius', { context: 'discharge' })}
                            min={1}
                            max={500}
                            isDisabled={isDispatcherFiltrationToggled}
                        />
                    </div>
                </div>

                {!hiddenFields?.includes('directions') && (
                    <div style={{ padding: '20px 0px', marginTop: '10px' }}>
                        <CheckboxField
                            borderLess
                            name="directions"
                            label={t('commissions.form.carrier.searchCarrier.form.directions')}
                            disabled={isDispatcherFiltrationToggled}
                        />
                    </div>
                )}

                <FormGrid columns={3}>
                    <div
                        style={{
                            pointerEvents: isDispatcherFiltrationToggled ? 'none' : 'auto',
                        }}
                    >
                        <MultiSelectField
                            name="vehicleTypes"
                            label={t('commissions.form.carrier.searchCarrier.form.vehicleType')}
                            placeholder={t('commissions.form.carrier.searchCarrier.form.vehicleType')}
                            options={Object.entries(VehicleTypes).map(([value, vehicleTypes]) => ({
                                label: tnt(`carriers.form.dispatchervehicle.vehicleTypes.${vehicleTypes}`),
                                value: Number(value),
                            }))}
                        />
                    </div>

                    <FormGrid columns={2}>
                        <NumberField
                            name={'minLength'}
                            endIcon="m"
                            label={tnt(`carriers.form.dispatchervehicle.vehicleProperties.carLength`, {
                                order: 'Min.',
                            })}
                            disabled={isDispatcherFiltrationToggled}
                        ></NumberField>

                        <NumberField
                            name={'minWidth'}
                            endIcon="m"
                            label={tnt(`carriers.form.dispatchervehicle.vehicleProperties.carWidth`, {
                                order: 'Min.',
                            })}
                            disabled={isDispatcherFiltrationToggled}
                        ></NumberField>
                    </FormGrid>

                    <FormGrid columns={2}>
                        <NumberField
                            name={'minHeight'}
                            endIcon="m"
                            label={tnt(`carriers.form.dispatchervehicle.vehicleProperties.carHeight`, {
                                order: 'Min.',
                            })}
                            disabled={isDispatcherFiltrationToggled}
                        ></NumberField>

                        <NumberField
                            name={'minWeight'}
                            endIcon="t"
                            label={tnt(`carriers.form.dispatchervehicle.vehicleProperties.totalWeight`, {
                                order: 'Min.',
                            })}
                            disabled={isDispatcherFiltrationToggled}
                        ></NumberField>
                    </FormGrid>
                </FormGrid>

                <div
                    style={{
                        marginTop: '25px',
                        pointerEvents: isDispatcherFiltrationToggled ? 'none' : 'auto',
                    }}
                >
                    <FormGrid columns={2}>
                        <MultiSelectField
                            name="requiredFeaturesSome"
                            label={t('commissions.form.carrier.searchCarrier.form.vehicleFeaturesMustHaveAll')}
                            placeholder={t('commissions.form.carrier.searchCarrier.form.all')}
                            options={getVehicleFeatureOptions(t).filter(({ label }) => label)}
                        />

                        <MultiSelectField
                            name="requiredFeatures"
                            label={t('commissions.form.carrier.searchCarrier.form.vehicleFeaturesMustHaveOne')}
                            placeholder={t('commissions.form.carrier.searchCarrier.form.all')}
                            options={getVehicleFeatureOptions(t).filter(({ label }) => label)}
                        />
                    </FormGrid>
                </div>
            </VisualFormInputsContext.Provider>
        </FormProvider>
    );
};
