import React, { useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAttachmentDetailForm } from 'modules/attachments/contexts/attachmentDetailForm';
import { TAttachmentDetailFormSchema } from 'modules/attachments/types';
import { useListCommissionNumbersQuery } from 'modules/commissions';
import { DebouncedAutocomplete } from 'modules/form/components/DebouncedAutocomplete';

export interface ICommissionSelectFieldProps {}

export const CommissionSelectField: React.FC<ICommissionSelectFieldProps> = () => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const { data: commissionNumbersData, isLoading: isCommissionNumbersLoading } = useListCommissionNumbersQuery(
        {
            search: searchTerm || undefined,
            age: '1y',
        },
        { skip: !searchTerm },
    );
    const { isNew, attachment, initialCommission } = useAttachmentDetailForm();
    const { commission_id, invoice_id } = useWatch<TAttachmentDetailFormSchema>();

    const shouldShowCommissionIdField = useMemo(() => {
        if (isNew && !invoice_id) {
            return true;
        }
        if (commission_id) {
            return true;
        }
        return false;
    }, [isNew, commission_id, invoice_id]);

    const commissionOptions = useMemo(() => {
        const options = (commissionNumbersData || []).map((commission) => ({
            label: commission.number.toString(),
            value: commission.commission_id,
        }));
        if (attachment?.commission?.number && attachment?.commission?.commission_id) {
            options.push({
                label: attachment.commission.number.toString(),
                value: attachment.commission.commission_id,
            });
        }
        if (initialCommission) {
            options.push({
                label: initialCommission.number.toString(),
                value: initialCommission.commission_id,
            });
        }
        return options;
    }, [commissionNumbersData, attachment?.commission, initialCommission]);

    const handleTyping = (value: string | null) => {
        setSearchTerm(value);
    };

    if (!shouldShowCommissionIdField) {
        return null;
    }
    return (
        <DebouncedAutocomplete
            name="commission_id"
            isDisabled={isCommissionNumbersLoading || !!initialCommission}
            label={t('attachments.form.fields.commissionId.title')}
            valueProperty="value"
            labelProperty="label"
            options={commissionOptions}
            onTyping={handleTyping}
            debounceInterval={300}
        />
    );
};
