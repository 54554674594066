import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { IconEmail } from 'assets/icons';
import { Language } from 'core/translation/i18next/i18next';
import { AnimatePresence } from 'framer-motion';
import { useGetAttachmentQuery, useMailAttachmentFileMutation } from 'modules/attachments/services';
import { EAttachmentType } from 'modules/attachments/types';
import { commissionLanguages } from 'modules/commissions';
import { Button, Tooltip } from 'modules/ui';
import { IMailModalProps, MailModal } from 'modules/ui/components/MailModal';

export interface IMailAttachmentFileButtonProps {
    attachmentId: number;
}

export const MailAttachmentFileButton: React.FC<IMailAttachmentFileButtonProps> = ({ attachmentId }) => {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [mailAttachment, { isLoading: isSending }] = useMailAttachmentFileMutation();

    const {
        isLoading,
        data: attachmentData,
        isError,
    } = useGetAttachmentQuery({
        attachmentId,
        includeInvoiceEmailData: true,
    });

    const languageCode: Language | null = useMemo(() => {
        if (!attachmentData) return null;
        if (!attachmentData?.invoiceLanguage) return commissionLanguages.čeština as string as Language;
        return commissionLanguages[
            attachmentData.invoiceLanguage as keyof typeof commissionLanguages
        ] as string as Language;
    }, [attachmentData]);

    const isButtonDisabled = useMemo(() => {
        if (isError || isLoading) return true;
        return attachmentData?.type === EAttachmentType.DELIVERY_NOTE;
    }, [attachmentData, isError, isLoading]);

    const handleMailAttachment: IMailModalProps['onSubmit'] = async (data) => {
        if (isSending || !languageCode) return;
        try {
            await mailAttachment({
                attachmentId,
                email: data.recipients[0],
                message: data.message,
                language: languageCode,
            }).unwrap();
            toast.success(t('attachments.mail.success'));
            setOpenModal(false);
        } catch (error: any) {
            if (error?.data?.message) {
                return toast.error(error.data.message);
            }
            toast.error(t('attachments.mail.error'));
        }
    };

    return (
        <>
            <AnimatePresence>
                {openModal && (
                    <MailModal
                        initialValues={{
                            attachments: [...(attachmentData ? [{ value: attachmentData?.name }] : [])],
                            message: t('attachments.mail.modal.message', {
                                lng: languageCode || undefined,
                            }),
                            recipients: [
                                ...(attachmentData?.invoiceCustomerEmail
                                    ? [
                                          {
                                              value: attachmentData.invoiceCustomerEmail,
                                              label: attachmentData.invoiceCustomerEmail,
                                          },
                                      ]
                                    : []),
                            ],
                        }}
                        onSubmit={handleMailAttachment}
                        isSubmitting={isSending}
                        title={t('attachments.mail.modal.title')}
                        onOpenChange={setOpenModal}
                    />
                )}
            </AnimatePresence>
            <Tooltip content={t('attachments.mail.modal.hasToBeInvoice')} disabled={!isButtonDisabled} side="bottom">
                <div>
                    <Button disabled={isButtonDisabled} variant="secondary" onClick={() => setOpenModal(true)}>
                        <IconEmail iconBackgroundColor="#1E2124" />
                        {t('common.subheader.email')}
                    </Button>
                </div>
            </Tooltip>
        </>
    );
};
