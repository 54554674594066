import React, { useEffect } from 'react';
import {
    ATTACHMENTS_TABLE_COLUMNS,
    AttachmentsTableContextProvider,
    useAttachmentsTable,
} from 'modules/attachments/contexts/attachmentsTable';
import { Table } from 'modules/table';


export interface IAttachmentsTableProps {
    commissionId?: number;
    invoiceId?: number;
    columnsVisibility?: Partial<Record<(typeof ATTACHMENTS_TABLE_COLUMNS)[number], boolean>>;
}

const AttachmentsTableInner: React.FC<IAttachmentsTableProps> = ({ commissionId, invoiceId, columnsVisibility }) => {
    const {
        setCommissionId,
        setInvoiceId,
        tableState,
        columns,
        filteredAttachments,
        isLoadingAttachments,
        setColumnsVisibility,
    } = useAttachmentsTable();

    useEffect(() => {
        commissionId && setCommissionId(commissionId);
    }, [commissionId]);

    useEffect(() => {
        invoiceId && setInvoiceId(invoiceId);
    }, [invoiceId]);

    useEffect(() => {
        if (columnsVisibility) {
            setColumnsVisibility(columnsVisibility);
        }
    }, [columnsVisibility]);

    return (
        <Table
            {...{ columns }}
            data={filteredAttachments}
            totalCount={filteredAttachments.length}
            isLoading={isLoadingAttachments}
            bordered
            hideBorders
            hidePaginationPanel
            hideResizing
            {...tableState.tableProps}
        />
    );
};

export const AttachmentsTable: React.FC<IAttachmentsTableProps> = (props) => {
    return (
        <AttachmentsTableContextProvider>
            <AttachmentsTableInner {...props} />
        </AttachmentsTableContextProvider>
    );
};
