import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'core/application';
import useChange from 'hooks/useChange';
import { createFullName } from 'modules/carriers/utils';
import { useSelectedDispatchers } from 'modules/commissions/hooks/useSelectedDispatchers';
import { tableNames } from 'modules/common';
import { useDispatcherSearchMapContext } from 'modules/dispatcherSearch/components/DispatcherSearchMap/context/useDispatcherSearchMapContext';
import { EnquiryDispatcherSchema } from 'modules/offers/types';
import { TableProps } from 'modules/table';
import { Spinner, Typography } from 'modules/ui';

import { FormSection } from '../FormSection';
import { SearchedDispatchersTable } from './components/SearchedDispatchersTable';
import { SelectedDispatchersTable } from './components/SelectedDispatchersTable';
import { DispatcherSearchTableItem } from './hooks/useDispatcherSearchTable';

import styles from './DispatcherSearchTables.module.scss';

export type TDispatcherSearchTablesProps = {
    data: DispatcherSearchTableItem[];
    disabledIds?: number[];
    defaultSelectedDispatchers?: EnquiryDispatcherSchema[];
    dispatcherSelectedFromMap?: number;
    setDispatcherSelectedFromMap?: (id: number | undefined) => void;
    setShowInquiryModal?: (show: boolean) => void;
} & Pick<TableProps, 'overflow' | 'fixedHeight'>;

export const DispatcherSearchTables: React.FC<TDispatcherSearchTablesProps> = ({
    data,
    disabledIds,
    defaultSelectedDispatchers = [],
    dispatcherSelectedFromMap,
    setDispatcherSelectedFromMap,
    setShowInquiryModal,
    fixedHeight,
    overflow,
}) => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.customers'));

    const { isTableLoading } = useDispatcherSearchMapContext();

    const formattedSelectedDispatchers = defaultSelectedDispatchers.map((item) => ({
        dispatcher_id: item.dispatcher_id,
        country: item.dispatcher.carrier.country,
        company: item.dispatcher.carrier.company,
        postalCode: item.dispatcher.carrier.postalCode,
        fullName: createFullName(item.dispatcher.firstName, item.dispatcher.lastName),
        lastRequestTimeSent: item.dispatcher.lastRequestTimeSent || 0,
        routes: item.dispatcher_id,

        //BE not returning these values at the moment
        lastCommissionDate: null,
        lastCommissionId: null,
        lastCommissionNumber: null,
        popUpData: null,
    }));

    const [selectedDispatchersLocal, setSelectedDispatchersLocal] =
        useState<DispatcherSearchTableItem[]>(formattedSelectedDispatchers);
    const { setSelectedDispatchers } = useSelectedDispatchers();

    useChange(() => {
        setSelectedDispatchers(selectedDispatchersLocal.map(({ dispatcher_id }) => ({ dispatcherId: dispatcher_id })));
    }, [selectedDispatchersLocal]);

    //handle selecting from map
    useChange(() => {
        if (dispatcherSelectedFromMap !== null) {
            const dispatcherToAdd = data.find((dispatcher) => dispatcher.dispatcher_id === dispatcherSelectedFromMap);

            if (
                dispatcherToAdd &&
                !selectedDispatchersLocal.some((d) => d.dispatcher_id === dispatcherToAdd.dispatcher_id)
            ) {
                setSelectedDispatchersLocal((prev) => [...prev, dispatcherToAdd]);
            }
            setDispatcherSelectedFromMap ? setDispatcherSelectedFromMap(undefined) : null; //reset dispatcherSelectedFromMap state
        }
    }, [dispatcherSelectedFromMap]);

    return (
        <>
            <div style={{ marginTop: '15px' }}>
                <FormSection
                    style={{ marginTop: '15px' }}
                    headerStartSlot={
                        <div className={styles.selectCarrierTitle}>
                            <Typography variant="headline-h4">{t('offers.modalFindCarriers.selectCarrier')}</Typography>
                        </div>
                    }
                    headerEndSlot={isTableLoading ? <Spinner /> : <></>}
                >
                    <SearchedDispatchersTable
                        data={data}
                        selectedDispatchers={selectedDispatchersLocal}
                        setSelectedDispatchers={setSelectedDispatchersLocal}
                        tableName={tableNames.commissions.searched}
                        fixedHeight={fixedHeight}
                        overflow={overflow}
                        storeColumnWidth={false}
                    />
                </FormSection>
            </div>

            <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                <FormSection
                    headerStartSlot={
                        <div className={styles.selectCarrierTitle}>
                            <Typography variant="headline-h4">
                                {t('offers.modalFindCarriers.selectedCarriers')}
                            </Typography>
                        </div>
                    }
                    headerEndSlot={
                        <div className={styles.titleOfferBtn}>
                            <Typography
                                onClick={() => setShowInquiryModal && setShowInquiryModal(true)}
                                variant="headline-h4"
                            >
                                {t('offers.modalFindCarriers.createOffer')}
                            </Typography>
                        </div>
                    }
                >
                    <SelectedDispatchersTable
                        selectedDispatchers={selectedDispatchersLocal}
                        setSelectedDispatchers={setSelectedDispatchersLocal}
                        disabledIds={disabledIds}
                        tableName={tableNames.commissions.selected}
                        fixedHeight={fixedHeight}
                        overflow={overflow}
                        storeColumnWidth={false}
                    />
                </FormSection>
            </div>
        </>
    );
};
