import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { MapProvider } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TrashIcon } from 'assets/icons';
import { config } from 'config';
import { useDocumentTitle } from 'core/application';
import { selectAuth } from 'core/auth/services/selectors';
import { useAppSelector } from 'hooks';
import {
    carrierApi,
    useGetOneCarrierQuery,
    usePostOnboardingEmailMutation,
    usePutUpdatedCarrierMutation,
} from 'modules/carriers';
import { createFullName } from 'modules/carriers/utils';
import { PageContent, Subheader } from 'modules/layout';
import { dispatchersApi, useGetOneDispatcherQuery, usePutUpdatedDispatcherMutation } from 'modules/onboarding/services';
import { OnboardingSchema } from 'modules/onboarding/types';
import { transformOnboardingData } from 'modules/onboarding/utils';
import { BackButton, Button, Input, Modal, Spinner, Typography } from 'modules/ui';
import { universalLanguageFinder } from 'utils';

import { DispatcherBasicForm } from '../DispatcherBasicForm';
import { OnboardingState } from '../OnboardingState';

import styles from './DispatcherDetailPage.module.scss';

export const DispatcherDetailPage = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { user } = useAppSelector(selectAuth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    useDocumentTitle(t('nav.dispatchers'));

    const [showRemoveConfirmationPopup, setShowRemoveConfirmationPopup] = useState(false);
    const [showSendOnboardingModal, setShowSendOnboardingModal] = useState(false);
    const [onboardingEmail, setOnboardingEmail] = useState('');

    const { data: dispatcherData } = useGetOneDispatcherQuery({ id: Number(id) }, { skip: !id });
    const { data: carrierData } = useGetOneCarrierQuery(
        { id: String(dispatcherData?.carrier_id) },
        { skip: !dispatcherData?.carrier_id },
    );
    const [putUpdatedDispatcher, { isLoading: isSubmittingFormLoading }] = usePutUpdatedDispatcherMutation();
    const [putUpdatedCarrier, { isLoading: isSubmittingDeleting }] = usePutUpdatedCarrierMutation();
    const [postOnboardingEmail, { isLoading: isOnboardingEmailFetching }] = usePostOnboardingEmailMutation();

    if (!dispatcherData) return <Spinner fullScreen />;

    const prepareData = async (data: OnboardingSchema, oldData?: OnboardingSchema) => {
        if (!dispatcherData) return;
        const formatted = transformOnboardingData({ currentData: data, oldData });
        try {
            await putUpdatedDispatcher({ data: formatted, id: dispatcherData.dispatcher_id }).unwrap();
            dispatch(carrierApi.util.invalidateTags([{ type: 'CarrierItem', id: dispatcherData.carrier_id }]));
            toast.success(t('onboarding.updated'));
            const pageToReturn = searchParams.get('pageToReturn');
            if (pageToReturn) {
                const [pathname, search] = pageToReturn.split('?');
                navigate({
                    pathname: pathname,
                    search: search
                });
                return;
            }
            navigate(`${config.routes.carriers.list}/${dispatcherData.carrier_id}`);
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    const onRemoveItem = async () => {
        if (!carrierData) return;
        try {
            await putUpdatedCarrier({
                data: {
                    company: carrierData?.company,
                    dispatchers: {
                        toDelete: [{ dispatcher_id: dispatcherData.dispatcher_id }],
                    },
                },
                id: dispatcherData.carrier_id,
            }).unwrap();

            navigate(`${config.routes.carriers.list}/${dispatcherData.carrier_id}`);
            toast.success(t('onboarding.removed'));
        } catch (error) {
            toast.error(t('form.saveError'));
        } finally {
            setShowRemoveConfirmationPopup(false);
        }
    };

    const sendOnboardingEmail = async () => {
        if (!onboardingEmail || !dispatcherData?.carrier_id || !user) return;

        const sendEmailRequestBody = {
            to: [
                {
                    email: onboardingEmail,
                    lang: universalLanguageFinder(String(dispatcherData.language_id))?.lang || 'cs',
                    dispatcher_id: dispatcherData.dispatcher_id,
                },
            ],
            carrier_id: dispatcherData?.carrier_id,
            dispatcher: { name: user.name, surname: user.surname, phone: user.mobilePhone, email: user.email },
        };
        const postOnboardingEmailResponse = await postOnboardingEmail(sendEmailRequestBody);

        if ('error' in postOnboardingEmailResponse) return toast.error(t('carriers.modalOnboarding.error'));
        toast.success(t('carriers.modalOnboarding.success'));

        if (!('error' in postOnboardingEmailResponse))
            dispatch(
                dispatchersApi.util.invalidateTags([
                    { type: 'DispatcherItem', id: sendEmailRequestBody.to[0].dispatcher_id },
                ]),
            );

        setOnboardingEmail('');
        setShowSendOnboardingModal(false);
    };

    return (
        <PageContent
            subheader={
                <Subheader
                    startSlot={<BackButton />}
                    titleSlot={<OnboardingState size="big" state={dispatcherData.onboardingState} />}
                    endSlot={
                        <div className={styles.actionButtons}>
                            <Button
                                onClick={() => setShowRemoveConfirmationPopup(true)}
                                isLoading={isSubmittingDeleting}
                                disabled={isSubmittingDeleting}
                                className={styles.deleteBtn}
                                variant="primary"
                                danger
                            >
                                <TrashIcon />
                            </Button>
                            <Button
                                disabled={isOnboardingEmailFetching}
                                isLoading={isOnboardingEmailFetching}
                                onClick={() => {
                                    setOnboardingEmail(dispatcherData.email);
                                    setShowSendOnboardingModal(true);
                                }}
                                variant="secondary"
                            >
                                {t('onboarding.sendOnboardingEmail')}
                            </Button>
                            <Button
                                isLoading={isSubmittingFormLoading}
                                disabled={isSubmittingFormLoading}
                                form="dispatcher-form"
                                type="submit"
                            >
                                {t('onboarding.saveDispatcher')}
                            </Button>
                        </div>
                    }
                    title={createFullName(dispatcherData.firstName, dispatcherData.lastName)}
                />
            }
        >
            <MapProvider>
                <DispatcherBasicForm viewSide="detail" fetchedData={dispatcherData} prepareData={prepareData} />
            </MapProvider>
            {showRemoveConfirmationPopup && (
                <Modal
                    onClick={() => setShowRemoveConfirmationPopup(false)}
                    label={t('onboarding.modalRemove.label')}
                    cancelComponent={
                        <Button
                            onClick={() => setShowRemoveConfirmationPopup(false)}
                            className={styles.btn}
                            type="button"
                            variant="secondary"
                            isLoading={isSubmittingDeleting}
                            disabled={isSubmittingDeleting}
                        >
                            {t('onboarding.modalRemove.cancel')}
                        </Button>
                    }
                    approveComponent={
                        <Button className={styles.btn} type="button" danger variant="primary" onClick={onRemoveItem}>
                            {t('onboarding.modalRemove.approve')}
                        </Button>
                    }
                />
            )}
            {showSendOnboardingModal && dispatcherData.carrier_id && (
                <Modal
                    onClick={() => {
                        setShowSendOnboardingModal(false);
                        setOnboardingEmail('');
                    }}
                    label={t('carriers.modalOnboarding.label')}
                    cancelComponent={
                        <Button
                            className={styles.btn}
                            type="button"
                            variant="secondary"
                            onClick={() => {
                                setShowSendOnboardingModal(false);
                                setOnboardingEmail('');
                            }}
                        >
                            {t('carriers.modalOnboarding.cancel')}
                        </Button>
                    }
                    approveComponent={
                        <Button className={styles.btn} type="button" variant="primary" onClick={sendOnboardingEmail}>
                            {t('carriers.modalOnboarding.approve')}
                        </Button>
                    }
                >
                    <>
                        <Typography className={styles.modalDescription} variant="p">
                            {t('carriers.modalOnboarding.dispatcherEmail')}
                        </Typography>
                        <Input value={onboardingEmail} onChange={(e) => setOnboardingEmail(e.target.value)} />
                    </>
                </Modal>
            )}
        </PageContent>
    );
};
