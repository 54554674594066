import React from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import { useWindowSize } from '@uidotdev/usehooks';
import { MailAttachmentFileButton } from 'modules/attachments/components/MailAttachmentFileButton';
import { PrintAttachmentFileButton } from 'modules/attachments/components/PrintAttachmentFileButton';
import { useGetAttachmentFileQuery } from 'modules/attachments/services';
import { Spinner, Typography } from 'modules/ui';

import styles from './CarrierInvoiceModal.module.scss';

export interface ICarrierInvoiceModalProps {
    attachmentId: number;
    onClose: () => void;
}

export const CarrierInvoiceModal: React.FC<ICarrierInvoiceModalProps> = ({ attachmentId, onClose }) => {
    const { t } = useTranslation();
    const { data, isError } = useGetAttachmentFileQuery({ attachmentId });
    const { height } = useWindowSize();

    return (
        <div className={styles.modal} onClick={onClose}>
            <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                <div className={styles.documentContainer}>
                    {isError && (
                        <div className={styles.noDocument}>
                            <Typography variant="h5">{t('commissions.form.noCarrierInvoice')}</Typography>
                        </div>
                    )}
                    {!isError && (
                        <Document
                            file={data?.data}
                            noData={() => (
                                <div className={styles.loading}>
                                    <Spinner fullScreen />
                                </div>
                            )}
                        >
                            <Page
                                pageNumber={1}
                                devicePixelRatio={2}
                                height={(height || 0) * 0.85}
                                renderAnnotationLayer={false}
                            />
                        </Document>
                    )}
                </div>
                {!isError && data && (
                    <div className={styles.buttonContainer}>
                        <PrintAttachmentFileButton attachmentId={attachmentId} />
                        <MailAttachmentFileButton attachmentId={attachmentId} />
                    </div>
                )}
            </div>
        </div>
    );
};
