import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { config } from 'config';
import { AttachmentDetailForm, IAttachmentDetailFormProps } from 'modules/attachments/components/AttachmentDetailForm';
import { useAttachmentDetailForm } from 'modules/attachments/contexts/attachmentDetailForm';
import { useCreateAttachmentMutation } from 'modules/attachments/services';

export interface ICreateAttachmentFormWrapperProps {
    onIsSubmittingChange?: (isCreating: boolean) => void;
}

export const CreateAttachmentFormWrapper: React.FC<ICreateAttachmentFormWrapperProps> = ({ onIsSubmittingChange }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { initialCommission, initialInvoice } = useAttachmentDetailForm();
    const [createAttachment, { isLoading }] = useCreateAttachmentMutation();

    const handleSubmit: IAttachmentDetailFormProps['onSubmit'] = async ({
        file,
        name,
        type,
        invoice_id,
        commission_id,
    }) => {
        if (!file || isLoading) return;
        try {
            const { attachment_id } = await createAttachment({
                file,
                name,
                type,
                invoiceId: invoice_id || undefined,
                commissionId: commission_id || undefined,
            }).unwrap();
            toast.success(t('attachments.create.success'));
            if (initialCommission) {
                navigate(config.routes.commissions.detail.replace(':id', initialCommission.commission_id.toString()));
                return;
            }
            if (initialInvoice) {
                navigate(config.routes.invoicing.detail.replace(':id', initialInvoice.invoice_id.toString()));
                return;
            }
            navigate(config.routes.attachments.detail.replace(':id', attachment_id.toString()));
        } catch (error: any) {
            if (error?.data?.message) {
                return toast.error(error.data.message);
            }
            toast.error(t('attachments.create.error'));
        }
    };

    useEffect(() => {
        onIsSubmittingChange?.(isLoading);
    }, [isLoading]);

    return <AttachmentDetailForm onSubmit={handleSubmit} />;
};
