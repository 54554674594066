import type { IconProps } from './types';

export const ConfigurationIcon: React.FC<IconProps> = (props) => {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_309_2)">
                <path
                    d="M7.5 10.875C9.36396 10.875 10.875 9.36396 10.875 7.5C10.875 5.63604 9.36396 4.125 7.5 4.125C5.63604 4.125 4.125 5.63604 4.125 7.5C4.125 9.36396 5.63604 10.875 7.5 10.875Z"
                    stroke="#1E2124"
                    strokeWidth={1.5}
                />
                <path
                    d="M16.5 13.125C14.636 13.125 13.125 14.636 13.125 16.5C13.125 18.364 14.636 19.875 16.5 19.875C18.364 19.875 19.875 18.364 19.875 16.5C19.875 14.636 18.364 13.125 16.5 13.125Z"
                    stroke="#1E2124"
                    strokeWidth={1.5}
                />
                <path d="M12 7.5H19.5" stroke="#1E2124" strokeWidth={1.5} strokeLinecap="square" />
                <path d="M12 16.5H4.5" stroke="#1E2124" strokeWidth={1.5} strokeLinecap="square" />
            </g>
            <defs>
                <clipPath id="clip0_309_2">
                    <rect width={24} height={24} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
