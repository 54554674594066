import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetQueryParams } from 'hooks/useGetQueryParams';
import {
    AttachmentDetailFormContextProvider,
    useAttachmentDetailForm,
} from 'modules/attachments/contexts/attachmentDetailForm';
import { initialCommissionSchema, initialInvoiceSchema } from 'modules/attachments/types';
import { PageContent, Subheader } from 'modules/layout';
import { BackButton, Button } from 'modules/ui';

import { CreateAttachmentFormWrapper } from '../CreateAttachmentFormWrapper';

import styles from './CreateAttachmentPage.module.scss';

export interface ICreateAttachmentPageProps {}

const CreateAttachmentPageInner: React.FC<ICreateAttachmentPageProps> = () => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { triggerSubmit, attachment, isValid, formMethods, setInitialCommission, setInitialInvoice } =
        useAttachmentDetailForm();

    const { commission, invoice } = useGetQueryParams<{ commission: string; invoice: string }>();

    useEffect(() => {
        if (!commission) return;
        try {
            const parsedCommission = initialCommissionSchema().parse(JSON.parse(commission));
            setInitialCommission(parsedCommission);
            formMethods.setValue('commission_id', parsedCommission.commission_id);
        } catch (error) {
            console.error(error);
        }
    }, [commission]);

    useEffect(() => {
        if (!invoice) return;
        try {
            const parsedInvoice = initialInvoiceSchema().parse(JSON.parse(invoice));
            setInitialInvoice(parsedInvoice);
            formMethods.setValue('invoice_id', parsedInvoice.invoice_id);
        } catch (error) {
            console.error(error);
        }
    }, [invoice]);

    return (
        <PageContent
            fullWidthHeader
            fullWidth
            subheader={
                <Subheader
                    startSlot={<BackButton />}
                    endSlot={
                        <div className={styles.actionButtonsWrapper}>
                            <Button disabled={!isValid} type="button" onClick={triggerSubmit} isLoading={isSubmitting}>
                                {t('form.save')}
                            </Button>
                        </div>
                    }
                    title={attachment?.name || '***'}
                />
            }
        >
            <CreateAttachmentFormWrapper onIsSubmittingChange={(state) => setIsSubmitting(state)} />
        </PageContent>
    );
};
export const CreateAttachmentPage: React.FC<ICreateAttachmentPageProps> = () => {
    return (
        <AttachmentDetailFormContextProvider>
            <CreateAttachmentPageInner />
        </AttachmentDetailFormContextProvider>
    );
};
