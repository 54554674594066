import React from 'react';
import { useAttachmentDetailForm } from 'modules/attachments/contexts/attachmentDetailForm';
import { TAttachmentDetailFormSchema } from 'modules/attachments/types';
import { FormGrid } from 'modules/form';

import { ConfigurationSection } from './components/ConfigurationSection';
import { FileSection } from './components/FileSection';

import styles from './AttachmentDetailForm.module.scss';

export interface IAttachmentDetailFormProps {
    onSubmit?: (data: TAttachmentDetailFormSchema) => void;
}

export const AttachmentDetailForm: React.FC<IAttachmentDetailFormProps> = ({ onSubmit }) => {
    const { formMethods, submitButtonRef } = useAttachmentDetailForm();

    return (
        <form onSubmit={onSubmit ? formMethods.handleSubmit(onSubmit) : undefined} className={styles.form}>
            <FormGrid columns={2} style={{ flex: 1 }}>
                <ConfigurationSection />
                <FileSection />
            </FormGrid>
            <button ref={submitButtonRef} type="submit" />
        </form>
    );
};
