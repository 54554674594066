import React from 'react';
import { useTranslation } from 'react-i18next';
import { PauseCircleIconOff } from 'assets/icons/pauseCircleIconOff';
import { PauseCircleIconOn } from 'assets/icons/pauseCircleIconOn';

import styles from './DispatcherFiltrationToggleBtn.module.scss';

type DispatcherFiltrationToggleBtnProps = {
    onDispatcherFiltrationToggled: (isToggled: boolean) => void;
    isDispatcherFiltrationToggled: boolean;
};

export const DispatcherFiltrationToggleBtn: React.FC<DispatcherFiltrationToggleBtnProps> = ({
    onDispatcherFiltrationToggled,
    isDispatcherFiltrationToggled,
}) => {
    const { t } = useTranslation();

    const handleClick = () => {
        onDispatcherFiltrationToggled(!isDispatcherFiltrationToggled);
    };

    return (
        <div className="btnWrapper">
            <button
                className={styles.filtrationBtnOn}
                type="button"
                role="radio"
                onClick={handleClick}
                data-state={isDispatcherFiltrationToggled ? 'on' : 'off'}
            >
                <div>{isDispatcherFiltrationToggled ? <PauseCircleIconOn /> : <PauseCircleIconOff />}</div>
                {isDispatcherFiltrationToggled
                    ? t('commissions.form.carrier.actionButtons.filterNotActive')
                    : t('commissions.form.carrier.actionButtons.pauseFilter')}
            </button>
        </div>
    );
};
