import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TrashIcon } from 'assets/icons';
import { config } from 'config';
import { useDeleteAttachmentMutation } from 'modules/attachments/services';
import { Button, ButtonProps, Modal } from 'modules/ui';
export interface IDeleteAttachmentButtonProps extends ButtonProps {
    attachmentId: number;
    redirectOnSuccess?: boolean;
}

export const DeleteAttachmentButton: React.FC<IDeleteAttachmentButtonProps> = ({
    attachmentId,
    redirectOnSuccess = true,
    ...props
}) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [deleteAttachment, { isLoading }] = useDeleteAttachmentMutation();

    const handleDelete = async () => {
        if (isLoading) return;
        try {
            await deleteAttachment({ attachmentId }).unwrap();
            toast.success(t('attachments.delete.success'));
            setShowModal(false);
            redirectOnSuccess && navigate(config.routes.commissions.list);
        } catch (error: any) {
            if (error?.data?.message) {
                return toast.error(error.data.message);
            }
            toast.error(t('attachments.delete.error'));
        }
    };

    return (
        <>
            {showModal && (
                <Modal
                    label={t('attachments.delete.title')}
                    description={t('attachments.delete.confirmation')}
                    onClick={() => setShowModal(false)}
                    approveComponent={
                        <Button type="button" variant="primary" isLoading={isLoading} danger onClick={handleDelete}>
                            {t('common.subheader.delete')}
                        </Button>
                    }
                    cancelComponent={
                        <Button type="button" variant="secondary" onClick={() => setShowModal(false)}>
                            {t('form.cancel')}
                        </Button>
                    }
                />
            )}
            <Button
                type="button"
                variant="primary"
                Icon={<TrashIcon />}
                danger
                onClick={() => setShowModal(true)}
                {...props}
            />
        </>
    );
};
