import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useAttachmentDetailForm } from 'modules/attachments/contexts/attachmentDetailForm';
import { useGetAttachmentFileQuery } from 'modules/attachments/services';
import { TAttachmentDetailFormSchema } from 'modules/attachments/types';
import { InvoiceDocument } from 'modules/invoicing/components/InvoiceDocument';

import { FileUploadField } from '../FileUploadField';

export interface IFileSectionProps {}

export const FileSection: React.FC<IFileSectionProps> = () => {
    const { attachment, isNew } = useAttachmentDetailForm();
    const { file } = useWatch<TAttachmentDetailFormSchema>();
    const { data: fileData, isLoading } = useGetAttachmentFileQuery(
        { attachmentId: Number(attachment?.attachment_id) },
        { skip: !attachment?.attachment_id },
    );

    const uploadedFileBlobUrl = useMemo(() => {
        if (file) {
            return URL.createObjectURL(file);
        }
        return null;
    }, [file]);

    if (isNew && !file) return <FileUploadField />;
    if (isNew && uploadedFileBlobUrl) {
        return (
            <InvoiceDocument pdfWrapperStyles={'commissionAttachments'} blobUrl={uploadedFileBlobUrl} loading={false} />
        );
    }
    return (
        <InvoiceDocument
            pdfWrapperStyles={'commissionAttachments'}
            blobUrl={fileData?.data || null}
            loading={isLoading}
        />
    );
};
