import React from 'react';
import { useTranslation } from 'react-i18next';
import { timestamp } from 'utils/formatTimestamp';

import { PopUpData } from '../../hooks/useDispatcherSearchTable';

import styles from './HoverPopUp.module.scss';

export type HoverPopUpProps = {
    popUpData: PopUpData | null;
};

export const HoverPopUp: React.FC<HoverPopUpProps> = ({ popUpData }) => {
    const { t } = useTranslation();

    const { priceCarrier, customer, loading, discharge } = popUpData || {};

    return (
        <>
            <div className={styles.container}>
                <div className={styles.customerLabel}>{customer}</div>

                <div className={styles.flexWrapper}>
                    <div className={styles.flexItemLoadingDischarge}>
                        <div className={styles.label}>
                            {t('commissions.form.carrier.searchCarrier.table.popUp.loading')}
                        </div>
                        <div>{timestamp(loading?.date ? loading.date : 0)?.format('DD. MM. YYYY')}</div>
                        <div>{loading?.city}</div>
                        <div>{loading?.postalCode}</div>
                    </div>

                    <div className={styles.flexItemLoadingDischarge}>
                        <div className={styles.label}>
                            {t('commissions.form.carrier.searchCarrier.table.popUp.discharge')}
                        </div>
                        <div> {timestamp(discharge?.date ? discharge.date : 0)?.format('DD. MM. YYYY')}</div>
                        <div>{discharge?.city}</div>
                        <div>{discharge?.postalCode}</div>
                    </div>
                </div>
                <div className={styles.division}></div>

                <div className={styles.flexWrapper}>
                    <div className={styles.flexItemLoadingDischarge}>
                        <div className={styles.label}>
                            <div className={styles.label}>
                                {t('commissions.form.carrier.searchCarrier.table.popUp.price')}
                            </div>
                        </div>
                        {priceCarrier ? (
                            <div className={styles.price}>
                                {priceCarrier} {t('common.currency.CZK')}
                            </div>
                        ) : (
                            <div>---</div>
                        )}
                    </div>
                    <div className={styles.flexItemLoadingDischarge}></div>
                </div>
            </div>
        </>
    );
};
