import React from 'react';

import styles from './LoadingDischargeFormRow.module.scss';

export const LoadingDischargeFormRow = ({
    actualIndex,
    rowEndSlot,
    children,
}: {
    actualIndex: number;
    rowEndSlot?: React.ReactNode;
    children: React.ReactNode;
}) => {
    return (
        <div className={styles.row}>
            <div className={styles.dragIconWrapper} >
            <svg xmlns="http://www.w3.org/2000/svg" width="2O" height="20" fill="currentColor"  viewBox="0 0 16 16">
            <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
            </svg>
            </div>
            <div className={styles.indexWrapper}> {actualIndex} </div>

            {children}

            <div className={styles.removeWrapper}> {rowEndSlot} </div>
        </div>
    );
};
