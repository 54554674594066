import React, { createContext, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { TAttachmentUploadTokenPayload } from 'core/auth/types';
import jwtDecode from 'jwt-decode';

import { useUploadAttachmentsMutation } from '../services';
import { attachmentUploadFormSchema, TAttachmentUploadFormSchema } from '../types';

export interface IAttachmentUploadContext {
    isValid: boolean;
    isUploading: boolean;
    isUploadTokenValid: boolean | null;
    uploadToken: string | null;
    uploadTokenPayload: TAttachmentUploadTokenPayload | null;
    formMethods: ReturnType<typeof useForm<TAttachmentUploadFormSchema>>;

    setUploadToken: (token: string) => void;
    uploadAttachments: (data: TAttachmentUploadFormSchema) => Promise<void>;
}

const AttachmentUploadContext = createContext<IAttachmentUploadContext>({
    formMethods: undefined!,
    isValid: false,
    uploadToken: null,
    isUploading: false,
    uploadTokenPayload: null,
    isUploadTokenValid: null,

    setUploadToken: () => undefined,
    uploadAttachments: async () => {
        Promise.resolve();
    },
});
export interface IAttachmentUploadContextProviderProps {
    children: React.ReactNode;
}
export const AttachmentUploadContextProvider: React.FC<IAttachmentUploadContextProviderProps> = ({ children }) => {
    const { t } = useTranslation();
    const [uploadAttachments, { isLoading }] = useUploadAttachmentsMutation();

    const [uploadToken, setUploadToken] = useState<string | null>(null);
    const [uploadTokenPayload, setUploadTokenPayload] = useState<TAttachmentUploadTokenPayload | null>(null);
    const [isUploadTokenValid, setIsUploadTokenValid] = useState<boolean | null>(null);

    const formMethods = useForm<TAttachmentUploadFormSchema>({
        resolver: zodResolver(attachmentUploadFormSchema()),
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            deliveryNotes: [],
            invoice: [],
        },
    });
    const { isValid } = useFormState(formMethods);

    const handleSetUploadToken = (token: string) => {
        try {
            const payload = jwtDecode<TAttachmentUploadTokenPayload>(token);
            setUploadToken(token);
            setUploadTokenPayload(payload);
            setIsUploadTokenValid(true);
        } catch (error) {
            setIsUploadTokenValid(false);
        }
    };

    const handleUploadAttachments = async (data: TAttachmentUploadFormSchema) => {
        if (isLoading) return;
        if (!uploadToken) {
            toast.error(t('attachmentsUpload.missingToken'));
            return;
        }

        try {
            const responseBody = await uploadAttachments({
                uploadToken,
                invoice: data.invoice[0].file,
                deliveryNotes: data.deliveryNotes.map((file) => file.file),
            }).unwrap();
            let hasErrors = false;
            let allErrors = true;
            responseBody.forEach(({ name, status }) => {
                if (status === 'error') {
                    toast.error(t('attachmentsUpload.uploadErrorSingle', { fileName: name }));
                    hasErrors = true;
                    return;
                }
                allErrors = false;
                return;
            });
            if (allErrors) {
                toast.error(t('attachmentsUpload.uploadErrorAll'));
                return;
            }
            if (hasErrors) {
                toast.success(t('attachmentsUpload.uploadSuccessPartial'));
                return;
            }
            formMethods.reset();
            toast.success(t('attachmentsUpload.uploadSuccessAll'));
        } catch (error) {
            toast.error(t('attachmentsUpload.uploadErrorAll'));
        }
    };

    return (
        <AttachmentUploadContext.Provider
            value={{
                isValid,
                formMethods,
                uploadToken,
                uploadTokenPayload,
                isUploadTokenValid,
                isUploading: isLoading,
                setUploadToken: handleSetUploadToken,
                uploadAttachments: handleUploadAttachments,
            }}
        >
            {children}
        </AttachmentUploadContext.Provider>
    );
};

export const useAttachmentUpload = () => {
    return React.useContext(AttachmentUploadContext);
};
