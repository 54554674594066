import { createElement } from 'react';
import classNames from 'classnames';

import styles from './Typography.module.scss';

type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'subheading' | 'text-disabled' | 'headline-h4';

type TypographyProps = React.ComponentProps<
    Exclude<TypographyVariant, 'subheading' | 'text-disabled' | 'headline-h4'>
> & {
    variant: TypographyVariant;
    component?: TypographyVariant;
    color?: 'black' | 'primary' | 'secondary' | 'white';
    fontWeight?: 'normal' | 'medium' | 'semibold' | 'bold';
    // opacity on hover
    link?: boolean;
};

export const Typography: React.FC<TypographyProps> = ({
    variant,
    color = 'black',
    fontWeight = 'semibold',
    link = false,
    component,
    children,
    className,
    ...props
}) => {
    // Display "component" element if specified, always use styles based on the "variant".
    let element = component ? component : variant;

    if (['subheading', 'text-disabled', 'headline-h4'].includes(element)) {
        element = 'p';
    }

    return createElement(
        element,
        {
            className: classNames(className, styles[variant], styles[`${color}Color`], styles[fontWeight], {
                [styles.link]: link,
            }),
            ...props,
        },
        children,
    );
};
