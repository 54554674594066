import React, { useMemo, useRef, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import * as SelectPrimitive from '@radix-ui/react-select';
import { CrossIcon } from 'assets/icons';
import { AutocompleteOption } from 'modules/form/components/Autocomplete/AutocompleteOption';

import { FormError } from '../FormError';
import { Typography } from '../Typography';

import styles from './MultiSelectInput.module.scss';
export type MultiSelectInputSelectOptions = { label: string; value: string }[];

export type MultiSelectInputSelectProps = React.ComponentProps<typeof SelectPrimitive.Root> & {
    id?: string;
    error?: boolean;
    className?: string;
    placeholder?: string;
    options: MultiSelectInputSelectOptions;
    isNumber?: boolean;
    selected: { label: string; value: string }[];
    onValuesChange: (values: { label: string; value: string }[]) => void;
    errorMessage?: string;
};

export const MultiSelectInput = React.forwardRef<HTMLButtonElement, MultiSelectInputSelectProps>(
    ({ placeholder, selected, onValuesChange, options, errorMessage, ...props }) => {
        const [text, setText] = useState<string>('');
        const [open, setOpen] = useState(false);

        const inputRef = useRef<HTMLInputElement>(null);

        const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key !== 'Enter') return;
            e.preventDefault();
            e.stopPropagation();
            convertTextToValue();
        };

        const convertTextToValue = () => {
            if (text.trim().length < 1) return;
            onValuesChange([...selected, { label: text, value: text }]);
            setText('');
        };

        const filteredOptions = useMemo(() => {
            const filtered = options.filter((option) =>
                String(typeof option === 'string' ? option : option.label)
                    .toLowerCase()
                    .includes(text.toLowerCase()),
            );
            if (!filtered.map(({ label }) => label).includes(text)) {
                filtered.unshift({ label: text, value: text });
            }

            return filtered.splice(0, 9);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [text, options]);

        return (
            <Popover.Root
                {...props}
                open={open}
                onOpenChange={(state) => {
                    setOpen(state);
                }}
            >
                <div className={styles.inputWrapper} ref={inputRef}>
                    {
                        <ul className={styles.inputList}>
                            {selected.map((value, index) => (
                                <li className={styles.inputItem} key={index}>
                                    <Typography variant="p" className={styles.inputText}>
                                        {value.label}
                                    </Typography>
                                    <button
                                        className={styles.removeBtn}
                                        type="button"
                                        onClick={() => {
                                            const arr = [...selected];
                                            arr.splice(index, 1);
                                            onValuesChange(arr);
                                        }}
                                    >
                                        <CrossIcon color={'black'} />
                                    </button>
                                </li>
                            ))}
                            <li className={styles.inputLi}>
                                <input
                                    className={styles.input}
                                    placeholder={placeholder}
                                    onKeyDown={onKeyDown}
                                    type="text"
                                    value={text}
                                    onBlur={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        convertTextToValue();
                                    }}
                                    onChange={(e) => {
                                        setOpen(e.target.value.length > 0);
                                        setText(e.target.value);
                                    }}
                                />
                            </li>
                        </ul>
                    }
                </div>
                <FormError>{errorMessage}</FormError>
                <Popover.Anchor style={{ width: '100%', zIndex: '36' }} />
                <Popover.Portal>
                    <Popover.Content
                        avoidCollisions={false}
                        style={{
                            minWidth: inputRef.current ? `${inputRef.current.offsetWidth}px` : 'unset',
                            zIndex: '36',
                        }}
                        align="start"
                        onFocusOutside={(e) => {
                            e.preventDefault();
                        }}
                        onOpenAutoFocus={(e) => {
                            e.preventDefault();
                        }}
                        onCloseAutoFocus={(e) => {
                            e.preventDefault();
                        }}
                    >
                        {filteredOptions.length > 0 && (
                            <ul className={styles['list-box']}>
                                {filteredOptions.map((option) => (
                                    <AutocompleteOption<typeof option>
                                        option={option}
                                        selected={selected.map(({ value }) => value).includes(option.value)}
                                        setOpen={setOpen}
                                        setSelected={(option) => {
                                            onValuesChange([...selected, option]);
                                            setText('');
                                        }}
                                        labelProperty={'label'}
                                        valueProperty={'value'}
                                        key={String(option['value'])}
                                    />
                                ))}
                            </ul>
                        )}
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        );
    },
);
