import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { IconWarning, MinusIcon, PlusIcon, TrashIcon } from 'assets/icons';
import { config } from 'config';
import { fetchExchangeRate } from 'core/api/currenciesApi';
import { selectAuth } from 'core/auth/services/selectors';
import { User } from 'core/auth/types';
import { useNonTypedTranslation } from 'core/translation';
import { getWeek, getYear } from 'date-fns';
import { useAppSelector } from 'hooks';
import useChange from 'hooks/useChange';
import { isNumber } from 'lodash';
import { useGetCarrierListQuery, useGetOneCarrierQuery } from 'modules/carriers';
import { createFullName } from 'modules/carriers/utils';
import {
    ActualCommissionSchema,
    CommissionGoodsSchema,
    CommissionInformationHeader,
    CommissionLoadingDischargeSchema,
    CommissionLoadingSchema,
    CommissionSchema,
    commissionSchema,
    SendCommissionInquiryModal,
} from 'modules/commissions';
import { CommissionEnquiryState } from 'modules/commissions/components/CommissionEnquiryState';
import { SelectedTabOptions } from 'modules/commissions/components/CommissionHeaderSwitch/types';
import { EnquiryLink } from 'modules/commissions/components/EnquiryLink';
import { useEmailMessageSaverType } from 'modules/commissions/hooks/useEmailMessageSaver';
import { getOrderSourceOptions } from 'modules/commissions/utils/orderSource';
import { Currency, getCurrencyOptions } from 'modules/common';
import { useGetAllCustomersQuery, useGetOneCustomerQuery } from 'modules/customers/services';
import { useDispatcherSearchMapContext } from 'modules/dispatcherSearch/components/DispatcherSearchMap/context/useDispatcherSearchMapContext';
import { DispatcherSearchResponse } from 'modules/dispatcherSearch/types';
import {
    CheckboxField,
    CommissionCarrierForm,
    DateField,
    Form,
    FormActionButton,
    FormCard,
    FormGrid,
    FormSection,
    LoadingDischargeFormArray,
    LoadingDischargeFormRow,
    RenderArgs,
    SelectField,
    TextAreaField,
    TextField,
    VerticalFormArray,
    VisualFormInputsContext,
} from 'modules/form';
import { useLazyGetOneInvoiceQuery } from 'modules/invoicing';
import { MultiSwitch } from 'modules/multiswitch';
import { OffersDetailPage } from 'modules/offers';
import { AdminOfferStatusValue } from 'modules/offers/types';
import { formatPrice } from 'modules/table';
import { DispatcherFiltrationToggleBtn, DispatcherSearchMap, FormLabel, Typography } from 'modules/ui';
import { ArrayElement, Subset } from 'types/helpers';
import { calculateCommissionItemsProperties } from 'utils/calculateCommissionItemsProperties';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';

import { Autocomplete } from '../Autocomplete';
import { DispatcherSearchTables } from '../DispatcherSearchTables';
import { DispatcherSearchTableItem } from '../DispatcherSearchTables/hooks/useDispatcherSearchTable';
import { NumberField } from '../NumberField';
import { AttachmentsSection } from './components/AttachmentsSection';

import styles from './CommissionsBasicForm.module.scss';

export const CommissionsBasicForm: React.FC<{
    fetchedData?: ActualCommissionSchema;
    prepareData: ({
        data,
        editedBy,
        submittedFromExportButton,
        oldData,
    }: {
        data: CommissionSchema;
        editedBy: User;
        submittedFromExportButton: boolean;
        oldData?: CommissionSchema;
    }) => void;
    handleEmail?: (
        data: useEmailMessageSaverType['messages']['enquiry'],
        loadings: Subset<CommissionLoadingSchema & { companyName: string }>[],
        discharges: Subset<CommissionLoadingSchema & { companyName: string }>[],
    ) => void;
    showInquiryModal: boolean;
    setShowInquiryModal: (show: boolean) => void;
    forceSubmitForm?: boolean;
    setDisableExportButton: (disable: boolean) => void;
    selectedTab?: SelectedTabOptions;
}> = ({
    fetchedData,
    prepareData,
    handleEmail,
    showInquiryModal,
    setShowInquiryModal,
    forceSubmitForm = false,
    setDisableExportButton,
    selectedTab,
}) => {
    const { t } = useTranslation();
    const { tnt } = useNonTypedTranslation();
    const { user } = useAppSelector(selectAuth);

    const navigate = useNavigate();
    const isAlreadyCreated = Boolean(fetchedData);
    const [searchedCarriersForTables, setSearchedCarriersForTables] = useState<DispatcherSearchTableItem[]>([]);
    const [searchedCarriersForMap, setSearchedCarriersForMap] = useState<DispatcherSearchResponse>();

    const [showAssigningCarrier, setShowAssigningCarrier] = useState(true);
    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);

    // FETCHING & PREPARING DATA FOR FORM

    const formSubmitBtnRef = useRef<HTMLButtonElement | null>(null);
    const data = useMemo(() => (fetchedData ? transformCommissionIncomingData(fetchedData) : undefined), [fetchedData]);
    // INDEPENDENT HOOKS, USED IN FORM's STATE

    const { data: carriersList } = useGetCarrierListQuery(undefined);
    const [getOneInvoice] = useLazyGetOneInvoiceQuery();
    const { data: customers } = useGetAllCustomersQuery({ limit: 10000 });

    const [customersList, setCustomersList] = useState<{ label: string; value: string }[]>([]);

    const [searchParams, setSearchParams] = useSearchParams();

    // get cached commission
    const dataSource = searchParams.get('dataSource');
    const savedData = localStorage.getItem('lastOpenedCommission');

    // transform customers to format needed for list
    useEffect(() => {
        if (!customers) return;
        const customersFormatted = customers.data.map(({ company, customer_id }) => ({
            label: company,
            value: String(customer_id),
        }));
        setCustomersList(customersFormatted);
    }, [customers]);

    // FORM's INITIALIZATION & STATE

    const formDefaultValues = {
        // ...commissionUserCredentials,
        // basic information
        relation: '',
        week: getWeek(new Date()),
        number: null,
        enteredCarrierByNumber: Number(user?.number) || 0,
        year: getYear(new Date()),
        // commission status
        carrierOrderSent: false,
        orderConfirmationSent: false,
        loadingConfirmationSent: false,
        // Goods
        commissionitem: [
            {
                name: '',
                quantity: null,
                weight: null,
                weightBrutto: null,
                packaging: '',
                loadingMeters: null,
                size: '',
                commissionLoading_id: null,
                commissionDischarge_id: null,
                loadingIdx: '',
                dischargeIdx: '',
            },
        ],
        dischargeConfirmationSent: false,
        // Customer
        // customer_id: null,
        priceCustomer: null,
        currencyCustomer: null,
        invNumber: null,
        orderNumber: null,
        orderDate: String(new Date().setHours(0, 0, 0, 0)),
        customerContact_id: null,
        // Cargo load
        commissionLoadingDischarge: [
            {
                location_id: 0,
                isLoading: true,
                // company: '',
                date: '',
                dateTo: null,
                time: null,
                refNumber: null,
                location: {
                    city: '',
                    countryCode: 'CZ',
                    postalCode: '',
                    latitude: 0,
                    longitude: 0,
                },
                number: null,
            },
            {
                location_id: 0,
                isLoading: false,
                // company: '',
                date: '',
                dateTo: null,
                time: null,
                neutralization: false,
                location: {
                    city: '',
                    countryCode: 'CZ',
                    postalCode: '',
                    latitude: 0,
                    longitude: 0,
                },
                number: null,
                // refNumber: null,
            },
        ],
        // commissionLoading: [],
        // commissionDischarge: [],
        loadingRadius: 40,
        dischargeRadius: 40,
        // Carrier assignment
        carrier_id: null,
        dispatcher_id: null,
        enteredCarrierBy: createFullName(user?.name, user?.surname),
        carrierDriver: '',
        carrierGsm: '',
        carrierRegistrationPlate: '',
        priceCarrier: null,
        currencyCarrier: Currency['CZK'],
        // other
        disposition: null,
        note: null,
        notification: false,
        state: 0,
        // another
        totalloadingIdx: '',
        totaldischargeIdx: '',
        vat: '21' as '0' | '21',
        invoice_id: null,
    };

    const methods = useForm<CommissionSchema>({
        defaultValues: dataSource === 'localStorage' && savedData ? JSON.parse(savedData) : data || formDefaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(
            commissionSchema(t).refine((data) => !(data.priceCustomer && !data.currencyCustomer), {
                message: t('commissions.form.priceMustHaveCurrency'),
                path: ['currencyCustomer'],
            }),
        ),
    });

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { dirtyFields },
        resetField,
        getValues,
    } = methods;
    const {
        customer_id,
        carrier_id,
        invoice_id,
        commissionLoadingDischarge,
        totalWeight,
        totalLoadingMeters,
        orderNumber,
        currencyCustomer,
        priceCustomer,
        exchangeRateCustomer,
        currencyCarrier,
        priceCarrier,
        exchangeRateCarrier,
        commissionitem,
        dispatcher_id,
    } = useWatch({
        control,
    });

    const {
        isDispatcherFiltrationToggled,
        setIsDispatcherFiltrationToggled,
        dispatcherSelectedFromMap,
        setDispatcherSelectedFromMap,
    } = useDispatcherSearchMapContext();

    // remove this commission from localStorage & update values
    useEffect(() => {
        if (dataSource === 'localStorage' && savedData) {
            localStorage.removeItem('lastOpenedCommission');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource, savedData]);

    // HOOKS THAT DEPEND ON FORM's STATE

    const { data: fetchedCustomer } = useGetOneCustomerQuery({ id: String(customer_id) }, { skip: !customer_id });
    const { data: fetchedCarrier } = useGetOneCarrierQuery({ id: String(carrier_id) }, { skip: !carrier_id });

    // after we removed the customer (company), remove the location_id (companies) from loadings/discharges as well,
    // because the list of that location_id is fetched by (and depends on) customer
    useChange(() => {
        commissionLoadingDischarge?.forEach((_, index) => {
            setValue(`commissionLoadingDischarge.${index}.location_id`, 0);
        });
    }, [customer_id]);

    const {
        companyLoadingList = [],
        companyDischargeList = [],
        loadingDischargeCountries = [],
        contactsList = [],
    } = useMemo(() => {
        // Add deleted customer to customers list
        if (fetchedCustomer && !customersList.find((customer) => customer.value === String(customer_id))) {
            setCustomersList((state) => [
                ...state,
                { label: String(fetchedCustomer?.company), value: String(customer_id) },
            ]);
        }
        // customer has loadings / discharges in location array, extract them from each location item
        const companyLoadingList = fetchedCustomer?.location
            .filter(({ loading }) => {
                return loading === true;
            })
            .map((item, index) => ({
                ...item,
                label: `${item.company} — ${item.city}`,
                value: String(item.location_id || index),
            }));

        const companyDischargeList = fetchedCustomer?.location
            .filter(({ discharge }) => {
                return discharge === true;
            })
            .map((item, index) => ({
                ...item,
                label: `${item.company} — ${item.city}`,
                value: String(item.location_id || index),
            }));

        const loadingDischargeCountries = fetchedCustomer?.location.reduce<{ [location_id: number]: string }>(
            (acc, { location_id, countryCode }) => {
                if (location_id && countryCode) {
                    return { ...acc, [location_id]: String(countryCode) };
                }
                return acc;
            },
            [],
        );

        const contactsList = fetchedCustomer?.customercontact.map(({ firstName, lastName, customerContact_id }) => ({
            label: createFullName(firstName, lastName),
            value: String(customerContact_id),
        }));
        return { companyLoadingList, companyDischargeList, loadingDischargeCountries, contactsList };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchedCustomer]);

    const { dispatchersList = [] } = useMemo(() => {
        const dispatchersList = fetchedCarrier?.dispatcher.map(({ firstName, lastName, dispatcher_id }) => ({
            label: createFullName(firstName, lastName) || '—',
            value: String(dispatcher_id) || '',
        }));

        return { dispatchersList };
    }, [fetchedCarrier]);

    const loadingDischargeFieldArray = useFieldArray({
        control,
        name: `commissionLoadingDischarge`,
    });

    const goodsFieldArray = useFieldArray({
        control,
        name: `commissionitem`,
    });

    // we need these values for PUT request, but they are auto removed 'cause we are not using them in the form
    useEffect(() => {
        if (data?.customer_id) setValue('customer_id', data?.customer_id);

        data?.commissionitem.forEach((item, index) => {
            setValue(`commissionitem.${index}.commissionLoading_id`, item.commissionLoading_id);
            setValue(`commissionitem.${index}.commissionDischarge_id`, item.commissionDischarge_id);
            setValue(`commissionitem.${index}.commissionItem_id`, item.commissionItem_id);
        });

        data?.commissionLoadingDischarge?.forEach((item, index) => {
            if (item.isLoading) {
                setValue(`commissionLoadingDischarge.${index}.commissionLoading_id`, item.commissionLoading_id);
                setValue(`commissionLoadingDischarge.${index}.isLoading`, true);
            } else {
                setValue(`commissionLoadingDischarge.${index}.commissionDischarge_id`, item.commissionDischarge_id);
                setValue(`commissionLoadingDischarge.${index}.isLoading`, false);
            }
        }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // set the company name as location_id, the <select/> component will extract a company name by id
    const setCompanyName = () => {
        commissionLoadingDischarge?.forEach((item, index) => {
            if (item.location_id) {
                setValue(`commissionLoadingDischarge.${index}.location_id`, item.location_id);
            }
        });
    };

    const getLoadingDischargeIndexes = useMemo(() => {
        const loadingsIndexes =
            commissionLoadingDischarge?.reduce<number[]>((prev, curr, index) => {
                if (curr.isLoading) prev.push(index);
                return prev;
            }, []) || [];
        const dischargesIndexes =
            commissionLoadingDischarge?.reduce<number[]>((prev, curr, index) => {
                if (!curr.isLoading) prev.push(index);
                return prev;
            }, []) || [];
        //  setCompanyName();    //unknown purpose and causes some brikule when reordering comms/discharges - commented out for now

        return {
            loadingsIndexes,
            dischargesIndexes,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(commissionLoadingDischarge)]);

    // sets the number value according to the current order of commissions/discharges in the form
    const handleNumberChange = () => {
        for (let i = 0; i < getLoadingDischargeIndexes.loadingsIndexes.length; i++) {
            setValue(`commissionLoadingDischarge.${i}.number`, i + 1);
        }

        for (let i = 0; i < getLoadingDischargeIndexes.dischargesIndexes.length; i++) {
            const dischargeIndex = getLoadingDischargeIndexes.dischargesIndexes[i];
            setValue(`commissionLoadingDischarge.${dischargeIndex}.number`, i + 1);
        }
    };

    useEffect(() => {
        // set the relation - first loading and lsat discharge
        const firstLoadingLocation = commissionLoadingDischarge?.[0];
        const firstLoadingCountryCode = loadingDischargeCountries[Number(firstLoadingLocation?.location_id)] || '';

        const lastDischargeLocation =
            commissionLoadingDischarge?.[
                getLoadingDischargeIndexes.dischargesIndexes[getLoadingDischargeIndexes.dischargesIndexes.length - 1]
            ];
        const firstDischargeCountryCode = loadingDischargeCountries[Number(lastDischargeLocation?.location_id)] || '';

        setValue('relation', firstLoadingCountryCode + firstDischargeCountryCode);
        // set the week and year - the date of the last discharge
        if (lastDischargeLocation?.dateTo) {
            setValue('week', getWeek(Number(lastDischargeLocation.dateTo)));
            lastDischargeLocation?.date && setValue('year', getYear(Number(lastDischargeLocation.dateTo)));
        } else if (lastDischargeLocation?.date) {
            setValue('week', getWeek(Number(lastDischargeLocation.date)));
            lastDischargeLocation?.date && setValue('year', getYear(Number(lastDischargeLocation.date)));
        }

        handleNumberChange();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLoadingDischargeIndexes, JSON.stringify(loadingDischargeCountries)]);

    useEffect(() => {
        // change the state property & allow to export commission
        setDisableExportButton(!dispatcher_id);
        setValue('state', dispatcher_id ? 1 : 0);

        if (dispatcher_id === null) {
            resetField('enteredCarrierBy');
        }

        if (dirtyFields.dispatcher_id) {
            if (dispatcher_id) setValue('enteredCarrierBy', createFullName(user?.name, user?.surname));
        }
    }, [dispatcher_id]);

    // If the carrier is unassigned, reset the dispatcher_id
    useEffect(() => {
        if (!isNumber(carrier_id)) {
            setValue('dispatcher_id', null);
        }
    }, [carrier_id]);

    const getGoodsLoadingOptions = () => {
        const itemLength = getLoadingDischargeIndexes.loadingsIndexes.length;
        if (!itemLength) return [];
        return Array.from([...Array(itemLength).keys()]).map((item) => String(++item));
    };

    const getGoodsDischargeOptions = () => {
        const itemLength = getLoadingDischargeIndexes.dischargesIndexes.length;
        if (!itemLength) return [];
        return Array.from([...Array(itemLength).keys()]).map((item) => String(++item));
    };

    const locationsForCarrierSearching = useMemo(() => {
        if (!fetchedData) return 'mustSave';
        if (!commissionLoadingDischarge) return 'noLocations';

        const commissionLoadingDischargeCopy = commissionLoadingDischarge.slice();

        const firstLoading = commissionLoadingDischargeCopy.find((item) => item.isLoading);
        const lastDischarge = commissionLoadingDischargeCopy.reverse().find((item) => !item.isLoading);

        if (!firstLoading || !lastDischarge) return 'noLocations';

        const { location: loadingLocation } = firstLoading;
        const { location: dischargeLocation } = lastDischarge;

        if (
            !loadingLocation?.longitude ||
            !dischargeLocation?.latitude ||
            !loadingLocation?.latitude ||
            !dischargeLocation?.longitude
        )
            return 'noLocations';

        return {
            loading: {
                lat: Number(loadingLocation.latitude),
                lon: Number(loadingLocation.longitude),
                radius: 40,
            },
            discharge: {
                lat: Number(dischargeLocation.latitude),
                lon: Number(dischargeLocation.longitude),
                radius: 40,
            },
        };
    }, [fetchedCustomer, commissionLoadingDischarge]);

    useEffect(() => {
        const fetchInvoice = async () => {
            if (!invoice_id) return;
            const { data } = await getOneInvoice({ id: String(invoice_id) });

            setValue('invNumber', data?.invoiceNumber ? Number(data?.invoiceNumber) : null);
        };
        fetchInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice_id]);

    // if the new commission, set the actual rate
    useEffect(() => {
        if (!isAlreadyCreated) {
            const fetchRate = async () => {
                const rate = await fetchExchangeRate({ base: Currency.EUR, target: Currency.CZK });
                if (!rate) return null;
                return Number(rate.toFixed(2));
            };
            const handleRates = async () => {
                const rate = await fetchRate();
                if (!rate) return;
                setValue('exchangeRateCarrier', rate);
                setValue('exchangeRateCustomer', rate);
            };
            handleRates();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAlreadyCreated]);

    // change the customer price in CZK according to user input
    useEffect(() => {
        if (!priceCustomer || !exchangeRateCustomer || currencyCustomer === 'CZK') return;
        setValue('priceCustomerInCZK', formatPrice(Math.ceil(priceCustomer * exchangeRateCustomer)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceCustomer, exchangeRateCustomer]);

    // change the carrier price in CZK according to user input
    useEffect(() => {
        if (!exchangeRateCarrier || !priceCarrier || currencyCarrier === 'CZK') return;
        setValue('priceCarrierInCZK', formatPrice(Math.ceil(priceCarrier * exchangeRateCarrier)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceCarrier, exchangeRateCarrier, currencyCarrier]);

    // calculate the total weight and loading meters
    useEffect(() => {
        if (!commissionitem) return;
        const { totalLoadingMeters, totalWeight } = calculateCommissionItemsProperties(commissionitem);
        setValue('totalWeight', `${totalWeight} t`);
        setValue('totalLoadingMeters', `${totalLoadingMeters} m`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(commissionitem?.flatMap((field) => [field.weightBrutto, field.loadingMeters]))]);

    const onSubmit = (currentData: CommissionSchema) => {
        if (!user) return;

        if (goodsFieldArray.fields.length === 0) {
            return toast.error(t('commissions.form.noGoods'));
        }

        prepareData({
            data: { ...currentData, addedBy: fetchedData ? fetchedData.addedBy : user.email },
            editedBy: user,
            submittedFromExportButton: forceSubmitForm,
            oldData: data,
        });
    };

    useEffect(() => {
        if (forceSubmitForm) {
            formSubmitBtnRef.current?.click();
        }
    }, [forceSubmitForm]);

    const onAddNewEntity = (pathname: string) => {
        setSearchParams({ dataSource: 'localStorage' });

        localStorage.setItem('lastOpenedCommission', JSON.stringify(getValues()));
        navigate({
            pathname,
            search: createSearchParams({
                pageToReturn: window.location.pathname + window.location.search,
            }).toString(),
        });
    };

    if (selectedTab === SelectedTabOptions.Offer) {
        return (
            <OffersDetailPage
                noPageHeader
                idFromCommissionPage={fetchedData?.enquiry.length ? fetchedData.enquiry[0].enquiry_id : -1}
            />
        );
    }

    if (selectedTab == SelectedTabOptions.Carrier && typeof locationsForCarrierSearching === 'string') {
        return (
            <Typography style={{ textAlign: 'center' }} variant="h4">
                {t('commissions.form.invalidLocations')}
            </Typography>
        );
    }

    return (
        <FormProvider {...methods}>
            <VisualFormInputsContext.Provider
                value={{
                    visualInputsList,
                    setVisualInputsList,
                }}
            >
                <Form
                    style={{ marginTop: '40px' }}
                    id="commission-form"
                    onSubmit={handleSubmit(
                        (data) => onSubmit(data),
                        (error) => handleErrorsWithNoInputs(error, visualInputsList),
                    )}
                    onReset={() => reset()}
                >
                    <button ref={formSubmitBtnRef} type="submit" className={styles.hidden}></button>
                    <CommissionInformationHeader />
                    {selectedTab === SelectedTabOptions.Carrier && (
                        <>
                            <div style={{ width: '60%' }}>
                                <FormSection
                                    title={t('commissions.form.carrier.sectionTitle')}
                                    headerEndSlot={
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            {!showAssigningCarrier && (
                                                <DispatcherFiltrationToggleBtn
                                                    onDispatcherFiltrationToggled={setIsDispatcherFiltrationToggled}
                                                    isDispatcherFiltrationToggled={isDispatcherFiltrationToggled}
                                                />
                                            )}

                                            <div className={styles.carrierToggleButtons}>
                                                <MultiSwitch
                                                    name="toggle"
                                                    value={showAssigningCarrier ? 'opened' : 'closed'}
                                                    size="small"
                                                    onChange={(value) =>
                                                        setShowAssigningCarrier(value === 'opened' ? true : false)
                                                    }
                                                    options={[
                                                        {
                                                            label: t('commissions.form.carrier.actionButtons.assign'),
                                                            value: 'opened',
                                                        },
                                                        {
                                                            label: t('commissions.form.carrier.actionButtons.search'),
                                                            value: 'closed',
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    }
                                >
                                    <FormGrid columns={2}>
                                        {!showAssigningCarrier &&
                                            fetchedCustomer &&
                                            typeof locationsForCarrierSearching !== 'string' &&
                                            fetchedData &&
                                            fetchedData?.enquiry?.length > 0 && (
                                                <div className={styles.existingEnquiryLinkContainer}>
                                                    <EnquiryLink
                                                        enquiryId={fetchedData.enquiry[0].enquiry_id}
                                                        state={fetchedData.enquiry[0].state as AdminOfferStatusValue}
                                                    />
                                                </div>
                                            )}
                                    </FormGrid>
                                    {showAssigningCarrier ? (
                                        <FormGrid columns={3} rows={2}>
                                            <Autocomplete<{ label: string; value: string }>
                                                label={t('commissions.form.carrier.assignCarrier.carrier_id')}
                                                labelProperty="label"
                                                valueProperty="value"
                                                name="carrier_id"
                                                options={
                                                    carriersList?.data?.map(({ company, carrier_id }) => ({
                                                        label: company,
                                                        value: String(carrier_id),
                                                    })) || []
                                                }
                                                actionIcon={<PlusIcon />}
                                                actionLabel={t('commissions.form.carrier.assignCarrier.addNewCarrier')}
                                                actionOnClick={() => {
                                                    onAddNewEntity(config.routes.carriers.new);
                                                }}
                                                actionCloseOnClick
                                                isNumber
                                            />
                                            <Autocomplete<{ label: string; value: string }>
                                                label={t('commissions.form.carrier.assignCarrier.dispatcher_id')}
                                                labelProperty="label"
                                                valueProperty="value"
                                                name="dispatcher_id"
                                                options={dispatchersList}
                                                actionIcon={<PlusIcon />}
                                                actionLabel={t(
                                                    'commissions.form.carrier.assignCarrier.addNewDispatcher',
                                                )}
                                                actionOnClick={() => {
                                                    onAddNewEntity(`${config.routes.carriers.list}/${carrier_id}`);
                                                }}
                                                actionCloseOnClick
                                                isDisabled={!carrier_id}
                                                isNumber
                                            />
                                            <TextField
                                                name="enteredCarrierBy"
                                                label={t('commissions.form.carrier.assignCarrier.enteredCarrierBy')}
                                                disabled
                                            />
                                            <TextField
                                                name="carrierDriver"
                                                label={t('commissions.form.carrier.assignCarrier.carrierDriver')}
                                            />
                                            <TextField
                                                name="carrierGsm"
                                                label={t('commissions.form.carrier.assignCarrier.carrierGsm')}
                                            />
                                            <TextField
                                                name="carrierRegistrationPlate"
                                                label={t(
                                                    'commissions.form.carrier.assignCarrier.carrierRegistrationPlate',
                                                )}
                                            />

                                            <NumberField
                                                name="priceCarrier"
                                                label={t('commissions.form.carrier.assignCarrier.priceCarrier')}
                                            />
                                            <SelectField
                                                name="currencyCarrier"
                                                label={t('commissions.form.carrier.assignCarrier.currencyCarrier')}
                                                options={getCurrencyOptions(t)}
                                            />

                                            {currencyCarrier === 'EUR' && (
                                                <>
                                                    <NumberField
                                                        name="exchangeRateCarrier"
                                                        label={t(
                                                            'commissions.form.carrier.assignCarrier.exchangeRateCarrier',
                                                        )}
                                                    />
                                                    <TextField
                                                        name="priceCarrierInCZK"
                                                        label={t(
                                                            'commissions.form.carrier.assignCarrier.priceCarrierInCZK',
                                                        )}
                                                        disabled
                                                    />
                                                </>
                                            )}
                                        </FormGrid>
                                    ) : (
                                        <>
                                            {fetchedCustomer ? (
                                                typeof locationsForCarrierSearching !== 'string' ? (
                                                    fetchedData && fetchedData?.enquiry?.length > 0 ? (
                                                        <CommissionEnquiryState
                                                            enquiryState={
                                                                fetchedData.enquiry[0].state as AdminOfferStatusValue
                                                            }
                                                        />
                                                    ) : (
                                                        <>
                                                            <CommissionCarrierForm
                                                                locations={locationsForCarrierSearching}
                                                                onFetchedData={(
                                                                    carriers: DispatcherSearchTableItem[],
                                                                ) => {
                                                                    setSearchedCarriersForTables(carriers);
                                                                }}
                                                                fetchDataForMap={(
                                                                    carriers: DispatcherSearchResponse,
                                                                ) => {
                                                                    setSearchedCarriersForMap(carriers);
                                                                }}
                                                            />

                                                            <div>
                                                                <DispatcherSearchTables
                                                                    data={searchedCarriersForTables}
                                                                    dispatcherSelectedFromMap={
                                                                        dispatcherSelectedFromMap
                                                                    }
                                                                    setDispatcherSelectedFromMap={
                                                                        setDispatcherSelectedFromMap
                                                                    }
                                                                    setShowInquiryModal={setShowInquiryModal}
                                                                    fixedHeight={null}
                                                                    overflow={false}
                                                                />
                                                            </div>
                                                        </>
                                                    )
                                                ) : (
                                                    <div className={styles.infoContainer}>
                                                        <IconWarning />
                                                        <Typography variant="h5">
                                                            {tnt(
                                                                `commissions.form.carrier.searchCarrier.${locationsForCarrierSearching}`,
                                                            )}
                                                        </Typography>
                                                    </div>
                                                )
                                            ) : (
                                                <div className={styles.infoContainer}>
                                                    <IconWarning />
                                                    <Typography variant="h5">
                                                        {t('commissions.form.carrier.searchCarrier.noCustomer')}
                                                    </Typography>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </FormSection>
                            </div>
                            <div style={{ width: '40%', height: '100%', position: 'fixed', top: '0', right: '0' }}>
                                <DispatcherSearchMap searchedCarriers={searchedCarriersForMap} />
                            </div>
                            {showInquiryModal && fetchedData && handleEmail && commissionLoadingDischarge && (
                                <SendCommissionInquiryModal
                                    totalWeight={totalWeight?.toString() || ''}
                                    totalLoadingMeters={totalLoadingMeters?.toString() || ''}
                                    loadings={
                                        commissionLoadingDischarge
                                            .filter((item) => item.isLoading)
                                            .map((loading) => ({
                                                ...loading,
                                                companyName:
                                                    companyLoadingList.find(
                                                        (item) => item.value === String(loading.location_id),
                                                    )?.label || '',
                                            })) as CommissionLoadingDischargeSchema[]
                                    }
                                    discharges={
                                        commissionLoadingDischarge
                                            .filter((item) => !item.isLoading)
                                            .map((loading) => ({
                                                ...loading,
                                                companyName:
                                                    companyDischargeList.find(
                                                        (item) => item.value === String(loading.location_id),
                                                    )?.label || '',
                                            })) as CommissionLoadingDischargeSchema[]
                                    }
                                    handleEmail={handleEmail}
                                    onClose={() => setShowInquiryModal(false)}
                                />
                            )}
                        </>
                    )}
                    {selectedTab == SelectedTabOptions.Configuration && (
                        <>
                            <FormSection title={t('commissions.form.commissionStatus.sectionTitle')}>
                                <FormGrid columns={4}>
                                    <CheckboxField
                                        name="carrierOrderSent"
                                        label={t('commissions.form.commissionStatus.commissionCarrier')}
                                    />
                                    <CheckboxField
                                        name="orderConfirmationSent"
                                        label={t('commissions.form.commissionStatus.commissionConfirmation')}
                                    />
                                    <CheckboxField
                                        name="loadingConfirmationSent"
                                        label={t('commissions.form.commissionStatus.cargoLoadConfirmation')}
                                    />
                                    <CheckboxField
                                        name="dischargeConfirmationSent"
                                        label={t('commissions.form.commissionStatus.deliveryConfirmation')}
                                    />
                                </FormGrid>
                            </FormSection>
                            <FormSection title={t('commissions.form.customer.sectionTitle')}>
                                <FormGrid columns={4}>
                                    <Autocomplete<{ label: string; value: string }>
                                        label={t('commissions.form.customer.company')}
                                        labelProperty="label"
                                        valueProperty="value"
                                        name="customer_id"
                                        options={customersList}
                                        actionIcon={<PlusIcon />}
                                        actionLabel={t('commissions.form.customer.addNewCustomer')}
                                        actionOnClick={() => {
                                            onAddNewEntity(config.routes.customers.new);
                                        }}
                                        actionCloseOnClick
                                        isNumber
                                    />
                                    <NumberField name="priceCustomer" label={t('commissions.form.customer.price')} />
                                    <SelectField
                                        options={getCurrencyOptions(t)}
                                        name="currencyCustomer"
                                        label={t('commissions.form.customer.currency')}
                                    />
                                    {currencyCustomer === 'EUR' && (
                                        <>
                                            <NumberField
                                                name="exchangeRateCustomer"
                                                label={t('commissions.form.carrier.assignCarrier.exchangeRateCarrier')}
                                            />
                                            <TextField
                                                name="priceCustomerInCZK"
                                                label={t('commissions.form.carrier.assignCarrier.priceCarrierInCZK')}
                                                disabled
                                            />
                                        </>
                                    )}
                                    <SelectField
                                        options={getOrderSourceOptions(tnt)}
                                        name="orderNumber"
                                        label={t('commissions.form.customer.orderNumber')}
                                    />
                                    {orderNumber === 'číslo objednávky' && (
                                        <TextField
                                            name="fakeOrderNumber"
                                            label={t('commissions.form.customer.fakeOrderNumber')}
                                        />
                                    )}
                                    <DateField name="orderDate" label={t('commissions.form.customer.commissionDate')} />
                                    <Autocomplete<{ label: string; value: string }>
                                        label={t('commissions.form.customer.customerContact_id')}
                                        labelProperty="label"
                                        valueProperty="value"
                                        name="customerContact_id"
                                        options={contactsList}
                                        actionIcon={<PlusIcon />}
                                        actionLabel={t('commissions.form.customer.addNewContact')}
                                        actionOnClick={() => {
                                            onAddNewEntity(`${config.routes.customers.list}/${customer_id}`);
                                        }}
                                        actionCloseOnClick
                                        isDisabled={!customer_id}
                                        isNumber
                                    />
                                </FormGrid>
                            </FormSection>
                            <LoadingDischargeFormArray
                                {...loadingDischargeFieldArray}
                                name="commissionLoadingDischarge"
                                getLoadingDischargeIndexes={getLoadingDischargeIndexes}
                                title={t('commissions.form.cargoLoad.sectionTitleLoading')}
                                addTitle={t('commissions.form.cargoLoad.addLoad')}
                                addToPosition={getLoadingDischargeIndexes.loadingsIndexes.length}
                                defaultValues={{
                                    // company: '',
                                    date: '',
                                    dateTo: null,
                                    time: null,
                                    refNumber: null,
                                    location_id: 0,
                                    isLoading: true,
                                    location: null,
                                    number: null,
                                }}
                                addOptions={[
                                    {
                                        label: t('commissions.form.cargoLoad.sectionTitleLoading'),
                                        defaultValues: {
                                            location_id: 0,
                                            isLoading: true,
                                            time: null,
                                            date: '',
                                            dateTo: null,
                                            refNumber: null,
                                            location: null,
                                            number: null,
                                        },
                                    },
                                ]}
                                render={({
                                    index,
                                    getFieldProps,
                                    remove,
                                }: RenderArgs<CommissionSchema, CommissionLoadingDischargeSchema>) => {
                                    const isLoading = getLoadingDischargeIndexes.loadingsIndexes.includes(index);

                                    const handleLocationChange = (
                                        value: ArrayElement<typeof companyLoadingList> | null,
                                    ) => {
                                        if (!value) return;
                                        const location: CommissionSchema['commissionLoadingDischarge'][0]['location'] =
                                            {
                                                ...value,
                                                city: value.city || '',
                                                countryCode: value.countryCode || 'CZ',
                                                latitude: Number(value.latitude) || 0,
                                                longitude: Number(value.longitude) || 0,
                                                postalCode: value.postalCode || '',
                                            };
                                        setValue(`commissionLoadingDischarge.${index}.location`, location);
                                    };

                                    // used to be showed in title of each item
                                    const actualIndex = index + 1;

                                    if (!isLoading) return null;

                                    return (
                                        <LoadingDischargeFormRow
                                            actualIndex={actualIndex}
                                            rowEndSlot={
                                                <FormActionButton
                                                    style={{ backgroundColor: '#ff5555', borderRadius: '4px' }}
                                                    variant="danger"
                                                    onClick={() => remove(index)}
                                                >
                                                    <TrashIcon></TrashIcon>
                                                </FormActionButton>
                                            }
                                        >
                                            <div style={{ flex: '2' }}>
                                                <Autocomplete<ArrayElement<typeof companyLoadingList>>
                                                    {...getFieldProps('location_id')}
                                                    placeholder={t('commissions.form.cargoLoad.company')}
                                                    labelProperty="label"
                                                    valueProperty="value"
                                                    options={isLoading ? companyLoadingList : companyDischargeList}
                                                    actionIcon={<PlusIcon />}
                                                    onChange={handleLocationChange}
                                                    actionLabel={
                                                        isLoading
                                                            ? t('commissions.form.cargoLoad.addNewLoading')
                                                            : t('commissions.form.cargoLoad.addNewDischarge')
                                                    }
                                                    actionOnClick={() => {
                                                        if (customer_id)
                                                            onAddNewEntity(
                                                                `${config.routes.customers.list}/${customer_id}`,
                                                            );
                                                    }}
                                                    actionCloseOnClick
                                                    isDisabled={
                                                        isLoading
                                                            ? companyLoadingList.length < 1
                                                            : companyDischargeList.length < 1
                                                    }
                                                    isNumber
                                                />
                                            </div>

                                            <div style={{ flex: '1' }}>
                                                <DateField
                                                    {...getFieldProps('date')}
                                                    placeholder={t('commissions.form.cargoLoad.dateFrom')}
                                                />
                                            </div>

                                            <div style={{ alignContent: 'center' }}>
                                                <MinusIcon></MinusIcon>
                                            </div>

                                            <div style={{ flex: '1' }}>
                                                <DateField
                                                    {...getFieldProps('dateTo')}
                                                    placeholder={t('commissions.form.cargoLoad.dateTo')}
                                                />
                                            </div>

                                            <div style={{ flex: '1' }}>
                                                <TextField
                                                    {...getFieldProps('time')}
                                                    placeholder={t('commissions.form.cargoLoad.time')}
                                                />
                                            </div>

                                            <div style={{ flex: '2' }}>
                                                {isLoading ? (
                                                    <TextField
                                                        {...getFieldProps('refNumber')}
                                                        placeholder={t('commissions.form.cargoLoad.referenceNumber')}
                                                    />
                                                ) : (
                                                    <FormGrid>
                                                        <CheckboxField
                                                            {...getFieldProps('neutralization')}
                                                            label={t('commissions.form.cargoLoad.toNeutralize')}
                                                        />
                                                    </FormGrid>
                                                )}
                                            </div>
                                        </LoadingDischargeFormRow>
                                    );
                                }}
                            />
                            <LoadingDischargeFormArray
                                {...loadingDischargeFieldArray}
                                name="commissionLoadingDischarge"
                                getLoadingDischargeIndexes={getLoadingDischargeIndexes}
                                title={t('commissions.form.cargoLoad.sectionTitleDischarge')}
                                addTitle={t('commissions.form.cargoLoad.addLoad')}
                                defaultValues={{
                                    // company: '',
                                    date: '',
                                    dateTo: null,
                                    time: null,
                                    refNumber: null,
                                    location_id: 0,
                                    isLoading: false,
                                    location: null,
                                    number: null,
                                }}
                                addOptions={[
                                    {
                                        label: t('commissions.form.cargoLoad.sectionTitleDischarge'),
                                        defaultValues: {
                                            location_id: 0,
                                            isLoading: false,
                                            date: '',
                                            dateTo: null,
                                            time: null,
                                            neutralization: false,
                                            location: null,
                                            number: null,
                                        },
                                    },
                                ]}
                                render={({
                                    index,
                                    getFieldProps,
                                    remove,
                                }: RenderArgs<CommissionSchema, CommissionLoadingDischargeSchema>) => {
                                    const isLoading = getLoadingDischargeIndexes.loadingsIndexes.includes(index);

                                    const handleLocationChange = (
                                        value: ArrayElement<typeof companyLoadingList> | null,
                                    ) => {
                                        if (!value) return;
                                        const location: CommissionSchema['commissionLoadingDischarge'][0]['location'] =
                                            {
                                                ...value,
                                                city: value.city || '',
                                                countryCode: value.countryCode || 'CZ',
                                                latitude: Number(value.latitude) || 0,
                                                longitude: Number(value.longitude) || 0,
                                                postalCode: value.postalCode || '',
                                            };
                                        setValue(`commissionLoadingDischarge.${index}.location`, location);
                                    };
                                    // used to be showed in title of each item
                                    const actualIndex = index + 1 - getLoadingDischargeIndexes.loadingsIndexes.length;

                                    if (isLoading) return null;

                                    return (
                                        <LoadingDischargeFormRow
                                            actualIndex={actualIndex}
                                            rowEndSlot={
                                                <FormActionButton
                                                    style={{ backgroundColor: '#ff5555', borderRadius: '4px' }}
                                                    variant="danger"
                                                    onClick={() => remove(index)}
                                                >
                                                    <TrashIcon></TrashIcon>
                                                </FormActionButton>
                                            }
                                        >
                                            <div style={{ flex: '2' }}>
                                                <Autocomplete<ArrayElement<typeof companyLoadingList>>
                                                    {...getFieldProps('location_id')}
                                                    placeholder={t('commissions.form.cargoLoad.company')}
                                                    labelProperty="label"
                                                    valueProperty="value"
                                                    options={isLoading ? companyLoadingList : companyDischargeList}
                                                    actionIcon={<PlusIcon />}
                                                    onChange={handleLocationChange}
                                                    actionLabel={
                                                        isLoading
                                                            ? t('commissions.form.cargoLoad.addNewLoading')
                                                            : t('commissions.form.cargoLoad.addNewDischarge')
                                                    }
                                                    actionOnClick={() => {
                                                        if (customer_id)
                                                            onAddNewEntity(
                                                                `${config.routes.customers.list}/${customer_id}`,
                                                            );
                                                    }}
                                                    actionCloseOnClick
                                                    isDisabled={
                                                        isLoading
                                                            ? companyLoadingList.length < 1
                                                            : companyDischargeList.length < 1
                                                    }
                                                    isNumber
                                                />
                                            </div>

                                            <div style={{ flex: '1' }}>
                                                <DateField
                                                    {...getFieldProps('date')}
                                                    placeholder={t('commissions.form.cargoLoad.dateFrom')}
                                                />
                                            </div>

                                            <div style={{ alignContent: 'center' }}>
                                                <MinusIcon></MinusIcon>
                                            </div>

                                            <div style={{ flex: '1' }}>
                                                <DateField
                                                    {...getFieldProps('dateTo')}
                                                    placeholder={t('commissions.form.cargoLoad.dateTo')}
                                                />
                                            </div>

                                            <div style={{ flex: '1' }}>
                                                <TextField
                                                    {...getFieldProps('time')}
                                                    placeholder={t('commissions.form.cargoLoad.time')}
                                                />
                                            </div>

                                            <div style={{ flex: '2', alignContent: 'center' }}>
                                                {isLoading ? (
                                                    <TextField
                                                        {...getFieldProps('refNumber')}
                                                        placeholder={t('commissions.form.cargoLoad.referenceNumber')}
                                                    />
                                                ) : (
                                                    <FormGrid>
                                                        <CheckboxField
                                                            {...getFieldProps('neutralization')}
                                                            label={t('commissions.form.cargoLoad.toNeutralize')}
                                                        />
                                                    </FormGrid>
                                                )}
                                            </div>
                                        </LoadingDischargeFormRow>
                                    );
                                }}
                            />
                            <VerticalFormArray
                                {...goodsFieldArray}
                                name="commissionitem"
                                title={t('commissions.form.goods.sectionTitle')}
                                addTitle={t('form.addItem') + ' +'}
                                defaultValues={{
                                    name: '',
                                    quantity: null,
                                    weight: null,
                                    weightBrutto: null,
                                    packaging: '',
                                    loadingMeters: null,
                                    size: '',
                                    commissionLoading_id: null,
                                    commissionDischarge_id: null,
                                    loadingIdx: '',
                                    dischargeIdx: '',
                                }}
                                endSlot={
                                    <div className={styles.totalParametersBox}>
                                        <FormGrid columns={2}>
                                            <TextField
                                                name="totalWeight"
                                                label={t('commissions.form.goods.totalWeight')}
                                                className={styles.totalParameterField}
                                                disabled
                                            />
                                            <TextField
                                                name="totalLoadingMeters"
                                                label={t('commissions.form.goods.totalLoadingMeters')}
                                                className={styles.totalParameterField}
                                                disabled
                                            />
                                        </FormGrid>
                                    </div>
                                }
                                render={({
                                    index,
                                    getFieldProps,
                                    remove,
                                }: RenderArgs<CommissionSchema, CommissionGoodsSchema>) => (
                                    <FormCard
                                        title={`${t('form.item')} ${index + 1}`}
                                        headerEndSlot={
                                            <FormActionButton variant="danger" onClick={() => remove(index)}>
                                                {t('form.removeItem')}
                                            </FormActionButton>
                                        }
                                    >
                                        <FormGrid>
                                            <FormGrid columns={4} rows={2}>
                                                <TextField
                                                    {...getFieldProps('name')}
                                                    label={t('commissions.form.goods.name')}
                                                />
                                                <FormGrid columns={2} rows={1}>
                                                    <SelectField
                                                        {...getFieldProps('loadingIdx')}
                                                        label={t('commissions.form.goods.cargoLoad')}
                                                        options={getGoodsLoadingOptions()}
                                                        disabled={companyLoadingList.length < 1}
                                                    />
                                                    <SelectField
                                                        {...getFieldProps('dischargeIdx')}
                                                        label={t('commissions.form.goods.cargoUnload')}
                                                        options={getGoodsDischargeOptions()}
                                                        disabled={companyDischargeList.length < 1}
                                                    />
                                                </FormGrid>
                                                <NumberField
                                                    {...getFieldProps('quantity')}
                                                    label={t('commissions.form.goods.amount')}
                                                    placeholder="0 ks"
                                                />
                                                <NumberField
                                                    {...getFieldProps('weight')}
                                                    label={t('commissions.form.goods.weightInKg')}
                                                    nonDecimal
                                                />
                                                <NumberField
                                                    {...getFieldProps('weightBrutto')}
                                                    label={t('commissions.form.goods.weightBrutto')}
                                                    nonDecimal
                                                />
                                                <TextField
                                                    {...getFieldProps('packaging')}
                                                    label={t('commissions.form.goods.packaging')}
                                                />
                                                <NumberField
                                                    {...getFieldProps('loadingMeters')}
                                                    label={t('commissions.form.goods.freightTonne')}
                                                />
                                                <TextField
                                                    {...getFieldProps('size')}
                                                    label={t('commissions.form.goods.size')}
                                                />
                                            </FormGrid>
                                        </FormGrid>
                                    </FormCard>
                                )}
                            />
                            {data?.commission_id && data?.number && (
                                <AttachmentsSection commissionId={data.commission_id} commissionNumber={data.number} />
                            )}
                            <FormSection title={t('commissions.form.other.sectionTitle')}>
                                <FormGrid columns={2}>
                                    <TextAreaField name="disposition" label={t('commissions.form.other.disposition')} />
                                    <TextAreaField name="note" label={t('commissions.form.other.note')} />
                                    <FormGrid columns={2}>
                                        <div>
                                            <FormLabel htmlFor={'notification'}>
                                                {t('commissions.form.other.notification')}
                                            </FormLabel>
                                            <CheckboxField
                                                name="notification"
                                                label={t('commissions.form.other.notification')}
                                            />
                                        </div>
                                    </FormGrid>
                                </FormGrid>
                            </FormSection>
                        </>
                    )}
                </Form>
            </VisualFormInputsContext.Provider>
        </FormProvider>
    );
};

export function transformCommissionIncomingData(fetchedData: ActualCommissionSchema): CommissionSchema {
    const { commissionitem, commissionloading, commissiondischarge, ...otherData } = fetchedData;

    // Separate loading and discharge arrays
    const loadingArray = fetchedData.commissionloading.map((item) => ({
        ...item,
        isLoading: true,
        location: item.location
            ? {
                  ...item.location,
                  latitude: Number(item.location.latitude),
                  longitude: Number(item.location.longitude),
              }
            : null,
    }));

    const dischargeArray = fetchedData.commissiondischarge.map((item) => ({
        ...item,
        isLoading: false,
        location: item.location
            ? {
                  ...item.location,
                  latitude: Number(item.location.latitude),
                  longitude: Number(item.location.longitude),
              }
            : null,
    }));

    // Sort each array separately by the "number" attribute
    loadingArray.sort((a, b) => (a.number ?? 0) - (b.number ?? 0));
    dischargeArray.sort((a, b) => (a.number ?? 0) - (b.number ?? 0));

    // Combine the sorted arrays back together
    const commissionLoadingDischarge = [...loadingArray, ...dischargeArray];

    const transformedData: CommissionSchema = {
        ...otherData,
        priceCustomer: !fetchedData.priceCustomer ? null : Number(fetchedData.priceCustomer),
        priceCarrier: !fetchedData.priceCarrier ? null : Number(fetchedData.priceCarrier),
        invNumber: null,
        totalloadingIdx: String(loadingArray.length),
        totaldischargeIdx: String(dischargeArray.length),
        commissionLoadingDischarge, // Use the sorted combined array

        commissionitem: commissionitem.map((item) => {
            const loadingIndex = loadingArray.findIndex(
                (loading) => loading.commissionLoading_id === item.commissionLoading_id,
            );
            const dischargeIndex = dischargeArray.findIndex(
                (discharge) => discharge.commissionDischarge_id === item.commissionDischarge_id,
            );

            const transformedItem = {
                ...item,
                loadingMeters: !item.loadingMeters ? null : Number(item.loadingMeters),
                weight: !item.weight ? null : Number(item.weight),
                weightBrutto: !item.weightBrutto ? null : Number(item.weightBrutto),
                quantity: !item.quantity ? null : Number(item.quantity),
                loadingIdx: String(loadingIndex + 1),
                dischargeIdx: String(dischargeIndex + 1),
            };

            return transformedItem;
        }),

        // back compatibility
        exchangeRateCarrier: !fetchedData.exchangeRateCarrier ? 24 : Number(fetchedData.exchangeRateCarrier),
        exchangeRateCustomer: !fetchedData.exchangeRateCustomer ? 24 : Number(fetchedData.exchangeRateCustomer),
        vat: fetchedData.vat === '21' || fetchedData.vat === '0' ? fetchedData.vat : '21',
        state: fetchedData.state === null ? 0 : fetchedData.state,
    };

    if (fetchedData.orderNumber && !['e-mail', 'telefon'].includes(fetchedData.orderNumber)) {
        transformedData.fakeOrderNumber = fetchedData.orderNumber;
        transformedData.orderNumber = 'číslo objednávky';
    } else if (!fetchedData.orderNumber) {
        transformedData.orderNumber = 'nezadáno';
    }

    return transformedData;
}
