import { config } from 'config';
import { generalApi, GeneralApiTagTypes } from 'core/api';

import {
    TAttachmentCreateRequestBody,
    TAttachmentCreateResponseBody,
    TAttachmentDeleteRequestBody,
    TAttachmentDeleteResponseBody,
    TAttachmentFileResponseBody,
    TAttachmentResponseBody,
    TAttachmentUpdateRequestBody,
    TAttachmentUpdateResponseBody,
    TGetAttachmentFileRequestBody,
    TGetAttachmentRequestBody,
    TListAttachmentsRequestBody,
    TListAttachmentsResponseBody,
    TMailAttachmentFileRequestBody,
    TMailAttachmentFileResponseBody,
    TUploadAttachmentsRequestBody,
    TUploadAttachmentsResponseBody,
} from '../types';

export const attachmentsApi = generalApi.injectEndpoints({
    endpoints: (builder) => ({
        listAttachments: builder.query<TListAttachmentsResponseBody, TListAttachmentsRequestBody>({
            query: ({
                commissionId,
                includeCommission,
                includeInvoice,
                includeUploadedBy,
                invoiceId,
                withCommissionsDeliveryNotes,
            }) => {
                const params = new URLSearchParams();

                if (includeUploadedBy) params.append('include[]', 'uploadedBy');
                if (includeCommission) params.append('include[]', 'commission');
                if (includeInvoice) params.append('include[]', 'invoice');
                if (commissionId) params.append('commissionId', commissionId.toString());
                if (invoiceId) params.append('invoiceId', invoiceId.toString());
                if (withCommissionsDeliveryNotes) params.append('withCommissionsDeliveryNotes', 'true');

                return {
                    url: config.api.endpoints.attachments.list,
                    method: 'GET',
                    params,
                };
            },
            providesTags: (_, __, arg) => [
                { type: GeneralApiTagTypes.attachment.list, id: arg.commissionId || arg.invoiceId },
            ],
        }),
        mailAttachmentFile: builder.mutation<TMailAttachmentFileResponseBody, TMailAttachmentFileRequestBody>({
            query: ({ attachmentId, email, message, language }) => {
                return {
                    url: config.api.endpoints.attachments.mailAttachment.replace(':id', attachmentId.toString()),
                    method: 'POST',
                    body: {
                        email,
                        message,
                        lang: language,
                    },
                };
            },
            invalidatesTags: (_, __, arg) => [{ type: GeneralApiTagTypes.attachment.item }],
        }),
        deleteAttachment: builder.mutation<TAttachmentDeleteResponseBody, TAttachmentDeleteRequestBody>({
            query: ({ attachmentId }) => {
                return {
                    url: config.api.endpoints.attachments.delete.replace(':id', attachmentId.toString()),
                    method: 'DELETE',
                };
            },
            invalidatesTags: (_, __, arg) => [
                { type: GeneralApiTagTypes.attachment.item },
                { type: GeneralApiTagTypes.attachment.file, id: arg.attachmentId },
                { type: GeneralApiTagTypes.attachment.list },
            ],
        }),
        createAttachment: builder.mutation<TAttachmentCreateResponseBody, TAttachmentCreateRequestBody>({
            query: ({ file, commissionId, invoiceId, name, type }) => {
                const body = new FormData();

                body.append('name', name);
                body.append('type', type);
                body.append('attachment', file);

                invoiceId && body.append('invoiceId', invoiceId.toString());
                commissionId && body.append('commissionId', commissionId.toString());

                return {
                    url: config.api.endpoints.attachments.create,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: (_, __, args) => [
                { type: GeneralApiTagTypes.attachment.list, id: args.commissionId || args.invoiceId },
            ],
        }),
        uploadAttachments: builder.mutation<TUploadAttachmentsResponseBody, TUploadAttachmentsRequestBody>({
            query: (data) => {
                const body = new FormData();
                body.append('invoice', data.invoice);
                data.deliveryNotes.forEach((file) => {
                    body.append('deliveryNotes', file);
                });
                return {
                    url: config.api.endpoints.attachments.upload,
                    method: 'POST',
                    headers: {
                        'x-auth-token': data.uploadToken,
                    },
                    body,
                };
            },
        }),
        getAttachment: builder.query<TAttachmentResponseBody, TGetAttachmentRequestBody>({
            query: ({
                attachmentId,
                includeUploadedBy,
                includeCommission,
                includeInvoice,
                includeInvoiceEmailData,
            }) => {
                const params = new URLSearchParams();

                if (includeUploadedBy) params.append('include[]', 'uploadedBy');
                if (includeCommission) params.append('include[]', 'commission');
                if (includeInvoice) params.append('include[]', 'invoice');
                if (includeInvoiceEmailData) params.append('include[]', 'invoiceEmailData');
                return {
                    url: config.api.endpoints.attachments.get.replace(':id', attachmentId.toString()),
                    method: 'GET',
                    params,
                };
            },
            providesTags: (_, __, arg) => [{ type: GeneralApiTagTypes.attachment.item, id: JSON.stringify(arg) }],
        }),
        getAttachmentFile: builder.query<TAttachmentFileResponseBody, TGetAttachmentFileRequestBody>({
            query: ({ attachmentId }) => {
                return {
                    url: config.api.endpoints.attachments.getFile.replace(':id', attachmentId.toString()),
                    method: 'GET',
                    responseHandler: async (response) => response,
                };
            },
            transformResponse: async (response: Response) => {
                const data = URL.createObjectURL(await response.blob());
                const headers = response.headers;
                return { data, headers };
            },
            providesTags: (_, __, arg) => [{ type: GeneralApiTagTypes.attachment.file, id: arg.attachmentId }],
        }),
        updateAttachment: builder.mutation<TAttachmentUpdateResponseBody, TAttachmentUpdateRequestBody>({
            query: ({ attachmentId, commissionId, invoiceId, name, type }) => {
                return {
                    url: config.api.endpoints.attachments.update.replace(':id', attachmentId.toString()),
                    method: 'PUT',
                    body: {
                        commissionId,
                        invoiceId,
                        name,
                        type,
                    },
                };
            },
            invalidatesTags: (_, __, arg) => [
                { type: GeneralApiTagTypes.attachment.item },
                { type: GeneralApiTagTypes.attachment.file, id: arg.attachmentId },
                { type: GeneralApiTagTypes.attachment.list },
            ],
        }),
    }),
});

export const {
    useUploadAttachmentsMutation,
    useGetAttachmentQuery,
    useLazyGetAttachmentQuery,
    useGetAttachmentFileQuery,
    useLazyGetAttachmentFileQuery,
    useUpdateAttachmentMutation,
    useCreateAttachmentMutation,
    useDeleteAttachmentMutation,
    useMailAttachmentFileMutation,
    useListAttachmentsQuery,
    useLazyListAttachmentsQuery,
} = attachmentsApi;
