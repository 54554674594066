import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { config } from 'config';
import { useGetQueryParams } from 'hooks/useGetQueryParams';
import { AttachmentUploadContextProvider, useAttachmentUpload } from 'modules/attachments/contexts/attachmentUpload';
import { Header, PageContent, Subheader } from 'modules/layout';
import { Toast } from 'modules/toast';
import { Button, Typography } from 'modules/ui';
import { LanguageSwitch } from 'modules/ui/components/LanguageSwitch';

import { AttachmentsUploadForm } from '../AttachmentsUploadForm';

import styles from './AttachmentsUploadPage.module.scss';

export interface IAttachmentsUploadPageProps {}

const AttachmentsUploadPageInner: React.FC<IAttachmentsUploadPageProps> = () => {
    const { t } = useTranslation();
    const { uploadToken } = useGetQueryParams<{ uploadToken: string }>();
    const navigate = useNavigate();

    const { uploadTokenPayload, isUploading, isValid, setUploadToken, isUploadTokenValid } = useAttachmentUpload();

    useLayoutEffect(() => {
        if (uploadToken) {
            setUploadToken(uploadToken);
        }
    }, [uploadToken]);

    if (!isUploadTokenValid) {
        return (
            <div className={styles.errorPage}>
                <Typography variant="h2">{t('attachmentsUpload.unableToUpload')}</Typography>
                <Typography variant="subheading">{t('attachmentsUpload.unableToUploadDescription')}</Typography>
                <Button style={{ marginTop: 20 }} onClick={() => navigate(config.routes.auth.login)}>
                    {t('auth.login.title')}
                </Button>
            </div>
        );
    }

    return (
        <>
            <Header hideNavigation>
                <LanguageSwitch />
            </Header>
            <PageContent
                fullWidthHeader
                subheader={
                    <Subheader
                        title={`${t('attachmentsUpload.title')} ${uploadTokenPayload?.commissionQId || ''}`}
                        endSlot={
                            <Button
                                form="attachmentUploadForm"
                                disabled={!isValid}
                                type="submit"
                                isLoading={isUploading}
                            >
                                {t('attachmentsUpload.submitBtn')}
                            </Button>
                        }
                    ></Subheader>
                }
            >
                <div className={styles.artificialGap} />
                <div className={styles.container}>
                    <AttachmentsUploadForm />
                </div>
            </PageContent>
        </>
    );
};

export const AttachmentsUploadPage: React.FC<IAttachmentsUploadPageProps> = (props) => {
    return (
        <AttachmentUploadContextProvider>
            <Toast />
            <AttachmentsUploadPageInner {...props} />
        </AttachmentUploadContextProvider>
    );
};
