import { Dispatch, SetStateAction } from 'react';
import { Row } from '@tanstack/react-table';
import { Table, TableContainer, TableProps } from 'modules/table';

import { DispatcherSearchTableItem, useDispatcherSearchTable } from '../../hooks/useDispatcherSearchTable';

export type TSearchedDispatchersTableProps = {
    data: DispatcherSearchTableItem[];
    selectedDispatchers: DispatcherSearchTableItem[];
    setSelectedDispatchers: Dispatch<SetStateAction<DispatcherSearchTableItem[]>>;
    tableName: string;
} & Pick<TableProps, 'overflow' | 'storeColumnWidth' | 'fixedHeight'>;
export const SearchedDispatchersTable: React.FC<TSearchedDispatchersTableProps> = ({
    data,
    selectedDispatchers,
    setSelectedDispatchers,
    tableName,
    fixedHeight = 300,
    storeColumnWidth,
    overflow,
}) => {
    const onRowSelect = (rows: Row<DispatcherSearchTableItem>[]) => {
        setSelectedDispatchers((prev) => [...prev, ...rows.map((row) => row.original)]);
    };

    const { paginatedData, filteredData, tableProps, columns } = useDispatcherSearchTable(
        data,
        tableName,
        selectedDispatchers,
        undefined,
        onRowSelect,
    );

    // set row selection to be always empty
    tableProps.rowSelection = {};
    tableProps.allRowsSelected = false;

    return (
        <>
            <TableContainer>
                <Table
                    data={paginatedData}
                    filtered={filteredData}
                    columns={columns}
                    totalCount={filteredData?.length}
                    bordered={true}
                    fixedHeight={fixedHeight ? fixedHeight : undefined}
                    storeColumnWidth={storeColumnWidth}
                    overflow={overflow}
                    {...tableProps}
                />
            </TableContainer>
        </>
    );
};
