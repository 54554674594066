import { config } from 'config';
import { generalApi, GeneralApiTagTypes } from 'core/api';
import i18n from 'core/translation/i18next/i18next';
import { NotInvoicedCommission, notInvoicedCommission, SendEmailSchema } from 'modules/commissions';
import { Currency } from 'modules/common';
import { QueryParams } from 'types/query-params';

import {
    InvoiceDetailSchema,
    invoiceDetailSchema,
    InvoicePreviewPdfRequestSchema,
    type InvoiceSchema,
    type InvoicesPageSchema,
    invoicesPageSchema,
    TListInvoiceNumbersRequestBody,
    TListInvoiceNumbersResponseBody,
    UninvoicedCountsSchema,
    uninvoicedCountsSchema,
} from '../types';
import { TransformedInvoicingData } from '../utils/FormattingInvoicingData/types';

export const invoicingApi = generalApi.injectEndpoints({
    endpoints: (builder) => ({
        getInvoices: builder.query<InvoicesPageSchema, QueryParams<InvoiceSchema>>({
            providesTags: [GeneralApiTagTypes.invoice.list],
            query: (params) => ({
                url: config.api.endpoints.invoices,
                method: 'GET',
                params,
                transformResponse: (res: InvoicesPageSchema) => {
                    const validatedSchema = invoicesPageSchema().parse(res);
                    return validatedSchema;
                },
            }),
        }),
        getOneInvoice: builder.query<InvoiceDetailSchema, { id: string }>({
            providesTags: (_result, _error, arg) => [{ type: GeneralApiTagTypes.invoice.item, id: arg.id }],
            query: ({ id }) => ({
                url: `${config.api.endpoints.invoices}/${id}`,
                method: 'GET',
            }),
            transformResponse: (res: InvoiceDetailSchema) => {
                // safe parse only used for back compatibility
                const validatedSchema = invoiceDetailSchema(i18n.t).safeParse(res);
                if (!validatedSchema.success) console.warn('Validation failed in getOneInvoice');
                return res;
            },
        }),
        postNewInvoice: builder.mutation({
            query: ({ data }: { data: TransformedInvoicingData }) => ({
                url: config.api.endpoints.invoices,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_, error) => (error ? [] : [GeneralApiTagTypes.invoice.list]),
        }),
        putUpdatedInvoice: builder.mutation({
            query: ({ data, id }: { data: TransformedInvoicingData; id: string }) => ({
                url: `${config.api.endpoints.invoices}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (_, error, arg) =>
                error
                    ? []
                    : [
                          GeneralApiTagTypes.invoice.list,
                          { type: GeneralApiTagTypes.invoice.item, id: arg.id },
                          { type: GeneralApiTagTypes.attachment.list, id: arg.id },
                      ],
        }),
        getUninvoicedCounts: builder.query<UninvoicedCountsSchema, null>({
            providesTags: [GeneralApiTagTypes.invoice.list],
            query: () => ({
                url: config.api.endpoints.uninvoicedCounts,
                method: 'GET',
            }),
            transformResponse: (res: UninvoicedCountsSchema) => {
                const validatedSchema = uninvoicedCountsSchema().parse(res);
                return validatedSchema;
            },
        }),
        getUninvoicedItems: builder.query<
            NotInvoicedCommission,
            {
                customer_company: string;
                currency: Currency;
                limit: number;
                offset: number;
            }
        >({
            providesTags: [GeneralApiTagTypes.invoice.list],
            query: (params) => ({
                url: config.api.endpoints.uninvoicedItems,
                method: 'GET',
                params,
            }),
            transformResponse: (res: NotInvoicedCommission) => {
                // safe parse only used for back compatibility
                const validatedSchema = notInvoicedCommission().safeParse(res);
                if (!validatedSchema.success) console.warn('Validation failed in getUninvoicedItems');
                return res;
            },
        }),
        getInvoicePreviewPdf: builder.query<Blob, { body: InvoicePreviewPdfRequestSchema }>({
            providesTags: [GeneralApiTagTypes.invoice.pdf],
            query: ({ body }) => ({
                url: config.api.endpoints.invoicePdf,
                method: 'POST',
                body,
                responseHandler: (response) => response.blob(),
            }),
        }),
        postInvoiceEmail: builder.query({
            query: ({ data }: { data: SendEmailSchema }) => ({
                url: config.api.endpoints.invoiceEmail,
                method: 'POST',
                body: data,
            }),
        }),
        deleteInvoice: builder.mutation<InvoiceSchema, { id: number }>({
            query: ({ id }) => ({
                url: `${config.api.endpoints.invoices}/${id}`,
                method: 'DELETE',
            }),

            invalidatesTags: [GeneralApiTagTypes.invoice.list],
        }),
        listInvoiceNumbers: builder.query<TListInvoiceNumbersResponseBody, TListInvoiceNumbersRequestBody>({
            query: ({ search }) => {
                return {
                    url: config.api.endpoints.invoiceNumbers,
                    method: 'GET',
                    params: {
                        ...(search ? { search } : {}),
                    },
                };
            },
            providesTags: (_, __, arg) => [{ type: GeneralApiTagTypes.invoice.numbers, id: arg.search }],
        }),
    }),
});

export const {
    useGetInvoicesQuery,
    useGetOneInvoiceQuery,
    useLazyGetOneInvoiceQuery,
    usePostNewInvoiceMutation,
    usePutUpdatedInvoiceMutation,
    useDeleteInvoiceMutation,
    useGetUninvoicedCountsQuery,
    useLazyGetUninvoicedItemsQuery,
    useLazyGetInvoicePreviewPdfQuery,
    useLazyPostInvoiceEmailQuery,
    useListInvoiceNumbersQuery,
    useLazyListInvoiceNumbersQuery,
} = invoicingApi;
