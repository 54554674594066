import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {  useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { TrashIcon } from 'assets/icons';
import { config } from 'config';
import { useDocumentTitle } from 'core/application/hooks';
import { User } from 'core/auth/types';
import {
    useDeleteUpdatedCarrierMutation,
    useGetOneCarrierQuery,
    usePutUpdatedCarrierMutation,
} from 'modules/carriers/services';
import { CarrierNewSchema } from 'modules/carriers/types';
import { transformCarrierData } from 'modules/carriers/utils/FormattingCarrierData';
import { PageContent, Subheader } from 'modules/layout';
import { BackButton, Button, NotFoundPage,Spinner } from 'modules/ui';

import { CarrierBasicForm } from '../../../form/components';
import { Modal } from '../../../ui/components/Modal/Modal';

import styles from './CarrierDetailPage.module.scss';

export const CarrierDetailPage: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    useDocumentTitle(t('nav.carriers'));
    const [searchParams] = useSearchParams();
    const [showRemoveConfirmationPopup, setShowRemoveConfirmationPopup] = useState(false);

    const { data: response, isLoading, isFetching, isError, error } = useGetOneCarrierQuery({ id: id ?? '' });

    const [putUpdatedCarrier, { isLoading: isSubmittingFormLoading }] = usePutUpdatedCarrierMutation();
    const [deleteCarrier, { isLoading: isSubmittingDeleting }] = useDeleteUpdatedCarrierMutation();

    if (isLoading || isFetching || !id) return <Spinner fullScreen />;
    const fetchedData: CarrierNewSchema | undefined = response;

    if (isError) {
        if ('status' in (error as FetchBaseQueryError)) {
            const fetchError = error as FetchBaseQueryError;

            if (fetchError.status === 404) {
                return <NotFoundPage routeKey='carriers'/>;
            }
        }
    }


    const prepareData = async (data: CarrierNewSchema, editedBy: User, oldData?: CarrierNewSchema) => {
        if (!oldData) return;
        const formatted = transformCarrierData({ currentData: data, editedBy, oldData });

        try {
            await putUpdatedCarrier({ data: formatted, id: Number(id) }).unwrap();
            toast.success(t('carriers.updated'));

            const pageToReturn = searchParams.get('pageToReturn');
            if(pageToReturn) {
                const [pathname, search] = pageToReturn.split('?');
                navigate({
                    pathname: pathname,
                    search: search
                })
                return;
            }
            navigate(config.routes.carriers.list);
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    const onRemoveItem = async () => {
        try {
            await deleteCarrier({
                id: Number(id),
            }).unwrap();

            toast.success(t('carriers.removed'));
            navigate(config.routes.carriers.list);
        } catch (error) {
            toast.error(t('form.saveError'));
        } finally {
            setShowRemoveConfirmationPopup(false);
        }
    };

    return (
        <PageContent
            subheader={
                <Subheader
                    startSlot={<BackButton />}
                    endSlot={
                        <div className={styles.actionButtons}>
                            <Button
                                onClick={() => setShowRemoveConfirmationPopup(true)}
                                isLoading={isSubmittingDeleting}
                                disabled={isSubmittingDeleting}
                                className={styles.deleteBtn}
                                type="button"
                                variant="primary"
                                danger
                                //style={{ display: 'none' }}
                            >
                                <TrashIcon />
                            </Button>
                            <Button
                                isLoading={isSubmittingFormLoading}
                                disabled={isSubmittingFormLoading}
                                form="carrier-form"
                                type="submit"
                            >
                                {t('carriers.form.save')}
                            </Button>
                        </div>
                    }
                    title={fetchedData?.company ?? ''}
                />
            }
        >
            {fetchedData && <CarrierBasicForm fetchedData={fetchedData} prepareData={prepareData} />}
            {/* if we remove the item */}
            {showRemoveConfirmationPopup && (
                <Modal
                    onClick={() => setShowRemoveConfirmationPopup(false)}
                    label={t('carriers.modalRemove.label')}
                    cancelComponent={
                        <Button
                            onClick={() => setShowRemoveConfirmationPopup(false)}
                            className={styles.btn}
                            type="button"
                            variant="secondary"
                            isLoading={isSubmittingDeleting}
                            disabled={isSubmittingDeleting}
                        >
                            {t('carriers.modalRemove.cancel')}
                        </Button>
                    }
                    approveComponent={
                        <Button className={styles.btn} type="button" danger variant="primary" onClick={onRemoveItem}>
                            {t('carriers.modalRemove.approve')}
                        </Button>
                    }
                />
            )}
        </PageContent>
    );
};
