import z from 'zod';

import { EAttachmentType, TAttachment } from '.';

export type TTransformedAttachment = Omit<TAttachment, 'uploadedBy' | 'type' | 'commission'> & {
    uploadedBy: string;
    type: string;
    commission: string;
};

export const initialCommissionSchema = () => {
    return z.object({
        commission_id: z.number(),
        number: z.number(),
    });
};

export const initialInvoiceSchema = () => {
    return z.object({
        invoice_id: z.number(),
        number: z.string().min(1),
    });
};

export const attachmentDetailFormSchema = () => {
    return z.object({
        attachment_id: z.number().optional(),
        name: z.string().min(1),
        type: z.enum([EAttachmentType.DELIVERY_NOTE, EAttachmentType.INVOICE]),
        commission_id: z.number().nullish(),
        invoice_id: z.number().nullish(),
        file: z.instanceof(File).optional(),
    });
};

export const attachmentDetailFormValidationSchema = () => {
    return attachmentDetailFormSchema().refine(
        (data) => data.commission_id !== undefined || data.invoice_id !== undefined,
        {
            path: ['commission_id', 'invoice_id'],
        },
    );
};

export type TAttachmentDetailFormSchema = z.infer<ReturnType<typeof attachmentDetailFormSchema>>;
export type TInitialCommission = z.infer<ReturnType<typeof initialCommissionSchema>>;
export type TInitialInvoice = z.infer<ReturnType<typeof initialInvoiceSchema>>;
