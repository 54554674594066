import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';
import { baseQueryWithAuthRefresh } from 'core/api/baseQuery';
import i18n from 'core/translation/i18next/i18next';
import { DispatcherSearchResponse } from 'modules/dispatcherSearch/types';
import { SearchCarrierRequest } from 'modules/form';
import { QueryParams } from 'types/query-params';

import {
    CarrierNewSchema,
    carrierNewSchema,
    type CarrierPageSchema,
    carrierPageSchema,
    CarrierSchema,
    GetCarrierListSchema,
    PostOnboardingEmailSchema,
    VehicleFeaturesList,
    vehicleFeaturesList,
} from '../types';
import { TransformedCarrierData } from '../utils/FormattingCarrierData/types';

export const carrierApi = createApi({
    reducerPath: 'carrierApi',
    baseQuery: baseQueryWithAuthRefresh,
    tagTypes: ['Carriers', 'CarrierItem', 'Feature'],
    endpoints: (builder) => ({
        getCarriers: builder.query<CarrierPageSchema, QueryParams<CarrierSchema>>({
            providesTags: ['Carriers'],
            query: (params) => ({
                url: config.api.endpoints.carrier,
                params,
            }),
            transformResponse: (res: CarrierPageSchema) => {
                const validatedSchema = carrierPageSchema(i18n.t).safeParse(res);
                if (!validatedSchema.success) {
                    console.warn('Validation failed in getCarrier');
                }
                return res;
            },
        }),
        getOneCarrier: builder.query<CarrierNewSchema, { id: string }>({
            providesTags: (_result, _error, arg) => [{ type: 'CarrierItem', id: arg.id }],
            query: ({ id }) => ({
                url: `${config.api.endpoints.carrier}/${id}`,
            }),
            transformResponse: (res: CarrierNewSchema) => {
                // used for back compatibility; almost all carriers are incorrect
                const validatedSchema = carrierNewSchema(i18n.t).safeParse(res);
                if (!validatedSchema.success) {
                    console.warn('Validation failed in getOneCarrier');
                }
                return res;
            },
        }),
        postNewCarrier: builder.mutation<CarrierNewSchema, { data: TransformedCarrierData }>({
            query: ({ data }) => ({
                url: config.api.endpoints.carrier,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Carriers']),
        }),
        putUpdatedCarrier: builder.mutation<CarrierNewSchema, { data: TransformedCarrierData; id: number }>({
            query: ({ data, id }) => ({
                url: `${config.api.endpoints.carrier}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (_, error, arg) => (error ? [] : ['Carriers', { type: 'CarrierItem', id: arg.id }]),
        }),
        deleteUpdatedCarrier: builder.mutation<CarrierNewSchema, { id: number }>({
            query: ({ id }) => ({
                url: `${config.api.endpoints.carrier}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Carriers']),
        }),
        getCarrierList: builder.query<GetCarrierListSchema, undefined>({
            providesTags: ['Carriers'],
            query: () => ({
                url: config.api.endpoints.carrierList,
                method: 'GET',
            }),
        }),
        postOnboardingEmail: builder.mutation<{ [email: string]: boolean }, PostOnboardingEmailSchema>({
            query: (body) => ({
                url: config.api.endpoints.onboardingEmail,
                method: 'POST',
                body,
            }),
            invalidatesTags: (_, error, arg) =>
                error ? [] : ['Carriers', { type: 'CarrierItem', id: arg.carrier_id }],
        }),
        getVehicleFeatures: builder.query<VehicleFeaturesList, null>({
            providesTags: ['Feature'],
            query: () => ({
                url: `${config.api.endpoints.vehicleType}`,
            }),
            transformResponse: (res: VehicleFeaturesList) => {
                const validatedSchema = vehicleFeaturesList().parse(res);
                return validatedSchema;
            },
        }),
        searchCarriers: builder.query<
            DispatcherSearchResponse,
            { body: SearchCarrierRequest; aborter?: () => AbortSignal }
        >({
            providesTags: ['Carriers'],
            query: ({ body, aborter }: { body: SearchCarrierRequest; aborter?: () => AbortSignal }) => {
                const signal = aborter?.();

                return {
                    url: config.api.endpoints.dispatcherSearch,
                    method: 'POST',
                    body,
                    signal,
                };
            },
        }),
    }),
});

export const {
    useGetCarriersQuery,
    useLazyGetCarriersQuery,
    useGetOneCarrierQuery,
    useLazyGetOneCarrierQuery,
    usePostNewCarrierMutation,
    usePutUpdatedCarrierMutation,
    useDeleteUpdatedCarrierMutation,
    useGetCarrierListQuery,
    usePostOnboardingEmailMutation,
    useGetVehicleFeaturesQuery,
    useLazySearchCarriersQuery,
} = carrierApi;
