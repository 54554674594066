import React from 'react';
import { Controller } from 'react-hook-form';
import { FormError, FormField, FormLabel, MultiSelectInput, MultiSelectInputSelectProps } from 'modules/ui';

export type MultiSelectFieldProps = Omit<MultiSelectInputSelectProps, 'selected' | 'onValuesChange'> & {
    name: string;
    label: string;
};

export const MultiSelectInputField = ({ name, label, placeholder, ...props }: MultiSelectFieldProps) => {
    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error } }) => {
                return (
                    <FormField name={name}>
                        <FormLabel htmlFor={name}>{label}</FormLabel>
                        <MultiSelectInput
                            id={name}
                            error={!!error}
                            placeholder={placeholder}
                            {...field}
                            {...props}
                            selected={field.value}
                            onValuesChange={(values) => {
                                field.onChange(values);
                            }}
                            ref={field.ref}
                        />
                        <FormError>{error?.message}</FormError>
                    </FormField>
                );
            }}
        />
    );
};
