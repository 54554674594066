import React from 'react';
import { Controller } from 'react-hook-form';
import { CarrierSearchMultiSwitch, CarrierSearchMultiSwitchProps } from 'modules/CarrierSearchMultiSwitch';
import { FormError, FormField, FormLabel } from 'modules/ui';

//Custom deselectable multiswitch used only in carrier form

export const CarrierSearchMultiSwitchField: React.FC<Omit<CarrierSearchMultiSwitchProps, 'value' | 'onChange'>> = ({
    name,
    label,
    ...props
}) => {
    return (
        <Controller
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <FormField name={name}>
                        <FormLabel htmlFor={name}>{label}</FormLabel>
                        <CarrierSearchMultiSwitch
                            name={name}
                            label={label}
                            {...props}
                            value={value}
                            onChange={(value) => {
                                onChange(value);
                            }}
                        />
                        <FormError>{error?.message}</FormError>
                    </FormField>
                );
            }}
        />
    );
};
