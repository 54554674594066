import React from 'react';
import classNames from 'classnames';

import styles from './Pill.module.scss';

export interface IPillProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'prefix'> {
    children?: React.ReactNode;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    color?: string;
    size?: 'small' | 'medium' | 'large';
}

export const Pill: React.FC<IPillProps> = ({
    children,
    prefix,
    size = 'small',
    suffix,
    color = 'primary',
    style,
    className,
    ...props
}) => {
    return (
        <div
            className={classNames(styles.pill, className, {
                [styles.small]: size === 'small',
                [styles.medium]: size === 'medium',
                [styles.large]: size === 'large',
            })}
            style={{ backgroundColor: color, ...style }}
            {...props}
        >
            {prefix}
            {children}
            {suffix}
        </div>
    );
};
