import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import { type BaseFieldProps } from 'modules/form';
import { Checkbox, type CheckboxProps, FormError, FormField, FormLabel } from 'modules/ui';

import styles from './CheckboxField.module.scss';

export type CheckboxFieldProps = BaseFieldProps &
    CheckboxProps & {
        outlined?: boolean;
        onClick?: () => void;
        borderLess?: boolean; // optional boolean
    };

export const CheckboxField = ({ name, label, onClick, disabled, borderLess = false, ...props }: CheckboxFieldProps) => {
    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error } }) => (
                <FormField name={name}>
                    <div
                        className={classNames(styles.field, {
                            [styles['field-error']]: error,
                            [styles['field-disabled']]: disabled,
                        })}
                        style={borderLess ? { border: 'none' } : {}}
                    >
                        <Checkbox {...field} id={name} value={undefined} checked={field.value} {...props} />
                        <FormLabel htmlFor={name}>{label}</FormLabel>
                        <div
                            onClick={() => {
                                if (disabled) return;
                                onClick && onClick();
                                field.onChange(!field.value);
                            }}
                            className={styles['invisible-button']}
                        />
                    </div>
                    <FormError>{error?.message}</FormError>
                </FormField>
            )}
        />
    );
};
