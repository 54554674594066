import React from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { motion } from 'framer-motion';
import { FormGrid, TextAreaField } from 'modules/form';
import { MultiSelectInputField } from 'modules/form/components/MultiSelectInputField';

import { Button } from '../Button';
import { Typography } from '../Typography';
import { AttachmentsList } from './components/AttachmentsList';
import { mailModalFormValidationSchema, TMailModalFormSchema, TMailModalTransformedValues } from './MailModal.types';

import styles from './MailModal.module.scss';

export interface IMailModalProps {
    title: string;
    infoSlot?: React.ReactNode;
    initialValues?: TMailModalFormSchema;
    isSubmitting?: boolean;
    maxRecipients?: number;

    onOpenChange: (state: boolean) => void;
    onSubmit?: (data: TMailModalTransformedValues) => void;
}

export const MailModal: React.FC<IMailModalProps> = ({
    initialValues,
    onOpenChange,
    title,
    infoSlot,
    onSubmit,
    isSubmitting,
    maxRecipients,
}) => {
    const { t } = useTranslation();

    const formMethods = useForm<TMailModalFormSchema>({
        defaultValues: {
            attachments: [],
            recipients: [],
        },
        values: initialValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(mailModalFormValidationSchema(t, { maxRecipients: maxRecipients })),
    });
    const { isValid } = useFormState({ control: formMethods.control });
    const handleSubmit = (data: TMailModalFormSchema) => {
        onSubmit?.({
            attachments: data.attachments.map((attachment) => String(attachment.value)),
            message: data.message,
            recipients: data.recipients.map((recipient) => recipient.value),
        });
    };

    return createPortal(
        <FormProvider {...formMethods}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={styles.modal}
                onClick={() => onOpenChange(false)}
            >
                <motion.div
                    initial={{ y: 10 }}
                    animate={{ y: 0 }}
                    exit={{ y: 10 }}
                    className={styles.container}
                    onClick={(e) => e.stopPropagation()}
                >
                    <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                        <div className={styles.title}>
                            <Typography variant="h4" className={styles.type}>
                                {title}
                            </Typography>
                        </div>
                        <FormGrid columns={1}>
                            <MultiSelectInputField name="recipients" label={t('common.recipient')} options={[]} />
                            <AttachmentsList />
                            <TextAreaField name="message" rows={8} label={t('common.message')} />
                            {infoSlot}
                            <div className={styles.buttons}>
                                <Button
                                    className={styles.btn}
                                    type="button"
                                    color="green"
                                    variant="secondary"
                                    onClick={() => onOpenChange(false)}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    className={styles.btn}
                                    color="red"
                                    variant="primary"
                                    disabled={isSubmitting || !isValid}
                                    isLoading={isSubmitting}
                                >
                                    {t('form.send')}
                                </Button>
                            </div>
                        </FormGrid>
                    </form>
                </motion.div>
            </motion.div>
        </FormProvider>,
        document.body,
    );
};
