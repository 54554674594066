import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './DispatcherSearchMapLegend.module.scss';

type DispatcherSearchMapLegendProps = {
    commissionsCount: number | undefined;
    dispatchersCount: number | undefined;
    hqsCount: number | undefined;
};

export const DispatcherSearchMapLegend: React.FC<DispatcherSearchMapLegendProps> = ({
    commissionsCount,
    dispatchersCount,
    hqsCount,
}) => {
    const { t } = useTranslation();

    const legendItems = [
        {
            count: commissionsCount,
            markerClass: styles.legendCommsMarker,
            translationKey: 'commissions.form.carrier.searchCarrier.form.searchType.commission',
        },
        {
            count: dispatchersCount,
            markerClass: styles.legendDisMarker,
            translationKey: 'commissions.form.carrier.searchCarrier.form.searchType.dispatcher',
        },
        {
            count: hqsCount,
            markerClass: styles.legendHqMarker,
            translationKey: 'commissions.form.carrier.searchCarrier.form.searchType.hq',
        },
    ];

    return (
        <div className={styles.legendWrapper}>
            <div className={styles.legend}>
                {legendItems.map((item, index) => (
                    <div key={index} className={styles.legendItem}>
                        <span className={item.markerClass}></span>
                        {t(item.translationKey, "")}: {item.count}
                    </div>
                ))}
            </div>
        </div>
    );
};
