import { createContext, ReactNode, useContext, useState } from 'react';

interface MapContextType {
    onLoadingRadiusMapChange: number;
    setOnLoadingRadiusMapChange: (value: number) => void;
    onDischargeRadiusMapChange: number;
    setOnDischargeRadiusMapChange: (value: number) => void;
    isDispatcherFiltrationToggled: boolean;
    setIsDispatcherFiltrationToggled: (value: boolean) => void;
    dispatcherSelectedFromMap?: number;
    setDispatcherSelectedFromMap: (value?: number) => void;
    //state of a loading spinner for search map
    isMapLoading: boolean;
    setIsMapLoading: (value: boolean) => void;
    //state of a loading icon for tables
    isTableLoading: boolean;
    setIsTableLoading: (value: boolean) => void;
}

const defaultMapContext: MapContextType = {
    onLoadingRadiusMapChange: 0,
    setOnLoadingRadiusMapChange: () => undefined,
    onDischargeRadiusMapChange: 0,
    setOnDischargeRadiusMapChange: () => undefined,
    isDispatcherFiltrationToggled: false,
    setIsDispatcherFiltrationToggled: () => undefined,
    dispatcherSelectedFromMap: undefined,
    setDispatcherSelectedFromMap: () => undefined,
    isMapLoading: false,
    setIsMapLoading: () => undefined,
    isTableLoading: false,
    setIsTableLoading: () => undefined,
};

const DispatcherSearchMapContext = createContext<MapContextType>(defaultMapContext);

export const DispatcherSearchMapProvider = ({ children }: { children: ReactNode }) => {
    const [onLoadingRadiusMapChange, setOnLoadingRadiusMapChange] = useState<number>(0);
    const [onDischargeRadiusMapChange, setOnDischargeRadiusMapChange] = useState<number>(0);
    const [isDispatcherFiltrationToggled, setIsDispatcherFiltrationToggled] = useState<boolean>(false);
    const [dispatcherSelectedFromMap, setDispatcherSelectedFromMap] = useState<number | undefined>();
    const [isMapLoading, setIsMapLoading] = useState<boolean>(false);
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

    return (
        <DispatcherSearchMapContext.Provider
            value={{
                onLoadingRadiusMapChange,
                setOnLoadingRadiusMapChange,
                onDischargeRadiusMapChange,
                setOnDischargeRadiusMapChange,
                isDispatcherFiltrationToggled,
                setIsDispatcherFiltrationToggled,
                dispatcherSelectedFromMap,
                setDispatcherSelectedFromMap,
                isMapLoading,
                setIsMapLoading,
                isTableLoading,
                setIsTableLoading,
            }}
        >
            {children}
        </DispatcherSearchMapContext.Provider>
    );
};

export const useDispatcherSearchMapContext = () => {
    const context = useContext(DispatcherSearchMapContext);

    return context;
};
