import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { config } from 'config';
import { createSelectColumn, getContainsFilterMeta, getDateRangeFilterMeta, Table, useTableState } from 'modules/table';
import { tableSortingFilteringPagination } from 'utils';
import { timestamp } from 'utils/formatTimestamp';

import { HoverPopUp } from '../components/HoverPopUp/HoverPopUp';

import styles from '../DispatcherSearchTables.module.scss';

export type DispatcherSearchTableItem = {
    dispatcher_id: number;
    country: string;
    company: string;
    fullName: string;
    lastCommissionDate: number | null;
    lastCommissionId: number | null;
    lastCommissionNumber: number | null;

    // Data used in a popup which appears when hovering over a last commission table cell
    popUpData: PopUpData | null;
};

export type PopUpData = {
    priceCarrier: number | null;
    customer: string | null;
    loading: {
        date: number | null;
        city: string | null;
        postalCode: string | null;
    };
    discharge: {
        date: number | null;
        city: string | null;
        postalCode: string | null;
    };
};

const columnHelper = createColumnHelper<DispatcherSearchTableItem>();

export const useDispatcherSearchTable = (
    data: DispatcherSearchTableItem[],
    tableName: string,
    selectedDispatchers?: DispatcherSearchTableItem[],
    disabledIds?: number[],
    onRowSelect?: (rows: Row<DispatcherSearchTableItem>[]) => void,
    onRowDeselect?: (rows: Row<DispatcherSearchTableItem>[]) => void,
) => {
    const { t } = useTranslation();

    const { tableProps, queryParams, paginationParams } = useTableState<DispatcherSearchTableItem>({
        rowIdKey: 'dispatcher_id',
        getCellStyles: ({ column, row }) => {
            const cellStyles: string[] = [];

            if (['lastCommissionNumber'].includes(column.id)) {
                cellStyles.push(Table.CellTextFormat.SemiBold);
                cellStyles.push(styles.popupSuitable);
            }
            if (column.id === 'lastCommissionNumber') cellStyles.push(Table.CellTextColor.Primary);

            if (disabledIds?.includes(row.original.dispatcher_id)) {
                cellStyles.push(styles.disabled);
            }

            return cellStyles;
        },
        getIsRowSelectionDisabled: (row) => disabledIds?.includes(row.original.dispatcher_id) || false,
        tableName,
    });

    const tableData = useMemo(() => {
        if (!data || data.length === 0) {
            return { filtered: [], sorted: [], paginated: [], changedData: [] };
        }

        let changedData = data;

        selectedDispatchers &&
            (changedData = changedData.filter(
                ({ dispatcher_id }) =>
                    !selectedDispatchers.find((dispatcher) => dispatcher?.dispatcher_id === dispatcher_id),
            ));

        const { filtered, result, sorted } = tableSortingFilteringPagination<DispatcherSearchTableItem>({
            data: changedData,
            paginationParams,
            queryParams,
            treatAsDate: ['lastCommissionDate'],
        });

        return { filtered, sorted, paginated: result, changedData };
    }, [data, queryParams, paginationParams, selectedDispatchers]);

    const columns = useMemo(
        () => [
            columnHelper.accessor('company', {
                header: t('commissions.form.carrier.searchCarrier.table.company'),
                cell: (props) => {
                    const value = props.getValue() || '';
                    return value.length > 37 ? `${value.substring(0, 37)}...` : value;
                },
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('fullName', {
                header: t('commissions.form.carrier.searchCarrier.table.fullName'),
                meta: {
                    ...getContainsFilterMeta(),
                },
                size: 140,
            }),
            columnHelper.accessor('country', {
                header: t('commissions.form.carrier.searchCarrier.table.country'),
                meta: {
                    ...getContainsFilterMeta(),
                    align: 'center',
                },
                size: 70,
            }),
            columnHelper.accessor('lastCommissionDate', {
                header: t('commissions.form.carrier.searchCarrier.table.lastCooperationDate'),
                cell: (props) => {
                    const value = props.getValue();
                    return value ? timestamp(value)?.format('DD. MM. YYYY') : <></>;
                },
                meta: {
                    align: 'center',
                    ...getDateRangeFilterMeta(),
                },
                size: 140,
            }),

            columnHelper.accessor('lastCommissionNumber', {
                header: t('commissions.form.carrier.searchCarrier.table.lastCommission'),
                cell: (props) => {
                    const lastCommissionId = props.row.original.lastCommissionId;
                    const value = props.getValue();

                    return (
                        <>
                            <div className={styles.popupWrapper}>
                                <div className={styles.popup}>
                                    <HoverPopUp popUpData={props.row.original.popUpData} />
                                </div>
                                <Link
                                    to={`${config.routes.commissions.detail.replace(':id', String(lastCommissionId))}`}
                                >
                                    {value !== null ? value : ''}
                                </Link>
                            </div>
                        </>
                    );
                },
                meta: {
                    align: 'center',
                },
                size: 100,
            }),

            createSelectColumn(
                columnHelper,
                undefined,
                undefined,
                data.flatMap((item) => {
                    if ('dispatcher_id' in item) {
                        return item.dispatcher_id;
                    }
                    return (item as DispatcherSearchTableItem).dispatcher_id;
                }),
                onRowSelect,
                onRowDeselect,
                50,
            ),
        ],
        [t, data],
    );

    return {
        tableProps,
        columns,
        paginatedData: tableData.paginated,
        filteredData: tableData.filtered,
        sortedData: tableData.sorted,
        changedData: tableData.changedData,
    };
};
