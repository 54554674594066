import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowRightIcon, TrashIcon } from 'assets/icons';
import { config } from 'config';
import { useDocumentTitle } from 'core/application/hooks';
import { useAuth } from 'core/auth';
import { AuthRole } from 'core/auth/types';
import { useAppDispatch } from 'hooks';
import { commissionsApi } from 'modules/commissions';
import { PageContent } from 'modules/layout';
import { Subheader } from 'modules/layout/components/Subheader';
import {
    useCloseEnquiryMutation,
    useDeleteEnquiryMutation,
    useDeleteOfferMutation,
    useGetOneEnquiryQuery,
    usePostOfferMutation,
    usePutEnquiryMutation,
    usePutOfferMutation,
    useReopenEnquiryMutation,
} from 'modules/offers/services';
import {
    AdminOfferStatusValue,
    DispatcherOfferStatusValue,
    EnquirySchemaComplete,
    OffersDetailAdminFormSchema,
    OffersDetailDispatcherFormSchema,
    PostOfferRequest,
    UpdateEnquiryRequestSchema,
} from 'modules/offers/types';
import { buildUpdateEnquiryRequest } from 'modules/offers/utils';
import { BackButton, Button, Modal, Spinner, Typography } from 'modules/ui';
import { getFormattedDate } from 'utils';

import { OfferStatusAdmin } from '../OfferStatusAdmin';
import { OfferStatusDispatcher } from '../OfferStatusDispatcher';
import { OffersDetailAdminForm } from './OffersDetailAdminForm/OffersDetailAdminForm';
import { OffersDetailDispatcherForm } from './OffersDetailDispatcherForm';

import styles from './OffersDetailPage.module.scss';

export type OffersDetailPageProps = {
    noPageHeader?: boolean; //we dont want the header when rendering OffersDetailPage from a commission tab
    idFromCommissionPage?: number | null;
};

export const OffersDetailPage: React.FC<OffersDetailPageProps> = ({ noPageHeader, idFromCommissionPage }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.offers'));

    const params = useParams<{ id: string }>();
    const id = idFromCommissionPage ? idFromCommissionPage : Number(params.id);

    const { user } = useAuth();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // STATE HOOKS
    const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] = useState(false);
    const [showReopenConfirmationModal, setShowReopenConfirmationModal] = useState(false);
    const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);

    // FETCH HOOKS
    const [updateDispatcherOffer] = usePutOfferMutation();
    const [updateEnquiry, { isLoading: isSubmittingFormLoading }] = usePutEnquiryMutation();
    const [closeEnquiry] = useCloseEnquiryMutation();
    const [reopenEnquiry, { isLoading: isSubmittingReopening }] = useReopenEnquiryMutation();
    const [deleteEnquiry, { isLoading: isSubmittingDeleting }] = useDeleteEnquiryMutation();
    const [postOffer] = usePostOfferMutation();
    const [deleteOffer] = useDeleteOfferMutation();
    const { data: fetchedData, isError, isLoading, isFetching } = useGetOneEnquiryQuery({ id: Number(id) });

    const isAdmin = user?.role === AuthRole.USER;
    const isClosed = fetchedData?.state === AdminOfferStatusValue.Closed;

    const prepareAdminData = async (data: Partial<OffersDetailAdminFormSchema>, selectedDispatchers?: number[]) => {
        if (!fetchedData) return;
        try {
            // find notes with updated note
            const updatedOffers =
                data?.offers?.filter(({ offer_id, note }) => {
                    // get the old offer's note
                    const oldNote = fetchedData.offers.find(
                        ({ offer_id: oldOffer_id }) => oldOffer_id === offer_id,
                    )?.note;
                    return note !== oldNote;
                }) || [];

            // in parallel, update the notes
            await Promise.all(
                updatedOffers.map((offer) =>
                    updateDispatcherOffer({
                        data: {
                            note: offer.note || null,
                        },
                        id: offer.offer_id,
                    }).unwrap(),
                ) || [],
            );

            const requestBody: Partial<UpdateEnquiryRequestSchema> = buildUpdateEnquiryRequest({
                data,
                selectedDispatchers,
                oldData: fetchedData,
            });
            await updateEnquiry({ data: requestBody, id: Number(id) }).unwrap();

            toast.success(t('offers.updated'));
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    const onChooseWinner = async (offerId: number) => {
        try {
            await closeEnquiry({ offerId, enquiryId: Number(id) }).unwrap();
            invalidateAssociatedCommission();
            toast.success(t('offers.chooseWinnerModal.success'));
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    const onRemoveEnquiry = async () => {
        try {
            await deleteEnquiry({
                id: Number(id),
            }).unwrap();

            invalidateAssociatedCommission();
            navigate(config.routes.offers.list);
            toast.success(t('offers.modalRemove.success'));
        } catch (error) {
            toast.error(t('form.saveError'));
        } finally {
            setShowRemoveConfirmationModal(false);
        }
    };

    const onReopenEnquiry = async (successMessage: string) => {
        try {
            await reopenEnquiry({ id: Number(id) }).unwrap();
            invalidateAssociatedCommission();
            toast.success(successMessage);
        } catch (error) {
            toast.error(t('form.saveError'));
        } finally {
            setShowReopenConfirmationModal(false);
        }
    };

    const prepareDispatcherData = async (data: OffersDetailDispatcherFormSchema) => {
        try {
            if (!fetchedData || !user?.dispatcher_id) return;
            const requestBody: PostOfferRequest = {
                enquiryId: Number(id),
                dispatcherId: user.dispatcher_id,
                response: true,
                price: data.suggestedPrice,
                currency: data.suggestedCurrency,
            };
            await postOffer(requestBody).unwrap();
            toast.success(t('offers.updated'));
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    const onCancelOffer = async () => {
        try {
            if (!fetchedData?.offers[0].offer_id) return;
            await deleteOffer({
                id: fetchedData.offers[0].offer_id,
                enquiryId: Number(id),
            }).unwrap();

            toast.success(t('offers.modalCancel.success'));
        } catch (error) {
            toast.error(t('form.saveError'));
        } finally {
            setShowCancelConfirmationModal(false);
        }
    };

    const invalidateAssociatedCommission = () => {
        if (!fetchedData?.commission_id) return;
        dispatch(
            commissionsApi.util.invalidateTags([
                'Commissions',
                { type: 'CommissionItem', id: fetchedData.commission_id },
            ]),
        );
    };

    if (isError)
        return (
            <div className={styles.notExist}>
                <Typography variant="h2">{t('offers.notFound')}</Typography>
            </div>
        );
    if (isLoading || isFetching || !id || !fetchedData) return <Spinner fullScreen />;

    return (
        <PageContent
            subheader={
                !noPageHeader ? (
                    <Subheader
                        title={t('offers.form.title', { id: fetchedData.number, context: user?.role })}
                        startSlot={<BackButton />}
                        titleSlot={
                            isAdmin ? (
                                <Link
                                    to={`${config.routes.commissions.list}/${fetchedData.commission_id}`}
                                    className={styles.commissionLink}
                                >
                                    <Typography variant="p" color="primary" fontWeight="semibold">
                                        {t('commissions.form.title')} {fetchedData.commission.number}
                                    </Typography>
                                    <ArrowRightIcon />
                                </Link>
                            ) : undefined
                        }
                        endSlot={
                            <div className={styles.actionButtons}>
                                {isAdmin ? (
                                    <>
                                        <Button
                                            onClick={() => setShowRemoveConfirmationModal(true)}
                                            isLoading={isSubmittingDeleting}
                                            disabled={isSubmittingDeleting}
                                            className={styles.deleteBtn}
                                            variant="primary"
                                            danger
                                        >
                                            <TrashIcon />
                                        </Button>{' '}
                                        {isClosed && (
                                            <Button
                                                onClick={() => setShowReopenConfirmationModal(true)}
                                                isLoading={isSubmittingReopening}
                                                disabled={isSubmittingReopening}
                                                variant="secondary"
                                            >
                                                {t('offers.reopen')}
                                            </Button>
                                        )}
                                        <Button
                                            isLoading={isSubmittingFormLoading}
                                            disabled={isSubmittingFormLoading}
                                            form="offer-detail-form"
                                            type="submit"
                                        >
                                            {t('offers.form.save')}
                                        </Button>
                                        <OfferStatusAdmin value={fetchedData.state as AdminOfferStatusValue} />
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="p" fontWeight="medium" color="secondary">
                                            {t('offers.offerCard.posted')}{' '}
                                            {getFormattedDate(Number(fetchedData.tsAdded) * 1000)}
                                        </Typography>
                                        {fetchedData.state === DispatcherOfferStatusValue.Responded && (
                                            <Button
                                                onClick={() => setShowCancelConfirmationModal(true)}
                                                variant="secondary"
                                                danger
                                            >
                                                {t('offers.cancel')}
                                            </Button>
                                        )}
                                        <OfferStatusDispatcher
                                            value={fetchedData.state as DispatcherOfferStatusValue}
                                        />
                                    </>
                                )}
                            </div>
                        }
                    />
                ) : null
            }
        >
            {/* the form's interface is different by role of current authenticated user */}
            {isAdmin ? (
                <OffersDetailAdminForm
                    fetchedData={fetchedData as EnquirySchemaComplete & { state: AdminOfferStatusValue }}
                    reopenEnquiry={() => onReopenEnquiry(t('offers.modalRemoveWinner.success'))}
                    chooseWinner={onChooseWinner}
                    prepareData={prepareAdminData}
                />
            ) : (
                <OffersDetailDispatcherForm
                    fetchedData={fetchedData as EnquirySchemaComplete & { state: DispatcherOfferStatusValue }}
                    prepareData={prepareDispatcherData}
                />
            )}
            {showRemoveConfirmationModal && (
                <Modal
                    onClick={() => setShowRemoveConfirmationModal(false)}
                    label={t('offers.modalRemove.label')}
                    description={t('offers.modalRemove.description')}
                    cancelComponent={
                        <Button
                            className={styles.modalBtn}
                            variant="secondary"
                            onClick={() => setShowRemoveConfirmationModal(false)}
                        >
                            {t('offers.modalRemove.cancel')}
                        </Button>
                    }
                    approveComponent={
                        <Button className={styles.modalBtn} variant="primary" danger onClick={onRemoveEnquiry}>
                            {t('offers.modalRemove.approve')}
                        </Button>
                    }
                />
            )}
            {showReopenConfirmationModal && (
                <Modal
                    onClick={() => setShowReopenConfirmationModal(false)}
                    label={t('offers.modalReopen.label')}
                    description={t('offers.modalReopen.description')}
                    cancelComponent={
                        <Button
                            className={styles.modalBtn}
                            type="button"
                            variant="secondary"
                            onClick={() => setShowReopenConfirmationModal(false)}
                        >
                            {t('offers.modalReopen.cancel')}
                        </Button>
                    }
                    approveComponent={
                        <Button
                            className={styles.modalBtn}
                            variant="primary"
                            onClick={() => onReopenEnquiry(t('offers.modalReopen.success'))}
                        >
                            {t('offers.modalReopen.approve')}
                        </Button>
                    }
                />
            )}
            {showCancelConfirmationModal && (
                <Modal
                    onClick={() => setShowCancelConfirmationModal(false)}
                    label={t('offers.modalCancel.label')}
                    description={t('offers.modalCancel.description')}
                    cancelComponent={
                        <Button
                            className={styles.modalBtn}
                            type="button"
                            variant="secondary"
                            onClick={() => setShowCancelConfirmationModal(false)}
                        >
                            {t('offers.modalCancel.cancel')}
                        </Button>
                    }
                    approveComponent={
                        <Button className={styles.modalBtn} variant="primary" danger onClick={onCancelOffer}>
                            {t('offers.modalCancel.approve')}
                        </Button>
                    }
                />
            )}
        </PageContent>
    );
};
