import { useEffect, useRef, useState } from 'react';
import { Container } from 'modules/ui/components/Container';

import styles from './PageContent.module.scss';

export type PageContentProps = {
    fullWidth?: boolean;
    fullWidthHeader?: boolean;
    subheader?: React.ReactNode;
    children: React.ReactNode;
};

export const PageContent: React.FC<PageContentProps> = ({ children, subheader, fullWidth, fullWidthHeader }) => {
    const [contentWidth, setContentWidth] = useState(1400);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setContentWidth(ref.current?.offsetWidth || 1400);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);

    const containerOverflowTheScreen = document.body.offsetWidth < contentWidth;

    return (
        <>
            {subheader && (
                <div className={styles.subheaderWrapper}>
                    <div
                        className={styles.content}
                        style={{ width: fullWidth || fullWidthHeader ? '100%' : `${contentWidth}px` }}
                    >
                        {subheader}
                    </div>
                </div>
            )}
            {subheader && <div className={styles['artificial-gap']} />}
            <main className={`${styles.main} ${containerOverflowTheScreen ? styles['overflow'] : ''} `}>
                <Container fullWidth={fullWidth}>
                    <div className={styles.mainContentWrapper} ref={ref}>
                        {children}
                    </div>
                </Container>
            </main>
        </>
    );
};
