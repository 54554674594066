import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { UploadIcon } from 'assets/icons';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Button, Typography } from 'modules/ui';
import { EFileSizeUnit } from 'types';
import { formatFileSize, getFileSizeInBytes } from 'utils/fileSize';

import styles from './AttachmentsUploadDropZone.module.scss';

export interface IAttachmentsUploadDropZoneProps {
    multiple?: boolean;
    maxFiles?: number;
    onDrop: (files: File[]) => void;
    filesCount: number;
    maxFileSize?: number;
    visual?: 'area' | 'section';
}

const DROP_ERROR_SHAKE_INTENSITY = 5;

export const AttachmentsUploadDropZone: React.FC<IAttachmentsUploadDropZoneProps> = ({
    onDrop,
    multiple,
    maxFiles,
    filesCount = 0,
    visual = 'area',
    maxFileSize = getFileSizeInBytes(5, EFileSizeUnit.MB),
}) => {
    const [isOver, setIsOver] = useState(false);
    const [dropError, setDropError] = useState(false);
    const { t } = useTranslation();

    const { getRootProps, open, getInputProps } = useDropzone({
        onDragEnter: () => setIsOver(true),
        onDragLeave: () => setIsOver(false),
        onDropRejected: () => {
            setIsOver(false);
            setDropError(true);
        },
        onDropAccepted: (files) => {
            onDrop(files);
            setIsOver(false);
        },
        accept: {
            'application/pdf': ['.pdf'],
        },
        multiple: multiple,
        noClick: false,
        maxSize: maxFileSize,
    });

    if (maxFiles && filesCount >= maxFiles) {
        return null;
    }

    return (
        <div
            className={classNames(styles.fileDropZone, {
                [styles.area]: visual === 'area',
                [styles.section]: visual === 'section',
            })}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            {isOver ? (
                <UploadIcon className={styles.uploadIcon} />
            ) : (
                <motion.div
                    animate={{
                        x: dropError
                            ? [
                                  '0%',
                                  `-${DROP_ERROR_SHAKE_INTENSITY}%`,
                                  `${DROP_ERROR_SHAKE_INTENSITY}%`,
                                  `-${DROP_ERROR_SHAKE_INTENSITY}%`,
                                  `${DROP_ERROR_SHAKE_INTENSITY}%`,
                                  '0%',
                              ]
                            : 0,
                    }}
                    onAnimationComplete={() => setDropError(false)}
                    transition={{ ease: 'easeInOut' }}
                    className={styles.wrapper}
                >
                    <Typography variant="p" fontWeight="semibold" color="secondary" style={{ marginBottom: 8 }}>
                        {t('attachmentsUpload.dragZone.hint')}
                    </Typography>
                    <Typography variant="p" fontWeight="normal" color="secondary" style={{ marginBottom: 20 }}>
                        {t('attachmentsUpload.dragZone.maxFileSize', { fileSize: formatFileSize(maxFileSize) })}
                    </Typography>
                    <Button onClick={open}>{t('attachmentsUpload.uploadFile')}</Button>
                </motion.div>
            )}
        </div>
    );
};
