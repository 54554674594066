// the import order must be strictly followed
// eslint-disable-next-line  simple-import-sort/imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DispatcherSearchTableItem } from 'modules/form/components/DispatcherSearchTables/hooks/useDispatcherSearchTable';

interface OfferState {
    // dispatchers we get from contactedDispatchers property of enquiry
    existedDispatchers: DispatcherSearchTableItem[];
    // all checked dispatchers
    selectedDispatchers: DispatcherSearchTableItem[];
    // new fetched dispatchers
    fetchedDispatchers: DispatcherSearchTableItem[] | null;
}

const initialState: OfferState = {
    existedDispatchers: [],
    selectedDispatchers: [],
    fetchedDispatchers: null,
};

export const offerSlice = createSlice({
    name: 'offer',
    initialState,
    reducers: {
        setExistedDispatchers: (state, { payload }: PayloadAction<DispatcherSearchTableItem[]>) => {
            state.existedDispatchers = payload;
        },
        setSelectedDispatchers: (state, { payload }: PayloadAction<DispatcherSearchTableItem[]>) => {
            state.selectedDispatchers = payload;
        },
        setFetchedDispatchers: (state, { payload }: PayloadAction<DispatcherSearchTableItem[]>) => {
            state.fetchedDispatchers = payload;
        },
    },
});

export const { setExistedDispatchers, setSelectedDispatchers, setFetchedDispatchers } = offerSlice.actions;
