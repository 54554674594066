import i18n from 'core/translation/i18next/i18next';
import { T } from 'core/translation/types';
import { carrierNewSchema } from 'modules/carriers';
import { actualCommissionSchema } from 'modules/commissions';
import { Countries, Currency } from 'modules/common';
import { getOneDispatcherResponseSchema } from 'modules/onboarding/types';
import { z } from 'zod';

export enum AdminOfferStatusValue {
    Waiting = 'WAITING',
    Opened = 'OPENED',
    Closed = 'CLOSED',
}

export enum DispatcherOfferStatusValue {
    New = 'NEW',
    Responded = 'RESPONDED',
    Won = 'WON',
    Closed = 'CLOSED',
}

export type PostOfferRequest = {
    enquiryId: number;
    dispatcherId: number;
    response: boolean;
    price: number | null;
    currency: keyof typeof Currency;
};

// it is enquiry actually
export type IOfferItemAdmin = {
    id: number;
    number: number;
    commission_id: number;
    commissionNumber: number | null;
    city: string;
    dispatchers: string[];
    offerStatusValue: AdminOfferStatusValue;
    offers: OfferSchema[];
    exchangeRate: number;
};

export type IOfferItemDispatcher = {
    id: number;
    number: number;
    offerDate: number;
    totalLoadingDischargeCount: number;
    totalLoadingMeters: number;
    totalWeight: number;
    offerStatusValue: DispatcherOfferStatusValue;
    firstLoading: {
        city: string;
        countryCode: keyof typeof Countries;
        date: number;
        dateTo: number;
        time: string;
    };
    lastDischarge: {
        city: string;
        countryCode: keyof typeof Countries;
        date: number;
        dateTo: number;
        time: string;
    };
};

export type ITransformedOfferItem = IOfferItemAdmin | IOfferItemDispatcher;

const offerSchema = () =>
    z.object({
        offer_id: z.number(),
        enquiry_id: z.number(),
        dispatcher_id: z.number(),
        dispatcher: getOneDispatcherResponseSchema(i18n.t).extend({
            carrier: carrierNewSchema(i18n.t),
        }),
        response: z.boolean(),
        preferenced: z.boolean(),
        price: z.string(),
        currency: z.nativeEnum(Currency),
        note: z.string().nullable(),
        tsAdded: z.string(),
    });

// contactedDispatchers
const enquiryDispatcherSchema = () =>
    z.object({
        enquirydispatcher_id: z.number(),
        enquiry_id: z.number(),
        dispatcher_id: z.number(),
        dispatcher: getOneDispatcherResponseSchema(i18n.t).extend({
            carrier: carrierNewSchema(i18n.t),
            _count: z.object({ dispatchervehicle: z.number() }),
        }),
    });

export const enquirySchema = () =>
    z.object({
        enquiry_id: z.number(),
        number: z.number(),
        note: z.string().nullable(),
        state: z.nativeEnum(AdminOfferStatusValue).or(z.nativeEnum(DispatcherOfferStatusValue)),
        parameters: z.object({
            minWidth: z.number(),
            minHeight: z.number(),
            minLength: z.number(),
            minWeight: z.number(),
            vehicleTypes: z.array(z.number().min(1).max(4)),
            requiredFeaturesSome: z.array(z.number().min(1).max(14)),
            requiredFeatures: z.array(z.number().min(1).max(14)),
        }),
        commission_id: z.number(),
        tsAdded: z.string(),
    });

export const enquirySchemaComplete = (t: T) =>
    enquirySchema().extend({
        offers: z.array(offerSchema()),
        commission: actualCommissionSchema(t),
        contactedDispatchers: z.array(enquiryDispatcherSchema()),
    });

export const offersDetailAdminFormSchema = (t: T) =>
    z.object({
        parameters: z.object({
            vehicleTypes: z.array(z.number().min(1).max(4)),
            requiredFeaturesSome: z.array(z.number().min(1).max(14)),
            requiredFeatures: z.array(z.number().min(1).max(14)),
            minLength: z.number(),
            minWidth: z.number(),
            minWeight: z.number(),
            minHeight: z.number(),
        }),
        offers: z.array(offerSchema().pick({
            offer_id: true,
            note: true,
        })),
        note: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
    });

export const offersDetailDispatcherFormSchema = (t: T) =>
    z.object({
        totalWeight: z.number(),
        totalLoadingMeters: z.number(),
        suggestedPrice: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        suggestedCurrency: z.nativeEnum(Currency),
    });

export const updateEnquiryRequestSchema = () =>
    z.object({
        state: z.nativeEnum(AdminOfferStatusValue).or(z.nativeEnum(DispatcherOfferStatusValue)),
        parameters: z.object({
            minLength: z.number(),
            minWidth: z.number(),
            minWeight: z.number(),
            minHeight: z.number(),
            vehicleTypes: z.array(z.number().min(1).max(4)),
            requiredFeaturesSome: z.array(z.number().min(1).max(14)),
            requiredFeatures: z.array(z.number().min(1).max(14)),
        }),
        note: z.string().nullable(),
        contactedDispatchers: z.object({ add: z.array(z.number()), remove: z.array(z.number()) }),
    });

export const sendEnquiryEmailRequestSchema = () =>
    z.object({
        enquiryId: z.number(),
        dispatcherIds: z.array(z.number()),
        dispatcher: z.object({
            name: z.string(),
            surname: z.string(),
            phone: z.string(),
            email: z.string(),
        }),
        parameters: z.object({
            minLength: z.number(),
            minWidth: z.number(),
            minWeight: z.number(),
            minHeight: z.number(),
            requiredFeatures: z.array(z.number()),
            requiredFeaturesSome: z.array(z.number()),
            vehicleTypes: z.array(z.number()),
        }),
        body: z.object({
            cs: z.string(),
            en: z.string(),
            de: z.string(),
        }),
        subject: z.string(),
    });

export type OfferSchema = z.infer<ReturnType<typeof offerSchema>>;
export type EnquirySchema = z.infer<ReturnType<typeof enquirySchema>>;
export type EnquirySchemaComplete = z.infer<ReturnType<typeof enquirySchemaComplete>>;
export type EnquiryDispatcherSchema = z.infer<ReturnType<typeof enquiryDispatcherSchema>>;
export type OffersDetailAdminFormSchema = z.infer<ReturnType<typeof offersDetailAdminFormSchema>>;
export type OffersDetailDispatcherFormSchema = z.infer<ReturnType<typeof offersDetailDispatcherFormSchema>>;
export type UpdateEnquiryRequestSchema = z.infer<ReturnType<typeof updateEnquiryRequestSchema>>;
export type SendEnquiryEmailRequestSchema = z.infer<ReturnType<typeof sendEnquiryEmailRequestSchema>>;
