import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from 'config';
import {
    AttachmentDetailFormContextProvider,
    useAttachmentDetailForm,
} from 'modules/attachments/contexts/attachmentDetailForm';
import { useGetAttachmentQuery } from 'modules/attachments/services';
import { PageContent, Subheader } from 'modules/layout';
import { ExceptionPage } from 'modules/layout/components/ExceptionPage';
import { BackButton, Button, Spinner } from 'modules/ui';

import { AttachmentSentStatusPill } from '../AttachmentSentStatusPill';
import { DeleteAttachmentButton } from '../DeleteAttachmentButton';
import { DownloadAttachmentFileButton } from '../DownloadAttachmentFileButton';
import { EditAttachmentFormWrapper } from '../EditAttachmentFormWrapper';
import { MailAttachmentFileButton } from '../MailAttachmentFileButton';

import styles from './EditAttachmentPage.module.scss';

export interface IEditAttachmentPageProps {}

const EditAttachmentPageInner: React.FC<IEditAttachmentPageProps> = () => {
    const { t } = useTranslation();
    const { id: attachmentId } = useParams();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        data: attachment,
        isLoading,
        isError,
    } = useGetAttachmentQuery(
        {
            attachmentId: Number(attachmentId),
            includeUploadedBy: true,
            includeCommission: true,
            includeInvoice: true,
        },
        { skip: !attachmentId },
    );

    const { triggerSubmit, isValid } = useAttachmentDetailForm();

    if (!attachmentId) {
        navigate(config.routes.commissions.list);
    }

    if (isLoading) {
        return (
            <div className={styles.loadingPage}>
                <Spinner fullScreen className={styles.loader} />
            </div>
        );
    }

    if (isError) {
        return <ExceptionPage title={t('attachments.get.error')} />;
    }

    return (
        <PageContent
            fullWidthHeader
            fullWidth
            subheader={
                <Subheader
                    startSlot={<BackButton />}
                    titleSlot={
                        <div style={{ marginLeft: 8 }}>
                            <AttachmentSentStatusPill sent={!!attachment?.sent} />
                        </div>
                    }
                    endSlot={
                        <div className={styles.actionButtonsWrapper}>
                            <MailAttachmentFileButton attachmentId={Number(attachmentId)} />
                            <DownloadAttachmentFileButton attachmentId={Number(attachmentId)} />
                            <DeleteAttachmentButton attachmentId={Number(attachmentId)} />
                            <Button disabled={!isValid} type="button" onClick={triggerSubmit} isLoading={isSubmitting}>
                                {t('form.save')}
                            </Button>
                        </div>
                    }
                    title={attachment?.name || '***'}
                />
            }
        >
            <EditAttachmentFormWrapper
                initialData={attachment}
                onIsSubmittingChange={(state) => setIsSubmitting(state)}
            />
        </PageContent>
    );
};

export const EditAttachmentPage: React.FC<IEditAttachmentPageProps> = () => {
    return (
        <AttachmentDetailFormContextProvider>
            <EditAttachmentPageInner />
        </AttachmentDetailFormContextProvider>
    );
};
