import { forwardRef } from 'react';
import classNames from 'classnames';
import { Typography } from 'modules/ui';

import styles from './FormSection.module.scss';

export const FormSection = forwardRef(
    (
        {
            title,
            children,
            className,
            headerStartSlot,
            headerEndSlot,
            ...props
        }: {
            title?: string;
            children: React.ReactNode;
            headerStartSlot?: React.ReactNode;
            headerEndSlot?: React.ReactNode;
        } & React.ComponentProps<'div'>,
        ref?: React.ForwardedRef<HTMLDivElement>,
    ) => {
        return (
            <div ref={ref} className={classNames(styles.section, className)} {...props}>
                <div className={styles.header}>
                    <p className={styles.title}>
                        <Typography variant="h4">{title}</Typography>
                        {headerStartSlot}
                    </p>
                    {headerEndSlot}
                </div>
                <div className={styles.content}>{children}</div>
            </div>
        );
    },
);
