import React from 'react';
import { CommissionMapIcon, DispatcherMapIcon, HqMapIcon } from 'assets/icons';
import { ReasonType } from 'modules/dispatcherSearch/types';

import styles from './MapPoint.module.scss';

type MapPointProps = {
    icon: ReasonType;
    onClick?: () => void;
};

export const MapPoint: React.FC<MapPointProps> = ({ icon, onClick }) => {
    const iconNameToComponent: { [key in ReasonType]: JSX.Element } = {
        commission: <CommissionMapIcon className="innerIcon" />,
        dispatcher: <DispatcherMapIcon className="innerIcon" />,
        hq: <HqMapIcon className="innerIcon" />,
    };

    return (
        <div className={styles.pointAndToastWrapper} onClick={onClick}>
            <div className={styles.pointWrapper}>
                {iconNameToComponent[icon] || <div style={{ width: '10px', height: '10px', background: 'gold' }} />}
            </div>
        </div>
    );
};
