import classNames from 'classnames';

import styles from './CommissionHeaderSwitchItem.module.scss';

type CommissionHeaderSwitchItemProps = {
    onClick: () => void
    isSelected: boolean;
    children: React.ReactNode
};

export const CommissionHeaderSwitchItem: React.FC<CommissionHeaderSwitchItemProps> = ({ isSelected, children,onClick }) => {
    return (
        <div
        className={classNames(styles.headerItem, {
            [styles.headerItemActive]: isSelected
            })}
        onClick={onClick}
        >
            {children}
        </div>
    );
};
