import React, { createContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { ConfigurationIcon } from 'assets/icons';
import { config } from 'config';
import { TFunction } from 'i18next';
import { getContainsFilterMeta, getDateRangeFilterMeta, useTableState } from 'modules/table';
import { Button, Typography } from 'modules/ui';
import { tableSortingFilteringPagination } from 'utils';
import { timestamp } from 'utils/formatTimestamp';

import { AttachmentSentStatusPill } from '../components/AttachmentSentStatusPill';
import { DeleteAttachmentButton } from '../components/DeleteAttachmentButton';
import { DownloadAttachmentFileButton } from '../components/DownloadAttachmentFileButton';
import { useListAttachmentsQuery } from '../services';
import { EAttachmentType, TAttachment, TTransformedAttachment } from '../types';

import styles from 'modules/attachments/components/AttachmentsTable/AttachmentsTable.module.scss';

export const ATTACHMENTS_TABLE_COLUMNS = ['name', 'commission', 'uploadedBy', 'type', 'tsAdded', 'actions'] as const;

export interface IAttachmentsTableContext {
    tableState: ReturnType<typeof useTableState<TAttachment>>;
    columns: ReturnType<typeof attachmentsTableColumnsDefinitions>;
    commissionId: number | null;
    invoiceId: number | null;
    attachments: TAttachment[];
    filteredAttachments: TTransformedAttachment[];
    isLoadingAttachments: boolean;

    setCommissionId: (id: number) => void;
    setInvoiceId: (id: number) => void;
    setColumnsVisibility: React.Dispatch<
        React.SetStateAction<Partial<Record<(typeof ATTACHMENTS_TABLE_COLUMNS)[number], boolean>>>
    >;
}

const AttachmentsTableContext = createContext<IAttachmentsTableContext>({
    tableState: undefined!,
    columns: [],
    commissionId: null,
    invoiceId: null,
    attachments: [],
    filteredAttachments: [],
    isLoadingAttachments: true,

    setCommissionId: () => null,
    setInvoiceId: () => null,
    setColumnsVisibility: () => null,
});
export interface IAttachmentsTableContextProviderProps {
    children: React.ReactNode;
}
export const AttachmentsTableContextProvider: React.FC<IAttachmentsTableContextProviderProps> = ({ children }) => {
    const { t } = useTranslation();
    const [commissionId, setCommissionId] = useState<number | null>(null);
    const [invoiceId, setInvoiceId] = useState<number | null>(null);
    const [columnsVisibility, setColumnsVisibility] = useState<
        Partial<Record<(typeof ATTACHMENTS_TABLE_COLUMNS)[number], boolean>>
    >({});

    const { data: attachments, isLoading: isLoadingAttachments } = useListAttachmentsQuery(
        {
            ...(commissionId && { commissionId }),
            ...(invoiceId && { invoiceId }),
            includeCommission: true,
            includeUploadedBy: true,
            withCommissionsDeliveryNotes: !!invoiceId,
        },
        { skip: !commissionId && !invoiceId },
    );
    const columnDefinitions = useMemo(() => attachmentsTableColumnsDefinitions(t), []);
    columnDefinitions.forEach((column) => {
        column.id;
    });
    const attachmentsTableState = useTableState<TAttachment>({
        rowIdKey: 'attachment_id',
        tableName: 'attachmentsTable',
        columnsVisibilityStates: {
            ...columnDefinitions.reduce<Record<string, boolean>>((acc, column) => {
                if (column.id) {
                    acc[column.id] = column.meta?.visible ?? true;
                }
                return acc;
            }, {}),
            ...columnsVisibility,
        },
    });

    const transformedDataForFiltering: TTransformedAttachment[] = useMemo(() => {
        if (!attachments) {
            return [];
        }
        return attachments.map((attachment) => {
            return {
                ...attachment,
                uploadedBy: attachment.uploadedBy
                    ? `${attachment.uploadedBy.name} ${attachment.uploadedBy.surname}`
                    : t('attachments.externalCarrier'),
                type: t(`attachments.form.fields.attachmentType.${attachment.type}`),
                commission: attachment.commission ? String(attachment.commission.number) : '',
            };
        });
    }, [attachments]);

    const { result: filteredAttachments } = tableSortingFilteringPagination<TTransformedAttachment>({
        data: transformedDataForFiltering,
        queryParams: attachmentsTableState.queryParams,
        paginationParams: attachmentsTableState.paginationParams,
        treatAsDate: ['tsAdded'],
    });

    return (
        <AttachmentsTableContext.Provider
            value={{
                invoiceId,
                setInvoiceId,
                commissionId,
                setCommissionId,
                isLoadingAttachments,
                setColumnsVisibility,
                columns: columnDefinitions,
                attachments: attachments || [],
                filteredAttachments: filteredAttachments,
                tableState: attachmentsTableState,
            }}
        >
            {children}
        </AttachmentsTableContext.Provider>
    );
};

export const useAttachmentsTable = () => {
    return React.useContext(AttachmentsTableContext);
};

export const attachmentsTableColumnsDefinitions = (t: TFunction) => {
    const columnHelper = createColumnHelper<TTransformedAttachment>();
    const columns = [
        columnHelper.accessor('name', {
            id: 'name',
            header: t('attachments.table.columns.name'),
            meta: {
                ...getContainsFilterMeta(),
            },
            cell: (info) => (
                <div className={styles.nameColumnWrapper}>
                    <Typography variant="p" fontWeight="semibold">
                        {info.getValue()}
                    </Typography>
                    {info.row.original.type ===
                        t(`attachments.form.fields.attachmentType.${EAttachmentType.INVOICE}`) && (
                        <AttachmentSentStatusPill sent={info.row.original.sent} size="small" />
                    )}
                </div>
            ),
        }),
        columnHelper.accessor('commission', {
            id: 'commission',
            header: t('attachments.table.columns.commission'),
            cell: (info) => {
                const commissionId = info.row.original.commission_id;
                const commissionNumber = info.getValue();
                if (!commissionId) {
                    return (
                        <Typography variant="p" fontWeight="normal">
                            {'-'}
                        </Typography>
                    );
                }
                return (
                    <Link to={config.routes.commissions.detail.replace(':id', String(commissionId))}>
                        <Typography color="primary" variant="p" fontWeight="semibold">
                            {commissionNumber || '-'}
                        </Typography>
                    </Link>
                );
            },
            meta: {
                ...getContainsFilterMeta(),
                disableRowClick: true,
            },
        }),
        columnHelper.accessor('type', {
            id: 'type',
            header: t('attachments.table.columns.type'),
            cell: (info) => {
                const type = info.getValue();
                return (
                    <Typography variant="p" fontWeight="normal">
                        {type}
                    </Typography>
                );
            },
            meta: {
                ...getContainsFilterMeta(),
            },
        }),
        columnHelper.accessor('uploadedBy', {
            id: 'uploadedBy',
            header: t('attachments.table.columns.uploadedBy'),
            cell: (info) => {
                const name = info.getValue();
                return (
                    <Typography variant="p" fontWeight="normal">
                        {name}
                    </Typography>
                );
            },
            meta: {
                ...getContainsFilterMeta(),
            },
        }),
        columnHelper.accessor('tsAdded', {
            id: 'tsAdded',
            header: t('attachments.table.columns.uploadDate'),
            sortingFn: 'datetime',
            cell: (info) => {
                const date = timestamp(Number(info.getValue()));
                return (
                    <Typography variant="p" fontWeight="normal">
                        {date?.format('DD. MM. YYYY HH:mm')}
                    </Typography>
                );
            },
            meta: {
                ...getDateRangeFilterMeta(),
            },
        }),

        // Static column for actions
        columnHelper.display({
            id: 'actions',
            header: t('attachments.table.columns.actions'),
            cell: (info) => (
                <div className={styles.actionButtonsWrapper}>
                    <Link to={config.routes.attachments.detail.replace(':id', String(info.row.original.attachment_id))}>
                        <Button variant="secondary" Icon={<ConfigurationIcon />} size="small" />
                    </Link>
                    <DownloadAttachmentFileButton
                        attachmentId={info.row.original.attachment_id}
                        hideText
                        size="small"
                    />
                    <DeleteAttachmentButton
                        attachmentId={info.row.original.attachment_id}
                        size="small"
                        redirectOnSuccess={false}
                    />
                </div>
            ),
        }),
    ];
    return columns;
};
