import React from 'react';
import { DispatcherSearchData } from 'modules/dispatcherSearch/types';

import styles from './MapPointToast.module.scss';

type MapPointToastProps = {
    dispatchers: DispatcherSearchData[];
    handleSelectionFromMap: (id: number) => void;
};

export const MapPointToast: React.FC<MapPointToastProps> = ({ dispatchers, handleSelectionFromMap }) => {
    return (
        <div className={styles.toast}>
            <div className={styles.toastFlexContainer}>
                {dispatchers.map((item) => (
                    <div
                        key={item.id}
                        className={styles.toastFlexContainerItem}
                        onClick={() => handleSelectionFromMap(item.id)}
                    >
                        {`${item.name} - ${item.carrier.name}`} <span className={styles.plusIcon}>+</span>
                    </div>
                ))}
            </div>
        </div>
    );
};
