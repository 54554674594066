import React from 'react';
import { FormProvider } from 'react-hook-form';
import { LayoutGroup, motion } from 'framer-motion';
import { useAttachmentUpload } from 'modules/attachments/contexts/attachmentUpload';
import { TAttachmentUploadFormSchema } from 'modules/attachments/types';

import { UploadSectionField } from './components/UploadSectionField';

import styles from './AttachmentsUploadForm.module.scss';

export interface IAttachmentsUploadFormProps {}

export const AttachmentsUploadForm: React.FC<IAttachmentsUploadFormProps> = () => {
    const { formMethods, uploadAttachments } = useAttachmentUpload();

    const handleSubmit = async (data: TAttachmentUploadFormSchema) => {
        await uploadAttachments(data);
    };

    return (
        <FormProvider {...formMethods}>
            <LayoutGroup>
                <motion.form
                    layout
                    id="attachmentUploadForm"
                    className={styles.uploadFormWrapper}
                    onSubmit={formMethods.handleSubmit(handleSubmit)}
                >
                    <UploadSectionField fieldName="invoice" />
                    <UploadSectionField fieldName="deliveryNotes" />
                </motion.form>
            </LayoutGroup>
        </FormProvider>
    );
};
