import React from 'react';
import { Button, Typography } from 'modules/ui';

import styles from './ExceptionPage.module.scss';

export interface IExceptionPageProps {
    title: string;
    description?: string;
    buttonText?: string;
    buttonAction?: () => void;
}

export const ExceptionPage: React.FC<IExceptionPageProps> = ({ title, buttonAction, buttonText, description }) => {
    return (
        <div className={styles.errorPage}>
            <Typography variant="h2">{title}</Typography>
            {description && <Typography variant="subheading">{description}</Typography>}
            {buttonText && (
                <Button style={{ marginTop: 20 }} onClick={buttonAction}>
                    {buttonText}
                </Button>
            )}
        </div>
    );
};
