import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { config } from 'config';
import { useDocumentTitle } from 'core/application/hooks';
import useChange from 'hooks/useChange';
import { useSelectedDispatchers } from 'modules/commissions/hooks/useSelectedDispatchers';
import { createSelectColumn, getContainsFilterMeta, Table, TableContainer, useTableState } from 'modules/table';
import { tableSortingFilteringPagination } from 'utils';
import { timestamp } from 'utils/formatTimestamp';

import { getDateRangeFilterMeta } from '../../../table/utils/getFilter';
import { DispatcherSearchTableItem } from '../DispatcherSearchTables/hooks/useDispatcherSearchTable';

import styles from './CommissionCarrierTable.module.scss';

const columnHelper = createColumnHelper<DispatcherSearchTableItem>();

interface ICommissionCarrierTableProps {
    data: DispatcherSearchTableItem[];
    // ids of dispatchers we want to disable (show gray)
    disabledIds?: number[];
    // ids of already contacted dispatchers they do not satisfy the new params (show red)
    notSatisfyParamsIds?: number[];
    tableName: string;
}

export const CommissionCarrierTable: React.FC<ICommissionCarrierTableProps> = ({
    data,
    disabledIds,
    notSatisfyParamsIds,
    tableName,
}) => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.customers'));
    const { selectedDispatchers, setSelectedDispatchers } = useSelectedDispatchers();

    const { tableProps, queryParams, paginationParams } = useTableState<DispatcherSearchTableItem>({
        rowIdKey: 'dispatcher_id',
        getCellStyles: ({ column, row }) => {
            const cellStyles: string[] = [];

            if (['company', 'routes'].includes(column.id)) {
                cellStyles.push(Table.CellTextFormat.SemiBold);
            }
            //  if (column.id === 'routes') cellStyles.push(Table.CellTextColor.Primary);

            if (disabledIds?.includes(row.original.dispatcher_id)) {
                cellStyles.push(styles.disabled);
            }

            if (notSatisfyParamsIds?.includes(row.original.dispatcher_id)) {
                cellStyles.push(styles.notSatisfyParamsIds);
            }

            return cellStyles;
        },
        getIsRowSelectionDisabled: (row) => disabledIds?.includes(row.original.dispatcher_id) || false,
        tableName,
    });
    const tableData = useMemo(() => {
        const changedData = data
        const { filtered, result, sorted } = tableSortingFilteringPagination<DispatcherSearchTableItem>({
            data: changedData,
            paginationParams,
            queryParams,
            treatAsDate: ['lastCommissionDate'],
        });

        return { filtered, sorted, paginated: result, changedData };
    }, [data, queryParams, paginationParams]);

    const columns = useMemo(
        () => [
            columnHelper.accessor('company', {
                header: t('commissions.form.carrier.searchCarrier.table.company'),
                cell: (props) => {
                    const value = props.getValue() || '';
                    return value.length > 37 ? `${value.substring(0, 37)}...` : value;
                },
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('fullName', {
                header: t('commissions.form.carrier.searchCarrier.table.fullName'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('country', {
                header: t('commissions.form.carrier.searchCarrier.table.country'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('lastCommissionDate', {
                header: t('commissions.form.carrier.searchCarrier.table.lastCooperationDate'),
                cell: (props) => {
                    const value = Number(props.getValue());
                    return value ? timestamp(value)?.format('DD. MM. YYYY') : <></>;
                },
                meta: {
                    ...getDateRangeFilterMeta(),
                },
            }),

            columnHelper.accessor('lastCommissionNumber', {
                header: t('commissions.form.carrier.searchCarrier.table.lastCommission'),
                cell: (props) => {
                    const lastCommissionId = props.row.original.lastCommissionId;
                    const value = props.getValue();
                    return (
                        <Link to={`${config.routes.commissions.detail.replace(':id', String(lastCommissionId))}`}>
                            {value !== null ? value : ''}
                        </Link>
                    );
                },
                meta: {
                    align: 'center',
                },
            }),

            createSelectColumn(
                columnHelper,
                undefined,
                undefined,
                data.flatMap((item) => {
                    if ('dispatcher_id' in item) {
                        return item.dispatcher_id;
                    }
                    return (item as DispatcherSearchTableItem).dispatcher_id;
                }),
            ),
        ],
        [t, data],
    );

    // set default row selection (from fetched data)
    useEffect(() => {
        const rowSelection: Record<string, any> = {};
        selectedDispatchers.forEach((item) => (rowSelection[String(item.dispatcherId)] = true));
        tableProps.setRowSelection(rowSelection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // when selecting the dispatchers, pass the emails to the HOC
    useChange(() => {
        if (!data) return;
        const selectedDispatchersIds = tableProps.rowSelection;
        const allDispatchers = data;
        const selectedDispatchers = allDispatchers.filter(
            (dispatcher) => dispatcher.dispatcher_id && selectedDispatchersIds?.[dispatcher.dispatcher_id] === true,
        );
        const selectedDispatchersShort = selectedDispatchers.map(({ dispatcher_id }) => ({
            dispatcherId: dispatcher_id as number,
        }));

        setSelectedDispatchers(selectedDispatchersShort);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(tableProps.rowSelection)]);

    return (
        <div className={styles.tableBox}>
            <TableContainer>
                <Table
                    data={tableData.paginated}
                    filtered={tableData.filtered}
                    columns={columns}
                    totalCount={tableData.changedData.length}
                    bordered={true}
                    {...tableProps}
                    tableName={tableName}
                />
            </TableContainer>
        </div>
    );
};
