import type { IconProps } from './types';

export const CommissionMapIcon: React.FC<IconProps> = ({ ...props }) => {
    return (
        <>
            <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32">
                <g id="symbolLocation" transform="translate(0.001)">
                    <g id="Exclusion_1" data-name="Exclusion 1" fill="#243c53">
                        <path
                            d="M 11.99882888793945 31.24303436279297 C 11.10530185699463 30.21704864501953 8.739523887634277 27.44063758850098 6.399638652801514 24.18623924255371 C 2.53929877281189 18.81711006164551 0.4988288879394531 14.60317993164062 0.4988288879394531 12 C 0.4988288879394531 8.928239822387695 1.695038914680481 6.040329933166504 3.867098808288574 3.868269920349121 C 6.039158821105957 1.696210026741028 8.927068710327148 0.5 11.99882888793945 0.5 C 15.07058906555176 0.5 17.95849800109863 1.696210026741028 20.13055801391602 3.868269920349121 C 22.3026180267334 6.040329933166504 23.49882888793945 8.928239822387695 23.49882888793945 12 C 23.49882888793945 14.60317993164062 21.45835876464844 18.81711006164551 17.59801864624023 24.18623924255371 C 15.25813388824463 27.44063758850098 12.89235591888428 30.21704864501953 11.99882888793945 31.24303436279297 Z"
                            stroke="none"
                        />
                        <path
                            d="M 11.99882888793945 1 C 9.060619354248047 1 6.29827880859375 2.144199371337891 4.220649719238281 4.221820831298828 C 2.143028259277344 6.299449920654297 0.9988288879394531 9.061790466308594 0.9988288879394531 12 C 0.9988288879394531 14.49658966064453 3.00501823425293 18.60713005065918 6.800498962402344 23.88726043701172 C 8.853259086608887 26.74296951293945 10.92746734619141 29.23019409179688 11.99882888793945 30.47843551635742 C 13.0701904296875 29.23019409179688 15.14439868927002 26.74296951293945 17.19715881347656 23.88726043701172 C 20.99263954162598 18.60713005065918 22.99882888793945 14.49658966064453 22.99882888793945 12 C 22.99882888793945 9.061790466308594 21.85462951660156 6.299449920654297 19.77699851989746 4.221820831298828 C 17.69937896728516 2.144199371337891 14.9370288848877 1 11.99882888793945 1 M 11.99882888793945 0 C 18.62623977661133 0 23.99882888793945 5.372579574584961 23.99882888793945 12 C 23.99882888793945 18.62741088867188 11.99882888793945 32 11.99882888793945 32 C 11.99882888793945 32 -0.001171112060546875 18.62741088867188 -0.001171112060546875 12 C -0.001171112060546875 5.372579574584961 5.371408462524414 0 11.99882888793945 0 Z"
                            stroke="none"
                            fill="#fff"
                        />
                    </g>
                    <g id="icoTruck" transform="translate(4 4.001)">
                        <rect id="Rectangle_7310" data-name="Rectangle 7310" width="16" height="16" fill="none" />
                        <g id="delivery" transform="translate(-0.425 -1.439)">
                            <path
                                id="Path_10814"
                                data-name="Path 10814"
                                d="M15.83,6.719H12.771V5.7a1.7,1.7,0,0,0-1.7-1.7H5.7A1.7,1.7,0,0,0,4,5.7v6.118a1.7,1.7,0,0,0,1.391,1.668,1.7,1.7,0,0,0,3.333.031h2.041a1.7,1.7,0,0,0,3.33,0H15.83a1.021,1.021,0,0,0,1.02-1.02V7.739A1.021,1.021,0,0,0,15.83,6.719Zm0,.68a.34.34,0,0,1,.34.34v1.7H15.15A2.383,2.383,0,0,1,12.8,7.4ZM4.68,11.818V5.7A1.021,1.021,0,0,1,5.7,4.68h5.372a1.021,1.021,0,0,1,1.02,1.02v5.813a1.7,1.7,0,0,0-1.325,1.325H8.724a1.7,1.7,0,0,0-3.316-.048,1.018,1.018,0,0,1-.729-.972ZM7.059,14.2a1.02,1.02,0,1,1,1.02-1.02,1.02,1.02,0,0,1-1.02,1.02Zm5.372,0a1.02,1.02,0,1,1,1.02-1.02A1.02,1.02,0,0,1,12.431,14.2Zm3.4-1.36H14.1a1.7,1.7,0,0,0-1.325-1.325V8.958a3.042,3.042,0,0,0,2.379,1.16h1.02V12.5A.34.34,0,0,1,15.83,12.838Z"
                                transform="translate(-2)"
                                fill="#fff"
                                stroke="#fff"
                                stroke-width="0.3"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </>
    );
};
