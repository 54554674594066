import { ReactNode } from 'react';
import classnames from 'classnames';
import { Spinner } from 'modules/ui';

import styles from './Button.module.scss';

export type ButtonProps = React.ComponentProps<'button'> & {
    Icon?: ReactNode;
    rounded?: boolean;
    isLoading?: boolean;
    variant?: 'primary' | 'secondary';
    pill?: boolean;
    danger?: boolean;
    size?: 'small' | 'medium';
};

export const Button = ({
    children,
    Icon,
    rounded,
    isLoading,
    variant = 'primary',
    pill,
    className,
    type = 'button',
    size = 'medium',
    danger,
    ...props
}: ButtonProps) => {
    return (
        <button
            className={classnames(styles.button, className, {
                [styles.icon]: !!Icon,
                [styles.loading]: isLoading,
                [styles.primary]: variant === 'primary',
                [styles.secondary]: variant === 'secondary',
                [styles.pill]: pill,
                [styles.danger]: danger,
                [styles.rounded]: rounded,
                [styles.small]: size === 'small',
            })}
            type={type}
            {...props}
        >
            <div className={styles.content}>{!!Icon ? Icon : children}</div>
            {isLoading && (
                <div className={styles.icon}>
                    <Spinner />
                </div>
            )}
        </button>
    );
};
