import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNonTypedTranslation } from 'core/translation';
import { useAttachmentDetailForm } from 'modules/attachments/contexts/attachmentDetailForm';
import { EAttachmentType } from 'modules/attachments/types';
import { FormGrid, FormSection, SelectField, TextField } from 'modules/form';
import { Typography } from 'modules/ui';
import { timestamp } from 'utils/formatTimestamp';

import { CommissionSelectField } from '../CommissionSelectField';
import { InvoiceSelectField } from '../InvoiceSelectField';

export interface IConfigurationSectionProps {}

export const ConfigurationSection: React.FC<IConfigurationSectionProps> = () => {
    const { attachment, isNew } = useAttachmentDetailForm();
    const { t } = useTranslation();
    const { tnt } = useNonTypedTranslation();

    const userName = useMemo(() => {
        if (isNew || !attachment) return '***';
        if (!attachment.uploadedBy) return t('attachments.externalCarrier');
        return `${attachment.uploadedBy.name} ${attachment.uploadedBy.surname}`;
    }, [attachment, isNew]);

    const attachmentTypeOptions = useMemo(() => {
        return [
            {
                label: tnt(`attachments.form.fields.attachmentType.${EAttachmentType.DELIVERY_NOTE}`),
                value: EAttachmentType.DELIVERY_NOTE,
            },
            {
                label: tnt(`attachments.form.fields.attachmentType.${EAttachmentType.INVOICE}`),
                value: EAttachmentType.INVOICE,
            },
        ];
    }, [tnt]);

    return (
        <FormSection title={t('attachments.form.sections.basicInfo')} style={{ marginTop: 30 }}>
            <FormGrid columns={2} style={{ marginBottom: 20 }}>
                <CommissionSelectField />
                <InvoiceSelectField />
                <SelectField
                    name="type"
                    label={t('attachments.form.fields.attachmentType.title')}
                    options={attachmentTypeOptions}
                />
            </FormGrid>
            <TextField label={t('attachments.form.fields.name.title')} name="name" />
            <Typography
                variant="p"
                style={{ marginTop: 20 }}
                fontWeight="normal"
                dangerouslySetInnerHTML={{
                    __html: t('attachments.form.uploadedBy', {
                        userName: userName,
                        dateTime: attachment?.tsAdded
                            ? timestamp(Number(attachment.tsAdded))?.format('DD. MM. YYYY HH:mm')
                            : '***',
                    }),
                }}
            />
        </FormSection>
    );
};
